import React from "react";

import { ScreenedOutReason } from "@/constants";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "gender";

declare module "@/contexts" {
  interface SurveyAnswers {
    gender?: string;
  }
}

const options: QuestionOption[] = [
  { id: "male", text: "Male" },
  { id: "female", text: "Female" },
  { id: "transgender", text: "Transgender" },
  { id: "other", text: "Other" },
  { id: "preferNotToSay", text: "Prefer not to say" },
];

const GenderQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => (
  <MultipleChoiceQuestion
    onClickBack={onClickBack}
    onClickNext={onClickNext}
    options={options}
    questionId={QUESTION_ID}
    questionText="Which gender do you identify with?"
    selectedItem={surveyAnswers[QUESTION_ID] || ""}
  />
);

export const Q_GENDER: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    screenedOutReason,
    selectedProgram,
    surveyAnswers: { gender },
  }) =>
    // Hide question if the user answered in the metabolic path and was screened out.
    !(
      gender &&
      selectedProgram === "MOOD" &&
      // Should include its own ScreenedOutReason (if applicable) and any that follow this question.
      [ScreenedOutReason.PREGNANT, ScreenedOutReason.HEALTH_CONDITION].includes(
        screenedOutReason as ScreenedOutReason
      )
    ),
  component: GenderQuestion,
};

export const Q_RETRIAGE_GENDER: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, triageCurriculums = [] } = {},
  }) => currentProgram === "WEIGHT" && triageCurriculums.length > 1,
  component: GenderQuestion,
};
