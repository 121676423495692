import { useEffect, useState } from "react";

import { OPT_OUT_MHMDA_STATES, OPT_OUT_STATES } from "@/constants";
import { loadOneTrust } from "@/utils/vendors/onetrust";

export const useGeolocation = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const loadGeolocationData = async () => {
      await loadOneTrust();
      setIsLoading(false);
    };

    loadGeolocationData();
  }, [isLoading]);

  const country = window.OneTrust?.getGeolocationData?.().country || "";
  const state = window.OneTrust?.getGeolocationData?.().state || "";
  const canOptOut = () => country === "US" && OPT_OUT_STATES.includes(state);
  const canOptOutMHMDA = () =>
    country === "US" && OPT_OUT_MHMDA_STATES.includes(state);

  return {
    canOptOut,
    canOptOutMHMDA,
    country,
    isLoading,
    state,
  };
};
