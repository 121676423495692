import React from "react";
import { useTranslation } from "react-i18next";

import { useAppContext } from "@/contexts";
import {
  shouldAskBaselineOutcomes,
  shouldAskQuarterlyQuestion,
} from "@/utils/survey/outcomes";

import { QuestionComponentProps, QuestionDefinition } from "../../Question";
import { SelectQuestion } from "../core";

const QUESTION_ID = "medications";

declare module "@/contexts" {
  interface SurveyAnswers {
    medications?: string;
  }
}

const MedicationsQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { surveyMetadata } = useAppContext();
  const { t } = useTranslation();

  const questionText =
    surveyMetadata?.name === "RECURRING_OUTCOMES"
      ? t("medications.questionText.recurringOutcomes")
      : t("medications.questionText.enrollment");

  return (
    <SelectQuestion
      initialValue={surveyAnswers[QUESTION_ID]}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      // [0 -> 20]
      options={Array(21)
        .fill(0)
        .map((_, i) => ({ id: `${i}`, text: `${i}` }))}
      placeholder=""
      questionHelperText={t("medications.questionHelperText")}
      questionId={QUESTION_ID}
      questionText={questionText}
    />
  );
};

export const Q_MEDICATIONS: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo }) =>
    shouldAskBaselineOutcomes(partnerInfo),
  component: MedicationsQuestion,
};

export const Q_OUTCOMES_MEDICATIONS: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ userProfile }) =>
    shouldAskQuarterlyQuestion(userProfile),
  component: MedicationsQuestion,
};
