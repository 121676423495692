import { useLocation } from "react-router-dom";

export type QueryParamsWithoutPii = {
  data?: string;
  eligibilityId?: string;
  enrollmentSlug?: string;
  passcode?: string;
  pwResetRedirect?: string;
  sharedEligibility?: string;
  upid?: string;
  leadSource?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
};

// List of parameters that can contain PII and need to be hidden from pixels and third parties.
// These will be re-appended for some API calls, since they can be important for backend handling.
export const paramNamesWithPii = [
  "name",
  "email",

  // Referrals
  "ref_email",

  // UTM params that can contain PII.
  "utm_term",
  "utm_content",
] as const;

export type PiiQueryParams = {
  [K in (typeof paramNamesWithPii)[number]]?: string;
};

export type QueryParamsWithPii = QueryParamsWithoutPii & PiiQueryParams;

export const useQueryParams = (): QueryParamsWithoutPii => {
  const { search } = useLocation();
  const rawQueryParams = new URLSearchParams(search);
  return {
    data: rawQueryParams.get("data") ?? undefined,
    eligibilityId: rawQueryParams.get("eligibilityId") ?? undefined,
    enrollmentSlug: rawQueryParams.get("enrollmentSlug") ?? undefined,
    passcode: rawQueryParams.get("passcode") ?? undefined,
    pwResetRedirect: rawQueryParams.get("pwResetRedirect") ?? undefined,
    sharedEligibility: rawQueryParams.get("sharedEligibility") ?? undefined,
    upid: rawQueryParams.get("upid")?.toLowerCase(),
    leadSource: rawQueryParams.get("leadSource") ?? undefined,
    utm_source: rawQueryParams.get("utm_source") ?? undefined,
    utm_medium: rawQueryParams.get("utm_medium") ?? undefined,
    utm_campaign: rawQueryParams.get("utm_campaign") ?? undefined,
  };
};
