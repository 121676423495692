import { CompassColor } from "@noom/wax-component-library";

import { defaultFieldStyles } from "../defaultFieldStyles";

export const Button = {
  defaultProps: {
    size: "xl",
    variant: "solid",
  },
  sizes: {
    xl: {
      borderRadius: "4px",
      fontSize: "lg",
      maxWidth: "400px",
      width: "100%",
    },
  },
  variants: {
    // This is a hacky way of implementing the "tertiary button" in the
    // Noom Design System, which is not supported by WAX
    outline: {
      ...defaultFieldStyles,
      backgroundColor: "unset",
      borderColor: CompassColor.black,
      borderRadius: "4px",
      cursor: "pointer",
      _active: {
        backgroundColor: "unset",
        borderWidth: "1.5px",
        color: CompassColor.black,
      },
      _hover: {
        backgroundColor: "unset",
        borderColor: CompassColor.grey3,
        color: CompassColor.grey3,
      },
    },
  },
};
