import { Api } from "@noom/noomscape";

import { ErrorCode } from "@/constants";
import { useAppContext } from "@/contexts";
import { MedEnrollmentData, User, formatAsMedEnrollmentData } from "@/models";
import { captureException } from "@/utils/sentry";

export const useEnrollMed = () => {
  const { b2bIdentifiedUserId, partnerInfo } = useAppContext();
  const enrollMed = async (accessCode: string, userData: User) => {
    if (!partnerInfo) {
      throw Error("partnerInfo expected");
    }

    const medEnrollmentData: MedEnrollmentData = formatAsMedEnrollmentData({
      accessCode,
      b2bIdentifiedUserId,
      partner: partnerInfo,
      userData,
    });

    return Api.call("account.enrollMed", Api.api.account.enrollMed, {
      medEnrollmentData,
    }).catch((e: unknown) => {
      captureException(e);
      throw Error(ErrorCode.MED_ENROLLMENT_FAILED);
    });
  };

  return { enrollMed };
};
