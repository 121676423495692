/* eslint-disable prefer-rest-params, prefer-spread, no-unused-expressions, no-underscore-dangle */
import { LibCategories } from "@/utils/consent";
import { loadScript } from "@/utils/scriptLoader";

declare const window: Window & {
  fbq?: () => void;
  _fbq?: () => void;
};

// eslint-disable-next-line func-names
const fbq: any = function () {
  fbq.callMethod
    ? fbq.callMethod.apply(fbq, arguments)
    : fbq.queue.push(arguments);
};
window.fbq = fbq;

if (!window._fbq) {
  window._fbq = fbq;
}
fbq.push = fbq;
fbq.loaded = !0;
fbq.version = "2.0";
fbq.queue = [];

export function loadFacebookPixel() {
  return loadScript(
    "https://connect.facebook.net/en_US/fbevents.js",
    [LibCategories.targetingCookies],
    { enableCORS: false },
  );
}
