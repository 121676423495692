import React from "react";

import { ScreenedOutReason } from "@/constants";
import { useAppContext } from "@/contexts";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "sex";

declare module "@/contexts" {
  interface SurveyAnswers {
    sex?: string;
  }
}

const options: QuestionOption[] = [
  { id: "male", text: "Male" },
  { id: "female", text: "Female" },
  { id: "intersex", text: "Intersex" },
  { id: "preferNotToSay", text: "Prefer not to say" },
];

const SexQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { selectedProgram } = useAppContext();

  const questionDescription =
    selectedProgram === "MOOD"
      ? ""
      : "Sex and hormones impact how you metabolize food.";

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionDescription={questionDescription}
      questionText="Which sex best describes you?"
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_SEX: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    screenedOutReason,
    selectedProgram,
    surveyAnswers: { sex },
  }) =>
    // Hide question if the user answered in the metabolic path and was screened out.
    !(
      sex &&
      selectedProgram === "MOOD" &&
      // Should include its own ScreenedOutReason (if applicable) and any that follow this question.
      [ScreenedOutReason.PREGNANT, ScreenedOutReason.HEALTH_CONDITION].includes(
        screenedOutReason as ScreenedOutReason
      )
    ),
  component: SexQuestion,
};

export const Q_RETRIAGE_SEX: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, triageCurriculums = [] } = {},
  }) => currentProgram === "WEIGHT" && triageCurriculums.length > 1,
  component: SexQuestion,
};
