import { format } from "date-fns";

import { PregnantSelection } from "@/constants";
import type { Partner, User } from "@/models";
import { getRegionAbbreviation } from "@/utils/location";

export type MedEnrollmentData = {
  userId: string;
  accessCode: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  stateCode: string;
  zipCode: string;
  phoneNumber: string;
  mainReason: string;
  familyHistory: string;
  pregnant: PregnantSelection;
  healthConditions: string[];
  heightFeet: number;
  heightInch: number;
  weightLbs: number;
  partnerId: number;
  useMedications: string;
  suicideOrSelfHarm1: string;
  suicideOrSelfHarm2: string;
};

export const formatAsMedEnrollmentData = ({
  accessCode,
  b2bIdentifiedUserId,
  partner,
  userData,
}: {
  accessCode: string;
  b2bIdentifiedUserId: string;
  partner: Partner;
  userData: User;
}): MedEnrollmentData => {
  const { id: partnerId } = partner;
  // using phoneNumber from userData (vs surveyAnswers) as there is already formatting applied.
  const { extras, phoneNumber, surveyAnswers } = userData;
  const {
    eligibilityCheck,
    gender,
    createAccount,
    mainReason,
    familyHistoryWeight,
    pregnant,
    healthConditions,
    heightWeight,
    medications,
    phq3,
    phq4,
  } = surveyAnswers!;
  const { firstName, lastName, dob } = eligibilityCheck!;
  // reading address from extras (vs surveyAnswers) as there is validation / correction applied.
  const { address1, address2, city, region, zipcode, country } = extras.address;
  const stateCode = getRegionAbbreviation(country, region)!;

  const { heightFeetPortion, heightInchesPortion, weight } = heightWeight!;

  return {
    userId: b2bIdentifiedUserId,
    accessCode,
    firstName,
    lastName,
    dateOfBirth: format(dob!, "yyyy-MM-dd"),
    gender: gender!,
    email: createAccount!.email,
    address1,
    address2,
    city,
    stateCode,
    zipCode: zipcode,
    phoneNumber: phoneNumber!,
    mainReason: mainReason!,
    familyHistory: familyHistoryWeight!,
    pregnant: pregnant || PregnantSelection.NO,
    healthConditions: healthConditions!,
    heightFeet: heightFeetPortion!,
    heightInch: heightInchesPortion!,
    weightLbs: weight!,
    partnerId,
    useMedications: Number(medications) > 0 ? "yes" : "no",
    suicideOrSelfHarm1: phq4!,
    suicideOrSelfHarm2: phq3!,
  };
};
