import Cookies from "js-cookie";

import {
  GetUserPolicyOptionsV3RequestProto_Body,
  GetUserPolicyOptionsV3ResponseProto,
  PostUserPolicyOptionsV3ResponseProto,
} from "@noom/noom-contracts/noom_contracts/backend/privacy/user_policy_options";
import { Api } from "@noom/noomscape";

import { applyLDUFlag } from "@/utils/pixels/publishers/facebook";

import { captureException } from "../sentry";

import { bootstrapOneTrust, seedOneTrustConsent } from "./one-trust";

import { consentFramework, getConsent, LibCategories, setConsent } from ".";

export const OPT_OUT_COOKIE_NAME = "ccpaOptOut";

async function fetchConsentStatus(email: string) {
  const formattedEmail = email.trim().toLowerCase();
  const requestData: GetUserPolicyOptionsV3RequestProto_Body = {
    userIdentifier: {
      email: formattedEmail,
    },
  };
  const response: GetUserPolicyOptionsV3ResponseProto = await Api.call(
    "account.getConsent",
    Api.api.account.getConsent,
    requestData,
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response;
}

export function optOutAllowsCookies() {
  return Cookies.get(OPT_OUT_COOKIE_NAME) === "false";
}

function applyOptOut() {
  // If not opted out, grant implicit full consent
  if (optOutAllowsCookies()) {
    setConsent([
      LibCategories.strictlyNecessary,
      LibCategories.performanceCookies,
      LibCategories.functionalCookies,
      LibCategories.targetingCookies,
      LibCategories.socialMediaCookies,
    ]);
  } else {
    setConsent([LibCategories.strictlyNecessary]);
  }
}

export async function ensureCookie(email?: string) {
  const cookie = Cookies.get(OPT_OUT_COOKIE_NAME);
  // If the cookie is not set, attempt to determine from the server
  if (!cookie && email) {
    try {
      const consentState = await fetchConsentStatus(email);
      const optedOut = !!consentState.userPolicyOptions?.userPolicyOptions.some(
        (userPolicyOption) =>
          userPolicyOption.status === "OPT_OUT_DO_NOT_SHARE",
      );
      Cookies.set(OPT_OUT_COOKIE_NAME, optedOut ? "true" : "false");
      applyOptOut();
    } catch (err) {
      console.log(err);
      captureException(err);
    }
  } else {
    if (!cookie && !email) {
      // We need to set this to show that we've evaluated the opt out status
      // Cookies will only load when cookie is explicitly false
      Cookies.set(OPT_OUT_COOKIE_NAME, "false");
    }
    applyOptOut();
  }
}

export async function setOptOutEmail(newEmail: string) {
  if (
    consentFramework() !== "optOut" ||
    // Don't reset if the session is opted out
    Cookies.get(OPT_OUT_COOKIE_NAME) === "true"
  ) {
    return;
  }

  Cookies.remove(OPT_OUT_COOKIE_NAME);
  setConsent([LibCategories.strictlyNecessary]);
  await ensureCookie(newEmail);
}

export async function bootstrapOptOut() {
  ensureCookie();

  applyOptOut();

  seedOneTrustConsent(getConsent());
  await bootstrapOneTrust();
}

export async function setOptOut(country: string, email: string, state: string) {
  const response: PostUserPolicyOptionsV3ResponseProto = await Api.call(
    "account.submitConsent",
    Api.api.account.submitConsent,
    {
      country,
      userIdentifier: {
        email,
      },
      status: "OPT_OUT_DO_NOT_SHARE",
      subdivision: state,
    },
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  Cookies.set(OPT_OUT_COOKIE_NAME, "true");

  // Set the LDU flag for the Facebook pixel.
  // Note that this is not considered compliant but we are
  // retaining as an extra layer of protection.
  applyLDUFlag();
}
