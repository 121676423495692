import { LibCategories, waitForConsent } from "./consent";
import { captureException } from "./sentry";

const loaderCache: Record<string, Promise<void>> = {};

class ScriptLoadError extends Error {
  constructor(public readonly src: string) {
    super(`Failed to load script: ${src}`);
  }
}

type HtmlAttrTypes = Partial<HTMLScriptElement>;

export const pendingScripts = new Set<string>();

export async function loadScript(
  src: string,
  categories: LibCategories[],
  {
    enableCORS = true,
    htmlAttr: { dataset, ...htmlAttributes } = {},
  }: {
    enableCORS?: boolean;
    htmlAttr?: HtmlAttrTypes;
  } = {},
): Promise<void> {
  pendingScripts.add(src);
  await waitForConsent(categories);

  if (!loaderCache[src]) {
    loaderCache[src] = new Promise((resolve, reject) => {
      const script = document.createElement("script");
      if (htmlAttributes) {
        Object.assign(script, htmlAttributes);
      }
      if (dataset) {
        Object.assign(script.dataset, dataset);
      }

      // Before setting src
      script.onload = () => {
        pendingScripts.delete(src);

        resolve();
      };

      script.onerror = () => {
        pendingScripts.delete(src);

        // Purge cache to allow retry
        delete loaderCache[src];

        const e = new ScriptLoadError(src);
        captureException(e);
        reject(e);
      };

      script.async = true;
      if (enableCORS) {
        script.crossOrigin = "anonymous";
      }

      // Do these last
      script.src = src;
      document.body.appendChild(script);
    });
  }
  return loaderCache[src];
}
