async function hash(algorithm: string, text: string): Promise<string> {
  const buf = await window.crypto.subtle.digest(
    algorithm,
    new TextEncoder().encode(text),
  );
  return Array.prototype.map
    .call(new Uint8Array(buf), (x: number) => `00${x.toString(16)}`.slice(-2))
    .join("");
}

export async function toSha256(text: string): Promise<string> {
  return text ? hash("SHA-256", text) : "";
}

export async function toSha512(text: string): Promise<string> {
  return text ? hash("SHA-512", text) : "";
}

export async function toSha1(text: string): Promise<string> {
  return text ? hash("SHA-1", text) : "";
}
