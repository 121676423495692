import { useLocation, useNavigate, useParams } from "react-router-dom";

import { B2BEnrollmentEnumsProto_PasscodeInputMethod } from "@noom/noom-contracts/noom_contracts/events/web/b2b/shared";
import { Api } from "@noom/noomscape";

import { useTrackEvent } from "./useTrackEvent";

async function getUpidFromPasscode(
  passcode: string,
  partnerSlug: string,
): Promise<string> {
  const response = await Api.call(
    "upid.getPasscode",
    Api.api.upid.getPasscode,
    {
      partnerSlug,
      passcode,
    },
  );
  return response.upid;
}

export const useSubmitPasscode = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { employerSlug } = useParams<AppParams>();
  const { trackAnonEvent, trackIdentifiedEvent } = useTrackEvent();

  return async (
    passcode: string,
    inputMethod: B2BEnrollmentEnumsProto_PasscodeInputMethod,
  ) => {
    let upid: string;
    try {
      upid = await getUpidFromPasscode(passcode, employerSlug || "");
      upid = upid?.toLowerCase();
    } catch (e) {
      trackIdentifiedEvent("b2bEnrollmentIdentifiedPasscodeChecked", {
        inputMethod,
        passcodeCorrect: false,
      });

      trackAnonEvent("b2bEnrollmentAnonPasscodeChecked", {
        passcodeCorrect: false,
      });

      throw e;
    }
    const qs = new URLSearchParams(search);
    qs.append("upid", upid);
    qs.delete("passcode");

    trackIdentifiedEvent("b2bEnrollmentIdentifiedPasscodeChecked", {
      inputMethod,
      passcodeCorrect: true,
    });

    trackAnonEvent("b2bEnrollmentAnonPasscodeChecked", {
      passcodeCorrect: true,
    });

    navigate(`/employer/${employerSlug}?${qs.toString()}`, { replace: true });
  };
};
