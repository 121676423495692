import { Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Api } from "@noom/noomscape";
import {
  Alert,
  Button,
  Spacing,
  Stack,
  useToast,
} from "@noom/wax-component-library";

import {
  PasswordInput,
  PasswordInputWithoutStrengthValidationSchema,
} from "@/components/form";
import { ErrorState } from "@/constants";
import { useAppContext } from "@/contexts";
import {
  useCreateLoginToken,
  useGoto,
  useQueryParams,
  useTrackEvent,
} from "@/hooks";
import { setSurveyAnswerCookie } from "@/utils/cookies";
import { withPiiQueryParams } from "@/utils/pixels/urlParamsPii";

import { QuestionComponentProps, QuestionDefinition } from "../../Question";
import { BaseQuestion } from "../core";

const QUESTION_ID = "existingPassword";

declare module "@/contexts" {
  interface SurveyAnswers {
    existingPassword?: string;
  }
}

type ExistingPasswordFormValues = {
  password: string;
};

const ExistingPasswordQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { partnerInfo } = useAppContext();
  const { isProvidingValidPassword } = useCreateLoginToken();
  const { error } = useGoto();
  const queryParams = useQueryParams();
  const [isCheckingPassword, setIsCheckingPassword] = useState(false);
  const [isSendingResetPasswordEmail, setIsSendingResetPasswordEmail] =
    useState(false);
  const toast = useToast();
  const toastId = "password-reset-email-toast";
  const { trackAnonEvent } = useTrackEvent();
  const { t } = useTranslation();

  const isPwResetRedirect: boolean = queryParams.pwResetRedirect === "true";

  const initialValues: ExistingPasswordFormValues = {
    password: surveyAnswers.existingPassword || "",
  };

  const onSubmit = async (
    { password }: ExistingPasswordFormValues,
    { setFieldError }: FormikHelpers<ExistingPasswordFormValues>,
  ) => {
    const { email } = surveyAnswers.createAccount || {};
    if (!email) {
      return;
    }

    setIsCheckingPassword(true);
    const wasSuccessful = await isProvidingValidPassword(email, password);
    setIsCheckingPassword(false);

    trackAnonEvent("b2bEnrollmentAnonExistingAccountPasswordChecked", {
      passwordCorrect: wasSuccessful,
    });

    if (!wasSuccessful) {
      setFieldError(
        "password",
        t("existingPassword.form.password.errors.incorrect"),
      );
      return;
    }
    onClickNext(password);
  };

  const handleResetPassword = async () => {
    setIsSendingResetPasswordEmail(true);

    trackAnonEvent("b2bEnrollmentAnonButtonClicked", {
      buttonName: "resetPassword",
      pageId: "existingPassword",
    });

    const paramsWithPii = {
      ...withPiiQueryParams(queryParams),
      pwResetRedirect: "true",
    };
    const qs = new URLSearchParams(
      Object.entries(paramsWithPii).filter(([, v]) => v !== undefined),
    );
    const postResetRedirectUrl = `${window.location.origin}/employer/${partnerInfo?.slug}/?${qs.toString()}`;

    await Api.call("account.passwordResets", Api.api.account.passwordResets, {
      email: surveyAnswers.createAccount?.email,
      postResetRedirectUrl,
    })
      .then(() => {
        const { eligibilityCheck, createAccount } = surveyAnswers;
        setSurveyAnswerCookie({ eligibilityCheck, createAccount });
        setIsSendingResetPasswordEmail(false);
        if (!toast.isActive(toastId)) {
          toast({
            id: toastId,
            duration: 3000,
            render: () => <Alert>{t("existingPassword.emailSent")}</Alert>,
          });
        }
      })
      .catch(() => {
        error(ErrorState.DEFAULT);
      });
  };

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionHelperText={t("existingPassword.questionHelperText", {
        email: surveyAnswers.createAccount?.email,
        context: isPwResetRedirect ? "pwResetRedirect" : "",
      })}
      questionId={QUESTION_ID}
      questionText={t("existingPassword.questionText", {
        context: isPwResetRedirect ? "pwResetRedirect" : "",
      })}
      hideBackButton={isPwResetRedirect}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={PasswordInputWithoutStrengthValidationSchema}
      >
        {({ handleChange, handleSubmit, resetForm, validateField }) => {
          const handleInputChange = async (
            e: React.ChangeEvent<HTMLInputElement>,
            fieldName: keyof ExistingPasswordFormValues,
          ) => {
            // This is a hacky method to get each field to validate on change
            // individually.
            await handleChange(e);
            validateField(fieldName);
          };

          return (
            <Form onSubmit={handleSubmit}>
              <Stack spacing={Spacing[8]}>
                <PasswordInput
                  name="password"
                  onChange={(e) => handleInputChange(e, "password")}
                  showPasswordStrengthBar={false}
                  userInputs={[]}
                />
                <Stack spacing={Spacing[4]}>
                  <Button
                    colorScheme="primary"
                    isLoading={isCheckingPassword}
                    size="xl"
                    type="submit"
                  >
                    {t("existingPassword.form.continue")}
                  </Button>
                  <Button
                    size="xl"
                    onClick={() =>
                      handleResetPassword().then(() => resetForm())
                    }
                    variant="unstyled"
                    isLoading={isSendingResetPasswordEmail}
                    display="flex"
                  >
                    {t("existingPassword.form.resetPassword")}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </BaseQuestion>
  );
};

export const Q_EXISTING_PASSWORD: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ hasB2cAccount }) => !!hasB2cAccount,
  component: ExistingPasswordQuestion,
};
