import React from "react";
import { useTranslation } from "react-i18next";

import { PHQ_OPTIONS } from "@/constants";
import { useAppContext } from "@/contexts";
import { shouldAskBaselineOutcomes } from "@/utils/survey/outcomes";

import { QuestionComponentProps, QuestionDefinition } from "../../../Question";
import { MultipleChoiceQuestion } from "../../core";

const QUESTION_ID = "phq2";

declare module "@/contexts" {
  interface SurveyAnswers {
    phq2?: string;
  }
}

const Phq2Question: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { surveyMetadata } = useAppContext();
  const { t } = useTranslation();

  const questionText =
    surveyMetadata?.name === "RECURRING_OUTCOMES"
      ? t("phq2.questionText.recurringOutcomes")
      : t("phq2.questionText.enrollment");
  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={PHQ_OPTIONS}
      questionId={QUESTION_ID}
      questionText={questionText}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_PHQ_2: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo }) =>
    shouldAskBaselineOutcomes(partnerInfo),
  component: Phq2Question,
};
