/**
 * Calculate current BMI using Metric measures
 */
export function calculateBMIMetric(heightCm: number, currentWeightKg: number) {
  const heightInMeter = heightCm / 100;
  return currentWeightKg / (heightInMeter * heightInMeter);
}

/**
 * Calculate weight in metric units from a BMI value and height in metric units
 * <=> bmi * (heightInMeters^2) = targetWeight
 */
export function calculateMetricWeightFromBMI(height: number, bmi: number) {
  const heightInMeters = height / 100;
  return bmi * (heightInMeters * heightInMeters);
}
