import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Api } from "@noom/noomscape";

import { Survey } from "@/components/survey/Survey";
import QuestionSets from "@/components/survey/questionSets/questionSets";
import { ErrorState } from "@/constants";
import { useAppContext } from "@/contexts";
import { Partner } from "@/models";
import { getAutologinData } from "@/utils/cookies";
import { captureException } from "@/utils/sentry";

export const RetriageView: React.FC = () => {
  const { partnerInfo, setErrorState, setPartnerInfo, setEnrollmentType } =
    useAppContext();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { employerSlug } = useParams<AppParams>();

  const autologinData = getAutologinData();

  const onSurveyComplete = async () => {
    if (autologinData) {
      window.location.href = "https://content.noom.com/?score=1&close=1";
    }
  };

  useEffect(() => {
    setEnrollmentType("RETRIAGE");
  }, []);

  useEffect(() => {
    const getPartnerInfo = async () => {
      try {
        const partnerInformation: Partner = await Api.call(
          "partner.getPartnerBySlug",
          Api.api.partner.getPartnerBySlug,
          {
            slug: employerSlug,
          },
        );

        if (!partnerInformation.configs.OFFERS_RETRIAGE) {
          navigate({ pathname: `/employer/${employerSlug}`, search });
          return;
        }

        setPartnerInfo(partnerInformation);
      } catch (e) {
        captureException(e);
        setErrorState(ErrorState.DEFAULT);
        navigate("/error");
      }
    };

    getPartnerInfo();
  }, [employerSlug]);

  return (
    partnerInfo && (
      <Survey
        baseRoute={`/employer/${employerSlug}/rt`}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSurveyComplete={onSurveyComplete}
        questionSet={QuestionSets.RETRIAGE_SURVEY}
      />
    )
  );
};
