import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Button, Input, Spacing, Stack } from "@noom/wax-component-library";

import { QuestionComponentProps, QuestionDefinition } from "../../Question";
import { BaseQuestion } from "../core";

const MAX_LENGTH = 100;
const QUESTION_ID = "otherEthnicity";

declare module "@/contexts" {
  interface SurveyAnswers {
    otherEthnicity?: string;
  }
}

type OtherEthnicityFormValues = {
  otherEthnicity?: string;
};

const OtherEthnicityQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const initialValues: OtherEthnicityFormValues = {
    otherEthnicity: surveyAnswers.otherEthnicity,
  };

  const validationSchema = Yup.object({
    otherEthnicity: Yup.string()
      .required(t("otherEthnicity.errors.required"))
      .max(
        MAX_LENGTH,
        t("form.errors.maxLength", {
          label: "Ethnicity",
          maxLength: MAX_LENGTH,
        }),
      ),
  });

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionId={QUESTION_ID}
      questionText={t("otherEthnicity.questionText")}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={({ otherEthnicity }: OtherEthnicityFormValues) =>
          onClickNext(otherEthnicity)
        }
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ errors, getFieldProps, handleSubmit, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={Spacing[8]}>
              <Input
                {...getFieldProps("otherEthnicity")}
                autoFocus
                error={touched.otherEthnicity && errors.otherEthnicity}
              />
              <Button colorScheme="primary" size="xl" type="submit">
                {t("otherEthnicity.ctaText")}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </BaseQuestion>
  );
};

export const Q_OTHER_ETHNICITY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ surveyAnswers: { ethnicity } }) =>
    (ethnicity || []).includes("other"),
  component: OtherEthnicityQuestion,
};
