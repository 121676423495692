import { Api } from "@noom/noomscape";

import { UpdateLastTriageTimestampRequest } from "@/api/dpp/updateLastTriageTimestamp";
import { captureException } from "@/utils/sentry";

export const useUpdateLastTriageTimestamp = () => {
  const updateLastTriageTimestamp = async (accessCode: string) => {
    const request: UpdateLastTriageTimestampRequest = {
      accessCode,
      newLastTriageTimestamp: new Date().toISOString(),
    };
    return Api.call(
      "dpp.updateLastTriageTimestamp",
      Api.api.dpp.updateLastTriageTimestamp,
      request,
    ).catch((e: unknown) => {
      captureException(e);
    });
  };

  return { updateLastTriageTimestamp };
};
