import React from "react";
import { useParams } from "react-router-dom";

import { Survey } from "@/components/survey/Survey";
import QuestionSets from "@/components/survey/questionSets/questionSets";

/*
 * DevView is a wrapper to enable viewing a single question page for development.
 * See
 *
 */
export const DevView: React.FC = () => {
  const { questionSetName } = useParams<{ questionSetName: string }>();

  const questionSet = QuestionSets[questionSetName || ""];

  const baseRoute = {
    DPP_SURVEY: "/enrollment",
    RETRIAGE_SURVEY: "/employer/test/rt",
    TRANSITION_SURVEY: "/transition",
  }[questionSetName!];

  if (window.ENV.NOOM_ENVIRONMENT !== "development" || !questionSet) {
    return null;
  }
  return (
    <Survey
      baseRoute={baseRoute!}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSurveyComplete={() => new Promise<void>(() => {})}
      questionSet={questionSet}
      resetOnOpen={false}
    />
  );
};
