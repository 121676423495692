import { CompassColor } from "@noom/wax-component-library";

export const defaultFieldStyles = {
  borderColor: CompassColor.grey2,
  borderRadius: "8px",
  height: "62px",
  maxWidth: "400px",
  width: "100%",
  _focus: {
    borderColor: CompassColor.lagoon,
    boxShadow: "none",
  },
  _hover: {
    borderColor: CompassColor.lagoon,
  },
};
