import React, { useEffect } from "react";

import { Heading, Image } from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { useTrackEvent } from "@/hooks";

import { BaseInterstitial } from "../../core";

const QUESTION_ID = "activeSubscription";

const ActiveSubscription: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
}) => {
  const { trackIdentifiedPageViewed } = useTrackEvent();

  useEffect(() => {
    trackIdentifiedPageViewed("activeSubscription");
  }, []);

  return (
    <BaseInterstitial
      ctaText="Let's do it"
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      questionId={QUESTION_ID}
      showBackButton
      width="600px"
    >
      <Image
        alt=""
        margin="auto"
        src="/assets/img/loading-gear.svg"
        width="fit-content"
      />
      <Heading fontSize="24px" fontWeight={500} lineHeight="32px">
        Looks like you currently have an active Noom subscription. No problem -
        we can transition this to a sponsored membership.
      </Heading>
    </BaseInterstitial>
  );
};

export const Q_ACTIVE_SUBSCRIPTION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => true,
  component: ActiveSubscription,
};
