import { differenceInYears, format } from "date-fns";

import { DOB_FORMAT, ProgramOffering } from "@/constants";
import { SurveyAnswers } from "@/contexts";
import { User } from "@/models";
import type { SignupFormData } from "@/models/SignupFormData";

// Height and weight are required to create an account, but we don't show
// Noom Mood enrollees these fields and pass default values instead.
// The actual values aren't important.
const DEFAULT_HEIGHT_FT = 5;
const DEFAULT_HEIGHT_IN = 7;
const DEFAULT_WEIGHT_LB = 150;
const HEIGHT_MULTIPLIER_IN_TO_CM = 2.54;
const WEIGHT_MULTIPLIER_LB_TO_KG = 0.453592;


export const formatAsUserData = ({
  formData,
  b2bUserId,
  program,
  dppTriageKey,
  surveyAnswers,
  policyId,
}: {
  formData: SignupFormData;
  b2bUserId: string;
  program: ProgramOffering;
  dppTriageKey?: string;
  surveyAnswers?: SurveyAnswers;
  policyId?: string;
}): User => {
  const {
    address,
    age,
    consentSignature,
    consentStatus,
    dob,
    email,
    employeeId,
    firstName,
    gender,
    heightFeetPortion = DEFAULT_HEIGHT_FT,
    heightInchesPortion = DEFAULT_HEIGHT_IN,
    lastName,
    locale,
    orderScale,
    password,
    phoneNumber,
    shippingAddress,
    weight = DEFAULT_WEIGHT_LB,
    mhmdaOptIn,
  } = formData;

  const formattedPhoneNumber = phoneNumber.dialCode.concat(phoneNumber.value);

  const calculatedAge = dob
    ? differenceInYears(new Date(), dob as Date)
    : (age as number);

  const userData: User = {
    age: calculatedAge,
    email: email.trim(),
    gender,
    heightInCm:
      (heightFeetPortion * 12 + heightInchesPortion) *
      HEIGHT_MULTIPLIER_IN_TO_CM,
    locale,
    name: firstName.trim(),
    password,
    phoneNumber: formattedPhoneNumber,
    weightInKg: weight * WEIGHT_MULTIPLIER_LB_TO_KG,
    surveyAnswers,
    extras: {
      address,
      b2bUserId,
      consentSignature: consentSignature?.trim(),
      consentStatus,
      employeeId: employeeId?.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      mhmdaOptIn,
      orderScale,
      phoneNumber: formattedPhoneNumber,
      program,
    },
  };

  if (dob) {
    userData.extras = {
      ...userData.extras,
      dob: format(dob as Date, DOB_FORMAT),
    };
  }

  if (dppTriageKey) {
    userData.extras = {
      ...userData.extras,
      dppTriageKey,
    };
  }

  if (orderScale) {
    userData.extras = {
      ...userData.extras,
      shippingAddress: { ...(shippingAddress || address) },
    };
  }

  if (policyId) {
    userData.extras = {
      ...userData.extras,
      policyId,
    };
  }

  /**
   * Only store the medicare question answers if the user stays in Med and is
   * 65+ - we don't need to store these if the user opts out of Med
   *
   * Context: https://noom.slack.com/archives/C06T19HJWD9/p1715291960358249
   */
  if (program === "MED" && calculatedAge > 64) {
    userData.extras = {
      ...userData.extras,
      enrolledInMedicare: surveyAnswers?.enrolledInMedicare,
      medicareConsent: surveyAnswers?.medicareConsent,
    };
  }

  return userData;
};
