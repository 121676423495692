import { DppTriageProto_CompletionStatus } from "@noom/noom-contracts/noom_contracts/healthcare/dpp_triage";
import { Api } from "@noom/noomscape";

import axios, { AxiosResponse } from "axios";

type DppTriageRequest = {
  completionStatus: DppTriageProto_CompletionStatus;
  partnerId?: number | undefined;
  participantId?: string | undefined;
  resourceKey?: string | undefined;
  survey: Record<string, any>;
};

type DppTriageResponse = {
  key: string;
};

Api.register(
  "dpp.saveTriageData",
  async ({
    completionStatus,
    participantId,
    partnerId,
    resourceKey,
    survey,
  }: DppTriageRequest) => {
    const response: AxiosResponse<DppTriageResponse> = await axios({
      baseURL: window.ENV.SERVER_URL,
      data: {
        completionStatus,
        participantId,
        partnerId,
        resourceKey,
        survey,
      },
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      url: "/dpp/v1/triage",
      urlWithPathParams: "/dpp/v1/triage",
    });

    return response.data;
  },
);
