import { registerHandler } from "@/utils/pixels/handler";

declare const gtag: any;

export default function registerHandlers() {
  registerHandler("purchase", ({ erev_13_months }) => {
    gtag("event", "conversion", {
      send_to: "AW-783925782/XdY_CJWPuYsBEJaE5_UC",
      value: erev_13_months,
      currency: "USD",
    });
  });
}
