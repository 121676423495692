/**
 * Endpoint for checking eligibility
 *
 * Copyright (C) 2022 Noom, Inc.
 * @author eric
 */

import axios from "axios";
import { format, parse } from "date-fns";

import { Api } from "@noom/noomscape";

import { DOB_FORMAT } from "@/constants";
import {
  EligibilityResponse,
  EligibilityVerificationProperties,
} from "@/models";
import {
  RetriageEligibilityResponse,
  RetriageEligibilityVerificationProperties,
} from "@/models/RetriageEligibility";

function generateQueryParams(
  properties:
    | EligibilityVerificationProperties
    | RetriageEligibilityVerificationProperties,
  partnerId: number,
  leadSource?: string,
) {
  const queryParams = new URLSearchParams();
  queryParams.set("partnerId", `${partnerId}`);
  if (leadSource) {
    queryParams.set("leadSource", leadSource);
  }

  Object.entries(properties).forEach(([key, value]) => {
    if (value) {
      if (key === "dateOfBirth") {
        // Date of birth must be "yyyy-MM-dd" in order for CoachServer to parse this
        queryParams.set(
          key,
          format(parse(value, DOB_FORMAT, new Date()), "yyyy-MM-dd"),
        );
      } else {
        queryParams.set(key, value);
      }
    }
  });
  return queryParams;
}

Api.register(
  "partner.checkEligibility",
  async ({
    eligibilityVerificationProperties,
    leadSource,
    partnerId,
  }: {
    eligibilityVerificationProperties: EligibilityVerificationProperties;
    leadSource?: string;
    partnerId: number;
  }) => {
    const response = await axios({
      url: "/partners/v1/checkEligibility",
      urlWithPathParams: "/partners/v1/checkEligibility",
      baseURL: window.ENV.SERVER_URL,
      params: generateQueryParams(
        eligibilityVerificationProperties,
        partnerId,
        leadSource,
      ),
      method: "GET",
    });

    return response.data as EligibilityResponse;
  },
);

Api.register(
  "partner.checkRetriageEligibility",
  async ({
    properties,
    partnerId,
  }: {
    properties: RetriageEligibilityVerificationProperties;
    partnerId: number;
  }) => {
    const response = await axios({
      url: "/partners/v1/checkRetriageEligibility",
      urlWithPathParams: "/partners/v1/checkRetriageEligibility",
      baseURL: window.ENV.SERVER_URL,
      params: generateQueryParams(properties, partnerId),
      method: "GET",
    });

    return response.data as RetriageEligibilityResponse;
  },
);
