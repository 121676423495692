import React from "react";
import { isMobile } from "react-device-detect";

import {
  Box,
  Button,
  Heading,
  Image,
  Size,
  Stack,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";

import { BaseOutline } from "../core";

const QUESTION_ID = "retriageKeepPlan";

const RetriageKeepPlanConfirmation: React.FC<QuestionComponentProps> = ({
  onClickNext,
}) => (
  <BaseOutline>
    <Stack
      alignItems="center"
      pt={{ base: "40px", md: "100px" }}
      gap={{ base: "30px", md: "50px" }}
    >
      <Image alt="" src="/assets/img/small-checkmark.svg" width="140px" />
      <Heading textAlign="center" size={Size.lg}>
        You got it. We&rsquo;ll keep you in your existing program.
      </Heading>

      {isMobile && (
        <Box>
          <Button
            colorScheme="primary"
            onClick={() => onClickNext(null)}
            size="md"
            whiteSpace="unset"
          >
            Return to App
          </Button>
        </Box>
      )}
    </Stack>
  </BaseOutline>
);

export const Q_RETRIAGE_KEEP_PLAN: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ surveyAnswers }) =>
    surveyAnswers.confirmChangeProgram === false,
  component: RetriageKeepPlanConfirmation,
};
