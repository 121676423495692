import { useLocation, useNavigate } from "react-router-dom";

import {
  EnrollmentSuccessType,
  ErrorState,
  ScreenedOutReason,
} from "@/constants";
import { useAppContext } from "@/contexts";

export const useGoto = () => {
  const { setScreenedOutReason, setErrorState } = useAppContext();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const success = (type: EnrollmentSuccessType) => {
    const qs = new URLSearchParams();
    qs.append("type", type);
    navigate({
      pathname: "/success",
      search: qs.toString(),
    });
  };

  const error = (errorState?: ErrorState) => {
    if (errorState) {
      setErrorState(errorState);
    }
    navigate({
      pathname: "/error",
      search,
    });
  };

  const notFound = () => {
    navigate({
      pathname: "/not-found",
    });
  };

  const screenOut = (reason: ScreenedOutReason) => {
    setScreenedOutReason(reason);

    // This logic redirects to the screen out page by taking the current
    // pathname and replacing the last segment with the /ineligible route.
    // This is needed to support flows with different segment lengths.
    const segments = pathname.split("/");
    const baseRoute = segments.slice(0, segments.length - 1).join("/");
    navigate({ pathname: `${baseRoute}/ineligible`, search });
  };

  return { error, notFound, screenOut, success };
};
