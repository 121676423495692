export const isBuyflowTraffic = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("leadSource") === "buyflow";
};

// The list of EU (and EEA / EFTA) country codes.
const EU_COUNTRIES = [
  "AT",
  "BE",
  "BG",
  "CH",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GB",
  "GR",
  "HR",
  "HU",
  "IE",
  "IS",
  "IT",
  "LI",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "NO",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
  // http://ec.europa.eu/eurostat/statistics-explained/index.php/Tutorial:Country_codes_and_protocol_order
  // The two-letter ISO code is used (ISO 3166 alpha-2) except for Greece and the United Kingdom,
  // for which the abbreviations EL and UK have to be used.
  "EL",
  "UK",
];

export const isEU = (countryCode: string) => EU_COUNTRIES.includes(countryCode);
