import React from "react";

import {
  CompassColor,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { ScreenedOutReason } from "@/constants";
import { shouldAskBaselineOutcomes } from "@/utils/survey/outcomes";

import { BaseInterstitial } from "../../core";

const QUESTION_ID = "baselineHealth";

const BaselineHealth: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
}) => (
  <BaseInterstitial
    backgroundColor={CompassColor.lagoon}
    onClickBack={onClickBack}
    onClickNext={onClickNext}
    questionId={QUESTION_ID}
    width="600px"
    ctaText="Let's go"
  >
    <Stack spacing={Spacing[8]} mb="16px" maxW="550px">
      <Image src="/assets/img/partyHat.svg" m="0 auto" />
      <Text
        color={CompassColor.white}
        fontSize="28px"
        lineHeight="36px"
        fontWeight="500"
        fontFamily="Untitled Serif"
      >
        Noom goes beyond victories on a scale
      </Text>
      <Text
        color={CompassColor.white}
        fontSize="18px"
        lineHeight="26px"
        fontWeight="400"
      >
        Our program is designed to help you feel better, move better, and even
        take less medication.
        <br />
        <br />
        The following questions help us understand your health history and where
        your health is right now. They also enable you to track your
        non&#8209;scale progress during your program.
      </Text>
    </Stack>
  </BaseInterstitial>
);

export const Q_BASELINE_HEALTH: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, screenedOutReason }) =>
    // Hide if shown in the metabolic path and was screened out.
    // Should include its own ScreenedOutReason (if applicable) and any that follow this question.
    ![ScreenedOutReason.HEALTH_CONDITION].includes(
      screenedOutReason as ScreenedOutReason,
    ) && shouldAskBaselineOutcomes(partnerInfo),
  component: BaselineHealth,
};
