import { QuestionOption } from "@/components/survey/Question";

// TODO (@jason.pao): Convert survey answer IDs to enums and move to this file

export enum HealthGoal {
  LOSING_WEIGHT = "losingWeight",
  REDUCING_STRESS_ANXIOUS_FEELINGS = "reducingStressAnxiousFeelings",
}

export enum NoomMedMoreOptionsSelection {
  MED = "med",
  OTHER = "other",
}

export enum NoomMedOverviewSelection {
  JOIN = "join",
  SEE_MORE_OPTIONS = "seeMoreOptions",
}

export enum PregnantSelection {
  NO = "no",
  NO_BUT_NURSING = "noButNursing",
  NO_BUT_PLANNING = "noButPlanning",
  YES = "yes",
}

export const PHQ_OPTIONS: (QuestionOption & { weight: number })[] = [
  { id: "notAtAll", text: "Not at all", weight: 0 },
  { id: "severalDays", text: "Several days", weight: 1 },
  { id: "moreThanHalfTheDays", text: "More than half the days", weight: 2 },
  { id: "nearlyEveryDay", text: "Nearly every day", weight: 3 },
];
