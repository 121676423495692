import { useFormikContext } from "formik";
import i18next from "i18next";
import React from "react";
import * as Yup from "yup";

import { Box, Spacing, Stack } from "@noom/wax-component-library";

import { InputWithSuffix } from "@/components/form";
import { INPUT_FIELD_WIDTH } from "@/constants/enrollment";

import { enforcePositiveInt } from "../form/utils";

const FEET_INPUT_FIELD_WIDTH = Math.ceil((INPUT_FIELD_WIDTH - 16) / 2);
const INCHES_INPUT_FIELD_WIDTH = Math.floor((INPUT_FIELD_WIDTH - 16) / 2);

type HeightWeightProps = {
  focusHeightFeet?: boolean;
  hideHeight?: boolean;
};

export type HeightWeightFormValues = {
  heightFeetPortion?: number;
  heightInchesPortion?: number;
  weight?: number;
};

export const HeightWeightSchema = Yup.object({
  heightFeetPortion: Yup.number()
    .nullable(true)
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(() => i18next.t("form.height.errors.required"))
    .positive(() => i18next.t("form.height.errors.invalid"))
    .min(0, () => i18next.t("form.height.errors.invalid"))
    .max(8, () => i18next.t("form.height.errors.invalid")),
  heightInchesPortion: Yup.number()
    .nullable(true)
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(() => i18next.t("form.height.errors.required"))
    .positive(() => i18next.t("form.height.errors.invalid"))
    .min(0, () => i18next.t("form.height.errors.invalid"))
    .max(11, () => i18next.t("form.height.errors.invalid")),
  weight: Yup.number()
    .nullable(true)
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(() => i18next.t("form.weight.errors.required"))
    .positive(() => i18next.t("form.weight.errors.invalid"))
    // 335 kg is maximum value for account creation - 741 lbs = 336 kg
    // https://github.com/noom/backend/blob/d9f504457c5392cf99f058972eb4d25b6c14bb9f/com.noom.api/src/main/java/com/noom/api/user/account/model/CreateAccountRequest.kt
    .max(740, () => i18next.t("form.weight.errors.invalid")),
});

export const HeightWeightForm: React.FC<HeightWeightProps> = ({
  focusHeightFeet,
  hideHeight = false,
}) => {
  const { errors, getFieldProps, handleChange, touched, validateField } =
    useFormikContext<HeightWeightFormValues>();

  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: keyof HeightWeightFormValues,
  ) => {
    // This is a hacky method to get each field to validate on change
    // individually.
    await handleChange(e);
    validateField(fieldName);
  };

  return (
    <Stack spacing={Spacing[4]}>
      {!hideHeight && (
        <Box display="flex" gap="var(--spacing-m)">
          <InputWithSuffix
            {...getFieldProps("heightFeetPortion")}
            aria-label="height feet portion"
            autoFocus={!!focusHeightFeet}
            error={touched.heightFeetPortion && errors.heightFeetPortion}
            maxWidth={`${FEET_INPUT_FIELD_WIDTH}px`}
            onChange={(e) => handleInputChange(e, "heightFeetPortion")}
            onKeyDown={enforcePositiveInt}
            suffix="ft."
            type="number"
          />
          <InputWithSuffix
            {...getFieldProps("heightInchesPortion")}
            aria-label="height inches portion"
            error={touched.heightInchesPortion && errors.heightInchesPortion}
            maxWidth={`${INCHES_INPUT_FIELD_WIDTH}px`}
            onChange={(e) => handleInputChange(e, "heightInchesPortion")}
            onKeyDown={enforcePositiveInt}
            suffix="in."
            type="number"
          />
        </Box>
      )}
      <InputWithSuffix
        {...getFieldProps("weight")}
        aria-label="weight portion"
        error={touched.weight && errors.weight}
        maxWidth={`${INPUT_FIELD_WIDTH}px`}
        onChange={(e) => handleInputChange(e, "weight")}
        onKeyDown={enforcePositiveInt}
        suffix="lbs."
        type="number"
      />
    </Stack>
  );
};
