import React from "react";

import { MultipleChoiceOptions } from "@/components/survey/multipleChoice";

import { QuestionComponentProps, QuestionOption } from "../../Question";

import { BaseQuestion } from "./BaseQuestion";

type MultipleChoiceQuestionProps = Omit<
  QuestionComponentProps,
  "surveyAnswers"
> & {
  options: QuestionOption[];
  questionDescription?: string;
  questionHelperText?: string;
  questionId: string;
  questionText: string;
  selectedItem: string;
};

export const MultipleChoiceQuestion: React.FC<MultipleChoiceQuestionProps> = ({
  onClickBack,
  onClickNext,
  options,
  questionDescription,
  questionHelperText,
  questionId,
  questionText,
  selectedItem,
}) => (
  <BaseQuestion
    onClickBack={onClickBack}
    questionDescription={questionDescription}
    questionHelperText={questionHelperText}
    questionId={questionId}
    questionText={questionText}
  >
    <MultipleChoiceOptions
      selectedItem={selectedItem}
      options={options}
      onClick={(optionId: string) => onClickNext(optionId)}
    />
  </BaseQuestion>
);
