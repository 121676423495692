import React, { useState } from "react";
import { isMobile } from "react-device-detect";

import { Button, Select, SelectOption } from "@noom/wax-component-library";

import { QuestionComponentProps, QuestionOption } from "../../Question";

import { BaseQuestion } from "./BaseQuestion";

type SelectQuestionProps = Pick<
  QuestionComponentProps,
  "onClickBack" | "onClickNext"
> & {
  autoFocus?: boolean;
  initialValue?: string;
  options: QuestionOption[];
  placeholder?: string;
  questionDescription?: string;
  questionHelperText?: string;
  questionId: string;
  questionText: string;
};

export const SelectQuestion: React.FC<SelectQuestionProps> = ({
  autoFocus = true,
  initialValue,
  onClickBack,
  onClickNext,
  options,
  placeholder,
  questionDescription,
  questionHelperText,
  questionId,
  questionText,
}) => {
  const [value, setValue] = useState(initialValue);

  const selectOptions: SelectOption[] = options.map((option) => ({
    label: option.text,
    value: option.id,
  }));

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionDescription={questionDescription}
      questionHelperText={questionHelperText}
      questionId={questionId}
      questionText={questionText}
    >
      <Select
        options={selectOptions}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setValue(e.target.value)
        }
        value={value}
        // Chakra checks if placeholder is falsy, so use " " instead of ""
        placeholder={placeholder === "" ? " " : placeholder}
        // On mobile, auto focusing the select causes the dropdown to open immediately potentially covering content
        autoFocus={autoFocus && !isMobile}
      />
      <Button
        colorScheme="primary"
        disabled={!value}
        onClick={() => value && onClickNext(value)}
        size="xl"
      >
        Next
      </Button>
    </BaseQuestion>
  );
};
