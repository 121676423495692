import { Api } from "@noom/noomscape";

import type { CreateLoginTokenResponse } from "@/api/account/createLoginToken";
import { captureException } from "@/utils/sentry";

import { AxiosError } from "axios";

export const useCreateLoginToken = () => {
  const createLoginToken = async (email: string, password: string) => {
    const { access_token }: CreateLoginTokenResponse = await Api.call(
      "account.createLoginToken",
      Api.api.account.createLoginToken,
      {
        email,
        password,
      },
    );

    return access_token;
  };

  const isProvidingValidPassword = async (email: string, password: string) => {
    try {
      await createLoginToken(email, password);
      return true;
    } catch (e: unknown) {
      let errorCode;
      if (e instanceof AxiosError) {
        errorCode = e.response?.data.errorCode;

        if (errorCode === "INCORRECT_PASSWORD") {
          return false;
        }

        if (errorCode === "NO_ACCOUNT_FOR_INFO") {
          return true;
        }
      }

      captureException(e, { errorCode });
      return false;
    }
  };

  return { createLoginToken, isProvidingValidPassword };
};
