import React from "react";
import { useTranslation } from "react-i18next";

import {
  CompassColor,
  CompassLoader,
  Heading,
  Stack,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";

import { BaseInterstitial } from "../core";

const QUESTION_ID = "personalizingProgram";

const RetriagePersonalizingProgram: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
}) => {
  const { t } = useTranslation();

  return (
    <BaseInterstitial
      autoAdvance
      autoAdvanceMs={2500}
      centerChildren
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      questionId={QUESTION_ID}
    >
      <Stack alignItems="center">
        <CompassLoader color={CompassColor.black} size="lg" />
      </Stack>
      <Heading
        fontSize={{ base: "24px", lg: "28px" }}
        fontWeight={500}
        lineHeight={{ base: "32px", lg: "36px" }}
        textAlign="center"
      >
        {t("retriagePersonalizingProgram.message")}
      </Heading>
    </BaseInterstitial>
  );
};

export const Q_RETRIAGE_PERSONALIZING_PROGRAM: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ retriageEligibility: { currentProgram } = {} }) =>
    currentProgram === "WEIGHT",
  component: RetriagePersonalizingProgram,
};
