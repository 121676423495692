import axios from "axios";
import { useState } from "react";

import { Api } from "@noom/noomscape";

type DppMigrationRequest = {
  accessCode: string;
  survey: { [key: string]: string };
};

Api.register("account.migrateToDpp", async (request: DppMigrationRequest) =>
  axios({
    url: "/dpp/v1/migrate",
    urlWithPathParams: "/dpp/v1/migrate",
    baseURL: window.ENV.SERVER_URL,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: request,
  }),
);

export const useMigrateToDpp = () => {
  const [apiError, setError] = useState<unknown>();
  const [apiLoading, setApiLoading] = useState(true);

  const migrateToDpp = async (request: DppMigrationRequest) => {
    try {
      await Api.call(
        "account.migrateToDpp",
        Api.api.account.migrateToDpp,
        request,
      );
      setApiLoading(false);
    } catch (e) {
      const err = e as Error;
      setError(err.message);
      setApiLoading(false);
    }
  };

  return { migrateToDpp, apiLoading, apiError };
};
