/**
 * Endpoint for getting an upid for a given passcode
 *
 * Copyright (C) 2021 Noom, Inc.
 * @author eric
 */

import axios from "axios";
import { Api } from "@noom/noomscape";

Api.register(
  "upid.getPasscode",
  async ({
    partnerSlug,
    passcode,
  }: {
    partnerSlug: string;
    passcode: string;
  }) => {
    const response = await axios({
      url: `/batches/-/batchPasscodes/${passcode}:generateUpidForPartner`,
      urlWithPathParams:
        "/batches/-/batchPasscodes/{passcode}:generateUpidForPartner",
      baseURL: window.ENV.SERVER_URL,
      method: "POST",
      params: { partnerSlug },
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data as string;
  },
);
