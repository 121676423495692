import React from "react";
import { useTranslation } from "react-i18next";

import { shouldAskMonthlyQuestion } from "@/utils/survey/outcomes";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "reachingHealthGoals";

declare module "@/contexts" {
  interface SurveyAnswers {
    reachingHealthGoals?: string;
  }
}

const ReachingHealthGoalsQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    {
      id: "didNotImproveAtAll",
      text: t("reachingHealthGoals.options.didNotImproveAtAll"),
    },
    {
      id: "minimallyImproved",
      text: t("reachingHealthGoals.options.minimallyImproved"),
    },
    {
      id: "somewhatImproved",
      text: t("reachingHealthGoals.options.somewhatImproved"),
    },
    {
      id: "moderatelyImproved",
      text: t("reachingHealthGoals.options.moderatelyImproved"),
    },
    {
      id: "veryMuchImproved",
      text: t("reachingHealthGoals.options.veryMuchImproved"),
    },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("reachingHealthGoals.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_REACHING_HEALTH_GOALS: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ userProfile }) =>
    userProfile?.currentProgram !== "MOOD" ||
    shouldAskMonthlyQuestion(userProfile),
  component: ReachingHealthGoalsQuestion,
};
