import { QuestionSection } from "../Question";
import {
  Q_ACTIVE_SUBSCRIPTION,
  Q_TRANSITION_EXPLAINER,
} from "../questionTypes/b2c-to-b2b";
import {
  Q_A1C,
  Q_BASELINE_HEALTH,
  Q_COMPLETE_PROFILE,
  Q_CONTINUE_PROGRAM,
  Q_CREATE_ACCOUNT,
  Q_CREATE_PASSWORD,
  Q_DIFFICULTIES,
  Q_EATING_DISORDER,
  Q_EDUCATION,
  Q_ELIGIBILITY_CHECK,
  Q_ENROLLED_IN_MEDICARE,
  Q_ETHNICITY,
  Q_EXISTING_PASSWORD,
  Q_FAMILY_HISTORY,
  Q_FAMILY_HISTORY_WEIGHT,
  Q_GENDER,
  Q_GESTATIONAL_DIABETES,
  Q_GLP1_MEDICATION,
  Q_HAD_A1C_TESTED,
  Q_HEALTH_CARE_PROFESSIONAL,
  Q_HEALTH_CONDITIONS,
  Q_HEALTH_GOAL,
  Q_HEIGHT_WEIGHT,
  Q_HYPERTENSION,
  Q_LOCATION_STATE,
  Q_MEDICARE_CONSENT,
  Q_MEDICATIONS,
  Q_MOTIVATION,
  Q_MSK_PAIN,
  Q_MSK_PAIN_LEVEL,
  Q_NOOM_MED_MORE_OPTIONS,
  Q_NOOM_MED_OVERVIEW,
  Q_NURSING,
  Q_OTHER_ETHNICITY,
  Q_OUTCOMES_A1C,
  Q_OUTCOMES_GLP1_MEDICATION,
  Q_OUTCOMES_HAD_A1C_TESTED,
  Q_OUTCOMES_HEALTH_CONDITIONS,
  Q_OUTCOMES_MEDICATIONS,
  Q_OUTCOMES_MSK_PAIN,
  Q_OUTCOMES_MSK_PAIN_LEVEL,
  Q_PERSONALIZING_PROGRAM,
  Q_PHQ_1,
  Q_PHQ_2,
  Q_PHQ_3,
  Q_PHQ_4,
  Q_PHYSICAL_ACTIVITY,
  Q_PREDIABETES_HISTORY,
  Q_PREGNANT,
  Q_PROGRAM_OVERVIEW,
  Q_PROGRAM_SET,
  Q_REASON_FOR_LOSING_WEIGHT,
  Q_RETRIAGE_A1C,
  Q_RETRIAGE_DIFFICULTIES,
  Q_RETRIAGE_EATING_DISORDER,
  Q_RETRIAGE_EDUCATION,
  Q_RETRIAGE_ETHNICITY,
  Q_RETRIAGE_FAMILY_HISTORY,
  Q_RETRIAGE_GENDER,
  Q_RETRIAGE_GESTATIONAL_DIABETES,
  Q_RETRIAGE_HEALTH_CARE_PROFESSIONAL,
  Q_RETRIAGE_HEALTH_CONDITIONS,
  Q_RETRIAGE_HEIGHT_WEIGHT,
  Q_RETRIAGE_HYPERTENSION,
  Q_RETRIAGE_MOTIVATION,
  Q_RETRIAGE_PHYSICAL_ACTIVITY,
  Q_RETRIAGE_PREDIABETES_HISTORY,
  Q_RETRIAGE_PREGNANT,
  Q_RETRIAGE_SEX,
  Q_SCALE_OPT_IN,
  Q_SCREENED_OUT,
  Q_SEX,
} from "../questionTypes/dpp-triage";
import {
  Q_EXERCISE,
  Q_OUTCOMES_SURVEY_COMPLETE,
  Q_OUTCOMES_WEIGHT,
  Q_REACHING_HEALTH_GOALS,
} from "../questionTypes/outcomes";
import {
  Q_CONFIRM_CHANGE_PROGRAM,
  Q_DPP_INFORMATION,
  Q_RETRIAGE_ELIGIBILITY_CHECK,
  Q_RETRIAGE_EXCLUSION,
  Q_RETRIAGE_KEEP_PLAN,
  Q_RETRIAGE_PERSONALIZING_PROGRAM,
  Q_RETRIAGE_RESULT,
  Q_RETRIAGE_TRANSITION,
} from "../questionTypes/retriage";

const QuestionSets: Record<string, QuestionSection[]> = {
  DPP_SURVEY: [
    {
      description: "Account Creation",
      percentage: 25,
      questions: [
        Q_ELIGIBILITY_CHECK,
        Q_CREATE_ACCOUNT,
        Q_CREATE_PASSWORD,
        Q_EXISTING_PASSWORD,
      ],
    },
    {
      description: "Health and Demographics",
      percentage: 50,
      questions: [
        Q_HEALTH_GOAL,
        Q_PROGRAM_OVERVIEW,
        Q_EATING_DISORDER,
        Q_HEIGHT_WEIGHT,
        Q_ETHNICITY,
        Q_OTHER_ETHNICITY,
        Q_SEX,
        Q_GENDER,
        Q_PREGNANT,
        Q_NURSING,
        Q_GESTATIONAL_DIABETES,
        Q_BASELINE_HEALTH,
        Q_HEALTH_CONDITIONS,
        Q_MEDICATIONS,
        Q_GLP1_MEDICATION,
        Q_HAD_A1C_TESTED,
        Q_A1C,
        Q_MSK_PAIN,
        Q_MSK_PAIN_LEVEL,
        Q_PHQ_1,
        Q_PHQ_2,
        Q_PHQ_3,
        Q_PHQ_4,
        Q_REASON_FOR_LOSING_WEIGHT,
        Q_FAMILY_HISTORY_WEIGHT,
        Q_LOCATION_STATE,
        Q_NOOM_MED_OVERVIEW,
        Q_NOOM_MED_MORE_OPTIONS,
        Q_ENROLLED_IN_MEDICARE,
        Q_MEDICARE_CONSENT,
        Q_CONTINUE_PROGRAM,
        Q_PREDIABETES_HISTORY,
        Q_HYPERTENSION,
        Q_FAMILY_HISTORY,
        Q_PHYSICAL_ACTIVITY,
        Q_DIFFICULTIES,
        Q_EDUCATION,
        Q_MOTIVATION,
        Q_HEALTH_CARE_PROFESSIONAL,
        Q_SCREENED_OUT,
      ],
    },
    {
      description: "Profile Creation",
      percentage: 25,
      questions: [
        Q_PERSONALIZING_PROGRAM,
        Q_PROGRAM_SET,
        Q_SCALE_OPT_IN,
        Q_COMPLETE_PROFILE,
      ],
    },
  ],
  RECURRING_OUTCOMES: [
    {
      description: "Progress Check-in",
      percentage: 100,
      questions: [
        Q_OUTCOMES_WEIGHT,
        Q_OUTCOMES_HEALTH_CONDITIONS,
        Q_OUTCOMES_MEDICATIONS,
        Q_OUTCOMES_GLP1_MEDICATION,
        Q_OUTCOMES_HAD_A1C_TESTED,
        Q_OUTCOMES_A1C,
        Q_OUTCOMES_MSK_PAIN,
        Q_OUTCOMES_MSK_PAIN_LEVEL,
        Q_PHQ_1,
        Q_PHQ_2,
        Q_PHQ_3,
        Q_PHQ_4,
        Q_EXERCISE,
        Q_REACHING_HEALTH_GOALS,
        Q_OUTCOMES_SURVEY_COMPLETE,
      ],
    },
  ],
  RETRIAGE_SURVEY: [
    {
      description: "Health Risk Assessment",
      percentage: 100,
      questions: [
        Q_RETRIAGE_ELIGIBILITY_CHECK,
        Q_RETRIAGE_HEIGHT_WEIGHT,
        Q_RETRIAGE_EATING_DISORDER,
        Q_RETRIAGE_ETHNICITY,
        Q_OTHER_ETHNICITY,
        Q_RETRIAGE_SEX,
        Q_RETRIAGE_GENDER,
        Q_RETRIAGE_PREGNANT,
        Q_RETRIAGE_GESTATIONAL_DIABETES,
        Q_RETRIAGE_HEALTH_CONDITIONS,
        Q_RETRIAGE_PREDIABETES_HISTORY,
        Q_RETRIAGE_A1C,
        Q_RETRIAGE_HYPERTENSION,
        Q_RETRIAGE_FAMILY_HISTORY,
        Q_RETRIAGE_PHYSICAL_ACTIVITY,
        Q_RETRIAGE_DIFFICULTIES,
        Q_RETRIAGE_EDUCATION,
        Q_RETRIAGE_MOTIVATION,
        Q_RETRIAGE_HEALTH_CARE_PROFESSIONAL,
        Q_RETRIAGE_PERSONALIZING_PROGRAM,
        Q_RETRIAGE_RESULT,
        Q_DPP_INFORMATION,
        Q_CONFIRM_CHANGE_PROGRAM,
        Q_RETRIAGE_TRANSITION,
        Q_RETRIAGE_KEEP_PLAN,
        Q_RETRIAGE_EXCLUSION,
      ],
    },
  ],
  TRANSITION_SURVEY: [
    {
      description: "Transition",
      percentage: 100,
      questions: [Q_ACTIVE_SUBSCRIPTION, Q_TRANSITION_EXPLAINER],
    },
  ],
  WIP: [
    {
      description: "",
      percentage: 0,
      questions: [Q_OUTCOMES_SURVEY_COMPLETE],
    },
  ],
};

export default QuestionSets;
