import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  CompassColor,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import {
  NoomMedMoreOptionsSelection,
  NoomMedOverviewSelection,
} from "@/constants";
import { useAppContext } from "@/contexts";
import { partnerOffersPrograms } from "@/utils/programs";
import { qualifiesForNoomMed } from "@/utils/qualification";

import { BaseQuestion } from "../core";

const QUESTION_ID = "noomMedMoreOptions";

declare module "@/contexts" {
  interface SurveyAnswers {
    noomMedMoreOptions?: string;
  }
}

type CardProps = {
  isSelected?: boolean;
  onClick?: () => void;
};

const Card: React.FC<CardProps> = ({ children, isSelected, onClick }) => (
  <Box
    backgroundColor={isSelected ? CompassColor.sky : CompassColor.offWhite}
    border={
      isSelected
        ? `1px solid ${CompassColor.lagoon}`
        : `1px solid ${CompassColor.sand01}`
    }
    borderRadius="8px"
    cursor="pointer"
    onClick={onClick}
    _hover={{ border: `1px solid ${CompassColor.lagoon}` }}
  >
    {children}
  </Box>
);

const NoomMedMoreOptionsQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
}) => {
  const {
    enrollmentInfo,
    partnerInfo,
    setSelectedProgram,
    setSurveyAnswers,
    surveyAnswers,
  } = useAppContext();
  const [selectedCard, setSelectedCard] =
    useState<NoomMedMoreOptionsSelection>();

  useEffect(() => {
    // if the user back navigates through this question we should delete the answer
    // as its used to determine med qualification and in question visibility
    const newSurveyAnswers = { ...surveyAnswers };
    delete newSurveyAnswers[QUESTION_ID];
    setSurveyAnswers(newSurveyAnswers);
  }, []);

  const partnerOffersDpp = partnerOffersPrograms(
    ["DPP", "WEIGHT"],
    enrollmentInfo,
    partnerInfo,
  );
  const otherOptionsTitle = partnerOffersDpp
    ? "Noom's Behavior Change Programs"
    : "Noom Weight";
  const otherOptionsDescription = partnerOffersDpp
    ? "Proven psychology-based programs that achieve long-term weight loss results. These programs do not include physician support."
    : "Proven psychology-based program that achieves long-term weight loss results. This program does not include physician support.";

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionId={QUESTION_ID}
      questionText="Select the path you'd prefer"
    >
      <Stack spacing={Spacing[4]}>
        <Card
          isSelected={selectedCard === NoomMedMoreOptionsSelection.MED}
          onClick={() => setSelectedCard(NoomMedMoreOptionsSelection.MED)}
        >
          <Stack padding="var(--spacing-m)" spacing={Spacing[4]}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
            >
              <Text
                fontFamily="Brown LL"
                fontSize="18px"
                fontWeight={500}
                lineHeight="21px"
                letterSpacing="0.03em"
                textTransform="uppercase"
              >
                Noom Med*
              </Text>
              <Box
                backgroundColor={CompassColor.lagoon}
                borderRadius="20px"
                padding="var(--spacing-xs) var(--spacing-m)"
              >
                <Text color={CompassColor.white} fontWeight={500}>
                  Recommended
                </Text>
              </Box>
            </Box>
            <Box display="flex">
              <Text fontSize="14px" lineHeight="20px">
                Based on your health profile, we think you'll have the best
                success with clinician support. In Noom Med, you get everything
                in our behavior change programs plus:
              </Text>
            </Box>
            <Box alignItems="flex-start" display="flex" gap="var(--spacing-m)">
              <Image alt="Group Feed" src="/assets/img/icon-group-feed.svg" />
              <Text fontSize="14px" lineHeight="20px">
                Video chats with clinicians trained in obesity management
              </Text>
            </Box>
            <Box alignItems="flex-start" display="flex" gap="var(--spacing-m)">
              <Image alt="Meds" src="/assets/img/icon-meds.svg" />
              <Text fontSize="14px" lineHeight="20px">
                Testing for health conditions that impact weight, with access to
                medication if suitable and desired
              </Text>
            </Box>
          </Stack>
        </Card>
        <Card
          isSelected={selectedCard === NoomMedMoreOptionsSelection.OTHER}
          onClick={() => setSelectedCard(NoomMedMoreOptionsSelection.OTHER)}
        >
          <Stack padding="var(--spacing-m)" spacing={Spacing[4]}>
            <Text
              fontFamily="Brown LL"
              fontSize="18px"
              fontWeight={500}
              lineHeight="21px"
              letterSpacing="0.03em"
              textTransform="uppercase"
            >
              {otherOptionsTitle}
            </Text>
            <Box alignItems="flex-start" display="flex" gap="var(--spacing-m)">
              <Image alt="Scale" src="/assets/img/icon-scale.svg" />
              <Text fontSize="14px" lineHeight="20px">
                {otherOptionsDescription}
              </Text>
            </Box>
          </Stack>
        </Card>
      </Stack>
      <Button
        colorScheme="primary"
        isDisabled={!selectedCard}
        onClick={() => {
          if (!selectedCard) {
            return;
          }

          const updatedProgram =
            selectedCard === NoomMedMoreOptionsSelection.MED ? "MED" : null;

          setSelectedProgram(updatedProgram);
          onClickNext(selectedCard, { updatedProgram });
        }}
        size="xl"
        type="submit"
      >
        Continue
      </Button>
      <Text color={CompassColor.grey3} fontSize="14px" lineHeight="20px">
        *There are no copays for doctor visits. Your insurance covers
        anti-obesity medications, but there may be a medication copay depending
        on your plan.
      </Text>
    </BaseQuestion>
  );
};

export const Q_NOOM_MED_MORE_OPTIONS: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    partnerInfo,
    selectedProgram,
    surveyAnswers,
    telehealthEligibleStates,
  }) =>
    selectedProgram !== "MOOD" &&
    qualifiesForNoomMed(surveyAnswers, partnerInfo, telehealthEligibleStates) &&
    surveyAnswers.noomMedOverview === NoomMedOverviewSelection.SEE_MORE_OPTIONS,
  component: NoomMedMoreOptionsQuestion,
};
