/**
 * Endpoint for validating the upid
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import axios from "axios";

import { Api } from "@noom/noomscape";

export type ResponseData = {
  activationCode: string;
  available: boolean;
  requestError?:
    | "ERROR_ACTIVATION_CODE_CLAIMED"
    | "ERROR_ACTIVATION_CODE_DOESNT_EXIST"
    | "ERROR_BATCH_FULL"
    | "ERROR_PAST_CONTRACT_END_DATE";
};

Api.register("account.validateUpid", async ({ upid }: { upid: string }) => {
  const response = await axios({
    url: `/account/upid/${upid}/validate`,
    urlWithPathParams: "/account/upid/{upid}/validate",
    baseURL: window.ENV.PRODUCT_API_URL,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data as ResponseData;
});
