import axios, { AxiosResponse } from "axios";

import {
  GetOutcomesUserProfileRequestProto,
  GetOutcomesUserProfileResponseProto,
} from "@noom/noom-contracts/noom_contracts/backend/outcomes/service/outcomes_service";
import { Api } from "@noom/noomscape";

Api.register(
  "outcomes.getUserProfile",
  async ({
    accessCode,
    accessToken,
  }: GetOutcomesUserProfileRequestProto & { accessToken: string }) => {
    const response: AxiosResponse<GetOutcomesUserProfileResponseProto> =
      await axios({
        baseURL: window.ENV.SERVER_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        url: `/outcomes/v1/userprofiles/${accessCode}`,
        urlWithPathParams: "/outcomes/v1/userprofiles/{accessCode}",
      });

    return response.data;
  },
);
