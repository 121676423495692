import { isPlainObject } from "lodash";

import { DynamicSurveyCompletedProto_SurveyCompleted_SurveyAnswer } from "@noom/noom-contracts/noom_contracts/events/app/dynamic_survey/dynamic_survey_completed";

import { ProgramOffering } from "@/constants";
import { B2C_PARTNERS } from "@/constants/Partners";
import { SurveyAnswers } from "@/contexts";
import type { Partner, UserProfile } from "@/models";

import { convertFtToCm, convertLbToKg } from "../UnitUtils";

import { calculatePhqScore } from "./phq";

export const OUTCOMES_QUESTION_ID_TO_UUID_MAP: Record<string, string> = {
  a1c: "23bc4a0b-9238-447d-ae66-5097432d87b9",
  exercise: "9da70ec9-b269-4367-8027-caf358a55541",
  glp1Medication: "d18dbd57-8172-4a38-876f-c08988266787",
  hadA1cTested: "61d55259-a724-4b06-9244-bc00f9f40919",
  healthConditions: "0c4d1ad1-4515-4e37-a1b5-6b091e6ab8f0",
  medications: "47ad5ef2-4600-4d94-a815-141554ff91cf",
  mskPain: "a4c7a4bf-ffd7-4248-bdd1-c96af906f713",
  mskPainLevel: "4014ef5d-9266-47ff-a695-2cb8e29c2aa0",
  phq1: "b957ba0c-5625-4f88-b2a1-6786f3bdac36",
  phq2: "29bd3e1d-25a2-42b1-a480-8bbd28d2fb54",
  phq3: "fe6f21eb-7ae3-40c7-b3df-1b1e148c6ca0",
  phq4: "780539bf-e97c-488a-8b47-5a0f6e0508dd",
  reachingHealthGoals: "0ae3cb75-dd2d-4c0e-a2e1-fb6eebfe67c9",
};

export const BASELINE_OUTCOMES_SURVEY_EXPERIENCE_SLUG = "b2b_baseline_outcomes";
export const RECURRING_OUTCOMES_SURVEY_EXPERIENCE_SLUG =
  "b2b_recurring_outcomes";
export const BASELINE_OUTCOMES_SURVEY_UUID =
  "d396e01d-d6cf-4437-bf1f-bfe1fac932e9";
export const RECURRING_OUTCOMES_SURVEY_UUID =
  "c74ee122-712c-478d-b32b-33e86687ee30";

export const PHQ_SCORE_UUID = "dc583140-a421-402c-a57e-eed41f621835";
export const HEIGHT_CM_UUID = "0e4486fe-6109-446b-ae1b-52320fcefc26";
export const WEIGHT_KG_UUID = "16d6e698-acd7-4101-a055-e17e20cadb53";

export const extractOutcomesSurveyAnswers = ({
  program,
  surveyAnswers,
  userProfile,
}: {
  program: ProgramOffering | null;
  surveyAnswers: SurveyAnswers;
  userProfile?: UserProfile | null;
}): DynamicSurveyCompletedProto_SurveyCompleted_SurveyAnswer[] => {
  const outcomesSurveyAnswers: DynamicSurveyCompletedProto_SurveyCompleted_SurveyAnswer[] =
    Object.entries(OUTCOMES_QUESTION_ID_TO_UUID_MAP)
      .filter(([questionId]) => {
        const surveyAnswer = surveyAnswers[questionId];

        return surveyAnswer !== undefined && !isPlainObject(surveyAnswer);
      })
      .map(([questionId, uuid]) => {
        const surveyAnswer = surveyAnswers[questionId];

        return {
          questionId: uuid,
          answerSlugs: Array.isArray(surveyAnswer)
            ? surveyAnswer
            : [String(surveyAnswer)],
        };
      });

  // store additional answer for PHQ score
  outcomesSurveyAnswers.push({
    questionId: PHQ_SCORE_UUID,
    answerSlugs: [String(calculatePhqScore(surveyAnswers))],
  });

  // Flatten and store height and weight for metabolic programs
  // For recurring outcomes, we only ask weight.
  if (program !== "MOOD" && userProfile?.latestWeightKgs === undefined) {
    const weight = surveyAnswers.heightWeight?.weight || surveyAnswers.weight;
    const { heightFeetPortion, heightInchesPortion } =
      surveyAnswers.heightWeight || {};

    if (
      !weight ||
      (surveyAnswers.heightWeight &&
        (!heightFeetPortion || heightInchesPortion === undefined))
    ) {
      throw Error(
        `Expected weight${surveyAnswers.heightWeight ? " and height" : ""}`,
      );
    }

    const weightInKg = convertLbToKg({
      mainUnitValue: weight,
    }).mainUnitValue;

    outcomesSurveyAnswers.push({
      questionId: WEIGHT_KG_UUID,
      answerSlugs: [String(weightInKg)],
    });

    if (heightFeetPortion) {
      const heightInCm = convertFtToCm({
        mainUnitValue: heightFeetPortion,
        secondaryUnitValue: heightInchesPortion,
      }).mainUnitValue;

      outcomesSurveyAnswers.push({
        questionId: HEIGHT_CM_UUID,
        answerSlugs: [String(heightInCm)],
      });
    }
  }
  return outcomesSurveyAnswers;
};

/**
 * Determines if the baseline outcomes question should be asked.
 *
 * @param partnerInfo used to determine if the partner is a B2C partner
 * @returns
 */
export const shouldAskBaselineOutcomes = (partnerInfo: Partner | null) => {
  if (partnerInfo?.id === undefined) {
    return true;
  }

  return !B2C_PARTNERS.includes(partnerInfo.id);
};

/**
 * Determines if the user's number of days in the program is within the first
 * two weeks of the month since the user started the program.
 *
 * This only applies to Mood and not cardiometabolic programs, because surveys
 * for cardiometabolic programs are sent every 30 days so monthly questions
 * should always be asked for cardiometabolic programs.
 * @param userProfile Profile data containing the days in program for the user
 * @returns
 */
export const shouldAskMonthlyQuestion = (userProfile: UserProfile | null) => {
  if (!userProfile) {
    return false;
  }

  const days = userProfile.daysInProgram % 30;

  return days >= 0 && days < 15;
};

/**
 * Determines if the user's number of days in the program is within the first
 * month of the quarter since the user started the program.
 * @param userProfile Profile data containing the days in program for the user
 * @returns
 */
export const shouldAskQuarterlyQuestion = (userProfile: UserProfile | null) => {
  if (!userProfile) {
    return false;
  }

  const questionCadence = userProfile.currentProgram === "MOOD" ? 15 : 30;

  const days = userProfile.daysInProgram % 90;

  return days >= 0 && days < questionCadence;
};
