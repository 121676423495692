export const ASSET_URL = `${window.ENV.CDN_URL}/assets`;

export const buildDeeplink = (email?: string) => {
  const queryParams = new URLSearchParams();
  // must pass at least one param in querystring or the link doesn't behavior properly
  // so set email to empty string if no value supplied
  queryParams.append("email", email || "");

  /**
   * 1) If the user does not have the app, this will open the app store
   * 2) If the user is not logged in, this will load the login screen
   * 3) If the user is logged in, this will load the home page
   */
  queryParams.append("open", "HOME");

  const baseUrl =
    window.ENV.NOOM_ENVIRONMENT === "production"
      ? "https://noomcoach.app.link"
      : "https://noomcoach.test-app.link";

  return `${baseUrl}/?${queryParams.toString()}`;
};

export enum ErrorState {
  DEFAULT = "Generic error",
  ACCOUNT_EXISTS = "Account exists",
  SSO_ACCOUNT_EXISTS = "SSO_ACCOUNT_EXISTS",
  BATCH_FULL = "Batch full",
  EXISTING_VIRGIN_PULSE_USER = "Already linked to Virgin Pulse",
  CODE_CLAIMED = "UPID claimed",
  ELIGIBILITY_CHECK_EXISTING_ACCOUNT = "Account with eligibility information already exists",
  FAILED_ELIGIBILITY_CHECK = "FAILED_ELIGIBILITY_CHECK",
  SSO_INELIGIBLE = "SSO_INELIGIBLE",
  SUPPORT_REQUEST_CREATED = "SUPPORT_REQUEST_CREATED",
  PENDING_DELETION_REQUEST = "PENDING_DELETION_REQUEST",
  BEFORE_GOLIVE_DATE = "Partner is not live yet",
  PAST_CONTRACT_END_DATE = "PAST_CONTRACT_END_DATE",
  PRIOR_TO_EFFECTIVE_DATE = "PRIOR_TO_EFFECTIVE_DATE",
}

export enum ErrorCode {
  MED_ENROLLMENT_FAILED = "MED_ENROLLMENT_FAILED",
}

export type ProgramOffering = "DPP" | "MED" | "MOOD" | "WEIGHT";

export enum ScreenedOutReason {
  BMI_TOO_LOW = "bmi_too_low",
  EATING_DISORDER = "eating_disorder",
  HEALTH_CONDITION = "health_condition",
  PREGNANT = "pregnant",
}

/**
 * List of states with consumer health laws.
 */
export const CONSUMER_HEALTH_LAW_STATES = ["CT", "NV", "WA"];

export const DOB_FORMAT = "MM/dd/yyyy";

/**
 * Allowlist of questions where the answers do not contain PII. This is used
 * so that we can log user answers to these questions.
 */
export const NON_PII_QUESTIONS = [
  "healthGoal",
  "programOverview",
  "programSelection",
  "scaleOptIn",
  "noomMedOverview",
  "noomMedMoreOptions",
  "healthConditions",
  "medications",
  "glp1Medication",
  "hadA1cTested",
  "a1c",
  "mskPain",
  "mskPainLevel",
  "phq1",
  "phq2",
  "phq3",
  "phq4",
  "enrolledInMedicare",
  "medicareConsent",
];

export const NOOM_SUPPORTED_LANGUAGES = [
  "en",
  "en-AU",
  "en-CA",
  "en-GB",
  "en-US",
  "es",
  "de",
  "ko",
];

export const NOOM_SUPPORTED_ENGLISH_LOCALES = NOOM_SUPPORTED_LANGUAGES.filter(
  (language) => language.slice(0, 2) === "en",
);

/**
 * List of states with opt out laws.
 */
export const OPT_OUT_STATES = ["CA", "CO", "CT", "NV", "OR", "TX", "UT", "VA", "WA"];
export const OPT_OUT_MHMDA_STATES = ["CT", "NV", "WA"];

export * from "./Curriculum";
export * from "./enrollment";
export * from "./success";
export * from "./survey-answers";
