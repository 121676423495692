import React from "react";

import { PregnantSelection, ScreenedOutReason } from "@/constants";
import { SurveyAnswer } from "@/contexts";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "pregnant";

declare module "@/contexts" {
  interface SurveyAnswers {
    pregnant?: PregnantSelection;
  }
}

const options: QuestionOption[] = [
  { id: PregnantSelection.YES, text: "Yes" },
  // In the future, we may want to include this option for weight loss pacing calculation
  // Currently, this question appears after pacing so it isn't useful.
  {
    id: PregnantSelection.NO_BUT_NURSING,
    text: "No, but I am currently nursing",
  },
  {
    id: PregnantSelection.NO_BUT_PLANNING,
    text: "No, but I'm planning to be within the next 6 months",
  },
  { id: PregnantSelection.NO, text: "No" },
];

const PregnantQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const screenOutOrProceed = async (answer: SurveyAnswer) => {
    if (answer === PregnantSelection.YES) {
      onClickNext(answer, {}, ScreenedOutReason.PREGNANT);
      return;
    }

    onClickNext(answer);
  };

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={screenOutOrProceed}
      options={options}
      questionId={QUESTION_ID}
      questionText="Are you currently pregnant?"
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_PREGNANT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ selectedProgram, surveyAnswers: { sex } }) =>
    selectedProgram !== "MOOD" &&
    (sex === "female" || sex === "intersex" || sex === "preferNotToSay"),
  component: PregnantQuestion,
};

export const Q_RETRIAGE_PREGNANT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, triageCurriculums = [] } = {},
    surveyAnswers: { sex },
  }) =>
    currentProgram === "WEIGHT" &&
    triageCurriculums.length > 1 &&
    (sex === "female" || sex === "intersex" || sex === "preferNotToSay"),
  component: PregnantQuestion,
};
