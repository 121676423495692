import styled from "@emotion/styled";
import React from "react";

import {
  Button,
  ButtonProps,
  CompassColor,
  Spacing,
  Stack,
} from "@noom/wax-component-library";

import { QuestionOption } from "@/components/survey/Question";

type MultipleChoiceOptionsProps = {
  onClick: (optionId: string) => void;
  options: QuestionOption[];
  selectedItem?: string;
};

type SurveyButtonProps = ButtonProps & {
  selected: boolean;
};

const SurveyButton = styled(Button)<SurveyButtonProps>`
  background-color: ${(props) =>
    props.selected ? CompassColor.lagoon : CompassColor.offWhite};
  border: 2px solid
    ${(props) => (props.selected ? CompassColor.lagoon : CompassColor.offWhite)};
  border-radius: 8px;
  color: ${(props) =>
    props.selected ? CompassColor.white : CompassColor.black};
  font-weight: normal;
  height: 60px;
  white-space: normal;
  width: 100%;
  word-break: break-word;

  &:hover {
    background-color: ${(props) =>
      props.selected ? CompassColor.lagoon : CompassColor.sand02};
    border: 2px solid
      ${(props) => (props.selected ? CompassColor.lagoon : CompassColor.sand02)};
  }
`;

export const MultipleChoiceOptions: React.FC<MultipleChoiceOptionsProps> = ({
  onClick,
  options,
  selectedItem,
}) => (
  <Stack spacing={Spacing[4]}>
    {options.map((option) => {
      const selected = option.id === selectedItem;

      return (
        <SurveyButton
          key={option.id}
          selected={selected}
          size="xl"
          onClick={() => onClick(option.id)}
        >
          {option.text}
        </SurveyButton>
      );
    })}
  </Stack>
);
