import React from "react";

import { qualifiesForNoomMed } from "@/utils/qualification";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "familyHistoryWeight";

declare module "@/contexts" {
  interface SurveyAnswers {
    familyHistoryWeight?: string;
  }
}

const options: QuestionOption[] = [
  { id: "yes", text: "Yes" },
  { id: "no", text: "No" },
];

const FamilyHistoryWeightQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => (
  <MultipleChoiceQuestion
    onClickBack={onClickBack}
    onClickNext={onClickNext}
    options={options}
    questionId={QUESTION_ID}
    questionText="Have any of your immediate family members struggled with their weight?"
    selectedItem={surveyAnswers[QUESTION_ID] || ""}
  />
);

export const Q_FAMILY_HISTORY_WEIGHT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    partnerInfo,
    selectedProgram,
    surveyAnswers,
    telehealthEligibleStates,
  }) =>
    selectedProgram !== "MOOD" &&
    qualifiesForNoomMed(surveyAnswers, partnerInfo, telehealthEligibleStates),
  component: FamilyHistoryWeightQuestion,
};
