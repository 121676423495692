import React from "react";

import { qualifiesForNoomMed } from "@/utils/qualification";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "mainReason";

declare module "@/contexts" {
  interface SurveyAnswers {
    mainReason?: string;
  }
}

const ReasonForLosingWeightQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const options: QuestionOption[] = [
    { id: "physicalAppearance", text: "Improve physical appearance" },
    { id: "engageFamily", text: "Engage more with family and friends" },
    { id: "healthier", text: "Become healthier" },
    { id: "feelBetter", text: "Feel better day-to-day" },
    { id: "other", text: "Other" },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText="What is your main reason for wanting to lose weight? (Please choose what is most important)"
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_REASON_FOR_LOSING_WEIGHT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    partnerInfo,
    selectedProgram,
    surveyAnswers,
    telehealthEligibleStates,
  }) =>
    selectedProgram !== "MOOD" &&
    qualifiesForNoomMed(surveyAnswers, partnerInfo, telehealthEligibleStates),
  component: ReasonForLosingWeightQuestion,
};
