import styled from "@emotion/styled";
import React, { InputHTMLAttributes } from "react";

import { CompassColor } from "@noom/wax-component-library";

import { Checkbox } from "./Checkbox";

const Label = styled.label<{ checked?: boolean }>`
  align-items: center;
  background-color: ${(props) =>
    props.checked ? CompassColor.lagoon : CompassColor.offWhite};
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  color: ${(props) =>
    props.checked ? CompassColor.white : CompassColor.black};
  display: flex;
  justify-content: flex-start;
  min-height: 60px;
  padding: 0 var(--spacing-m);
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) =>
      props.checked ? CompassColor.lagoon : CompassColor.sand02};
  }
`;

const CheckboxInput = styled.input`
  appearance: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

type CheckboxButtonProps = InputHTMLAttributes<HTMLInputElement>;

export const CheckboxButton: React.FC<CheckboxButtonProps> = ({
  checked,
  children,
  ...rest
}) => (
  <Label checked={checked}>
    <CheckboxInput
      checked={checked}
      type="checkbox"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
    <Checkbox checked={checked} />
    {children}
  </Label>
);
