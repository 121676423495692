import React from "react";

import { ScreenedOutReason } from "@/constants";
import { SurveyAnswer } from "@/contexts";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "eatingDisorder";

declare module "@/contexts" {
  interface SurveyAnswers {
    eatingDisorder?: string;
  }
}

const options: QuestionOption[] = [
  { id: "yes", text: "Yes" },
  { id: "no", text: "No" },
];

const EatingDisorderQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const screenOutOrProceed = async (answer: SurveyAnswer) => {
    if (answer === "yes") {
      onClickNext(answer, {}, ScreenedOutReason.EATING_DISORDER);
      return;
    }

    onClickNext(answer);
  };

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={screenOutOrProceed}
      options={options}
      questionId={QUESTION_ID}
      questionText="Do you have an active diagnosis of an eating disorder (e.g. bulimia, anorexia, or similar diagnosis)?"
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_EATING_DISORDER: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ selectedProgram }) => selectedProgram !== "MOOD",
  component: EatingDisorderQuestion,
};

export const Q_RETRIAGE_EATING_DISORDER: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, triageCurriculums = [] } = {},
  }) => currentProgram === "WEIGHT" && triageCurriculums.length > 1,
  component: EatingDisorderQuestion,
};
