import React from "react";

import { HealthGoal } from "@/constants";
import { partnerOffersPrograms } from "@/utils/programs";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "healthGoal";

declare module "@/contexts" {
  interface SurveyAnswers {
    healthGoal?: HealthGoal;
  }
}

const options: QuestionOption[] = [
  {
    id: HealthGoal.REDUCING_STRESS_ANXIOUS_FEELINGS,
    text: "Reducing stress & anxious feelings",
  },
  { id: HealthGoal.LOSING_WEIGHT, text: "Losing weight" },
];

const HealthGoalQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => (
  <MultipleChoiceQuestion
    onClickBack={onClickBack}
    onClickNext={onClickNext}
    options={options}
    questionHelperText="If both, just pick the one you'd like to start with."
    questionId={QUESTION_ID}
    questionText="What health goal are you interested in working towards?"
    selectedItem={surveyAnswers[QUESTION_ID] || ""}
  />
);

export const Q_HEALTH_GOAL: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ enrollmentInfo, partnerInfo }) =>
    partnerOffersPrograms(["MOOD", "WEIGHT"], enrollmentInfo, partnerInfo),
  component: HealthGoalQuestion,
};
