import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  CompassColor,
  Heading,
  Image,
  Spacing,
  Stack,
  Text,
  useMediaQuery,
} from "@noom/wax-component-library";

import { NAMESPACES } from "@/app/i18n";
import { ASSET_URL } from "@/constants";
import { MAINTAIN_ORIGINAL_LOGO_LIST } from "@/constants/BatchWhitelist";

type ValueProp = {
  alt: string;
  caption: ReactElement;
  url: string;
};

const STATIC_CONTENT_IMAGES: ValueProp[] = [
  {
    alt: "Hike",
    caption: (
      <>
        <Text color={CompassColor.white} fontWeight={500}>
          Set goals
        </Text>
        <Text color={CompassColor.white}> to achieve real results.</Text>
      </>
    ),
    url: "/assets/img/hike.png",
  },
  {
    alt: "Swim",
    caption: (
      <>
        <Text color={CompassColor.white}>Learn how to </Text>
        <Text color={CompassColor.white} fontWeight={500}>
          break old habits.
        </Text>
      </>
    ),
    url: "/assets/img/swim.png",
  },
  {
    alt: "Stretch",
    caption: (
      <>
        <Text color={CompassColor.white} fontWeight={500}>
          Take back control
        </Text>
        <Text color={CompassColor.white}> of your health.</Text>
      </>
    ),
    url: "/assets/img/stretch.png",
  },
];

type EnrollmentStaticContentProps = {
  employerName?: string;
  employerSlug?: string;
  showPricePill: boolean;
};

export const EnrollmentStaticContent: React.FC<
  EnrollmentStaticContentProps
> = ({ employerName, employerSlug, showPricePill }) => {
  const [isBrowser] = useMediaQuery("(min-width: 1160px)");
  const [employerLogoError, setEmployerLogoError] = useState(false);

  const showWhiteLogo =
    employerSlug && !MAINTAIN_ORIGINAL_LOGO_LIST.includes(employerSlug);

  return (
    <Box color={CompassColor.white}>
      <Stack
        alignItems="center"
        height="36px"
        isInline
        justifyContent="space-between"
      >
        <Box display="flex" gap="var(--spacing-m)">
          <Image
            alt="Noom"
            height="24px"
            src={`/assets/img/noom-logo-white.svg`}
          />
          {employerSlug && !employerLogoError && (
            <>
              <Box
                backgroundColor={CompassColor.white}
                height="24px"
                width="2px"
              />
              <Image
                alt={employerName}
                filter={showWhiteLogo ? "brightness(0) invert(1)" : "none"}
                height="24px"
                onError={() => setEmployerLogoError(true)}
                src={`${ASSET_URL}/img/${employerSlug}/logo.png`}
              />
            </>
          )}
        </Box>
        {showPricePill && isBrowser && (
          <Box
            backgroundColor={CompassColor.black}
            borderRadius="18px"
            display="flex"
            fontFamily="Brown LL"
            fontSize="20px"
            gap="var(--spacing-s)"
            lineHeight="26px"
            padding="var(--spacing-xs) var(--spacing-s)"
            textTransform="uppercase"
          >
            <Text color={CompassColor.grey2} textDecoration="line-through">
              $199
            </Text>
            <Text color={CompassColor.white} fontWeight={500}>
              Free
            </Text>
          </Box>
        )}
      </Stack>
      <Text
        as="p"
        color={CompassColor.sand02}
        fontFamily="Brown LL"
        fontSize="18px"
        fontWeight={500}
        lineHeight="21px"
        marginTop={{ base: "var(--spacing-l)", lg: "var(--spacing-xxxh)" }}
        textTransform="uppercase"
      >
        The magic of Noom
      </Text>
      <Heading
        fontSize={{ base: "24px", lg: "40px" }}
        fontWeight={500}
        lineHeight={{ base: "32px", lg: "50px" }}
        marginBottom={{ base: "var(--spacing-xl)", lg: "var(--spacing-h)" }}
        marginTop={{ base: "var(--spacing-s)", lg: "var(--spacing-m)" }}
      >
        Noom uses cognitive behavioral therapy techniques to help you break old
        habits and improve your health.
      </Heading>

      {isBrowser && (
        <Stack isInline spacing={Spacing[8]}>
          {STATIC_CONTENT_IMAGES.map(({ alt, caption, url }) => (
            <Stack key={url} maxWidth="192px" spacing={Spacing[4]}>
              <Image alt={alt} src={url} width="96px" />
              <Box fontFamily="Untitled Sans" fontSize="20px" lineHeight="28px">
                {caption}
              </Box>
            </Stack>
          ))}
        </Stack>
      )}
    </Box>
  );
};
