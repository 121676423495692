import { registerHandler } from "../handler";

declare const window: Window & {
  nf0?: any;
};

let didInit = false;

function ensureInit() {
  if (didInit) {
    return;
  }
  if (window.nf0) {
    // For this pixel, tracking properties for a user is another event
    window.nf0(
      "trackStructEvent",
      "country_code",
      window.OneTrust?.getGeolocationData?.().country,
    );
    didInit = true;
  }
}

export default function registerHandlers() {
  registerHandler("purchase", () => {
    ensureInit();
    window.nf0?.(
      "trackStructEvent",
      "custom_conversion",
      "signup",
      window.OneTrust?.getGeolocationData?.().country,
    );
  });
}
