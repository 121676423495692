import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

import {
  Button,
  Heading,
  Image,
  StackItem,
  Text,
} from "@noom/wax-component-library";

import { NoomEmailAddressLink } from "@/components/noom-branding";
import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { useTrackEvent } from "@/hooks";

import { BaseInterstitial } from "../../core";

const QUESTION_ID = "transitionExplainer";

const BoldListItem = styled.li`
  font-weight: 500;
`;

const Bullet = styled.li`
  list-style-type: disc;
  margin-left: 20px;
`;

const TransitionExplainer: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { trackIdentifiedPageViewed } = useTrackEvent();

  useEffect(() => {
    trackIdentifiedPageViewed("transitionExplainer");
  }, []);

  return (
    <BaseInterstitial
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      questionId={QUESTION_ID}
      showBackButton
      width="600px"
    >
      <Image
        alt=""
        margin="auto"
        src="/assets/img/bell.svg"
        width="fit-content"
      />
      <Heading fontSize="24px" fontWeight={500} lineHeight="32px">
        What to know to transition to a sponsored membership
      </Heading>
      <ol style={{ marginLeft: "20px" }}>
        <BoldListItem>
          Your current subscription will be cancelled and refunded
        </BoldListItem>
        <ul>
          <Bullet>
            You will receive a prorated refund for the remainder of your billing
            cycle. This will take a few days to process.
          </Bullet>
          <Bullet>
            If you have any add-on subscriptions, these will also be cancelled
            and refunded. Please note certain add-ons may not be available in
            your new plan.
          </Bullet>
        </ul>
        <br />
        <BoldListItem>
          You will be enrolled into a program specific to your sponsor
        </BoldListItem>
        <ul>
          <Bullet>
            You will receive a new coach and your course progress will be reset.
          </Bullet>
        </ul>
      </ol>
      <Text>
        Clicking &quot;Transition membership&quot; below means you acknowledge
        the above conditions. If you have questions, please email{" "}
        {NoomEmailAddressLink}.
      </Text>
      <StackItem display="flex" justifyContent="center">
        <Button
          colorScheme="primary"
          isLoading={isSubmitting}
          onClick={() => {
            setIsSubmitting(true);
            onClickNext(null);
          }}
          size="xl"
          type="submit"
        >
          Transition membership
        </Button>
      </StackItem>
    </BaseInterstitial>
  );
};

export const Q_TRANSITION_EXPLAINER: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => true,
  component: TransitionExplainer,
};
