import React from "react";

import { Curriculum } from "@/constants";
import { partnerOffersPrograms } from "@/utils/programs";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "familyHistory";

declare module "@/contexts" {
  interface SurveyAnswers {
    familyHistory?: string;
  }
}

const options: QuestionOption[] = [
  { id: "yes", text: "Yes" },
  { id: "no", text: "No" },
];

const FamilyHistoryQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => (
  <MultipleChoiceQuestion
    onClickBack={onClickBack}
    onClickNext={onClickNext}
    options={options}
    questionId={QUESTION_ID}
    questionText="Do you have a mother, father, sister, or brother with diabetes?"
    selectedItem={surveyAnswers[QUESTION_ID] || ""}
  />
);

export const Q_FAMILY_HISTORY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    enrollmentInfo,
    partnerInfo,
    selectedProgram,
    surveyAnswers: { dppEligible },
  }) =>
    partnerOffersPrograms(["DPP"], enrollmentInfo, partnerInfo) &&
    selectedProgram !== "MED" &&
    selectedProgram !== "MOOD" &&
    dppEligible !== false,
  component: FamilyHistoryQuestion,
};

export const Q_RETRIAGE_FAMILY_HISTORY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, triageCurriculums = [] } = {},
    surveyAnswers: { dppEligible },
  }) =>
    currentProgram === "WEIGHT" &&
    triageCurriculums.includes(Curriculum.DPP) &&
    dppEligible !== false,
  component: FamilyHistoryQuestion,
};
