import { Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Box,
  Button,
  CompassColor,
  Heading,
  Input,
  Loader,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import { NoomEmailAddressLink, NoomHeader } from "@/components/noom-branding";
import { useGeolocation } from "@/hooks";
import { setOptOut } from "@/utils/consent/opt-out";

type FormValues = {
  email: string;
};

const initialValues: FormValues = {
  email: "",
};

const OptOutForm = () => {
  const { canOptOut, country, isLoading, state } = useGeolocation();
  const [isOptOutSuccessful, setIsOptOutSuccessful] = useState(false);
  const { t } = useTranslation();

  const MAX_LENGTH = 180;
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .max(
        MAX_LENGTH,
        t("form.errors.maxLength", {
          label: "Email address",
          maxLength: MAX_LENGTH,
        }),
      )
      .email(t("form.email.errors.invalid"))
      .required(t("form.email.errors.required")),
  });

  const onSubmit = async (
    { email }: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    try {
      await setOptOut(country, email, state);
      setIsOptOutSuccessful(true);
    } catch (e) {
      actions.setFieldError(
        "email",
        "We were unable to complete your request. Please try again.",
      );
    }
  };

  if (isLoading) {
    return <Loader size="xl" colorScheme="primary" />;
  }

  if (!canOptOut()) {
    return (
      <Text size="lg">
        It does not appear you are a resident of an applicable region. If you
        think this is an error, please email us at {NoomEmailAddressLink}.
      </Text>
    );
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          dirty,
          errors,
          getFieldProps,
          handleSubmit,
          isSubmitting,
          isValid,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={Spacing[8]}>
              <Input
                {...getFieldProps("email")}
                autoFocus
                error={touched.email && errors.email}
                label="Email address"
                placeholder="Enter your email"
                maxWidth="none"
                size="lg"
              />
              <Button
                colorScheme="primary"
                isDisabled={!(dirty && isValid)}
                isLoading={isSubmitting}
                marginBottom="var(--spacing-m)"
                maxWidth="none"
                size="xl"
                type="submit"
                whiteSpace="normal"
              >
                Limit Use of My Sensitive Personal Information and Opt Out of
                Sharing My Personal Information
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
      {isOptOutSuccessful && (
        <Text color={CompassColor.kale} size="lg">
          Your request has been submitted.
        </Text>
      )}
    </>
  );
};

export const DoNotShareView: React.FC = () => (
  <Box>
    <NoomHeader />
    <Box margin="auto" maxWidth="1280px" padding="var(--spacing-m)">
      <Stack marginBottom="var(--spacing-xxh)" spacing={Spacing[8]}>
        <Heading fontSize="28px" lineHeight="36px">
          Right to Opt-Out/Right to Limit Use and Disclosure of Sensitive
          Personal Information
        </Heading>
        <Text size="lg">
          You have a right to opt-out from the &quot;sale&quot; or
          &quot;sharing&quot; of your personal information. As part of the
          Employer and Partnership Program (B2B), Noom may share information
          relating to your use of Noom with your health plan or employer. We
          also use services that help deliver interest-based marketing and
          advertising to you outside of Noom.
        </Text>
        <Text size="lg">
          You also have a right to limit our use of sensitive personal
          information for purposes other than to provide Noom. As a wellness
          service, the information we collect often pertains to your health. If
          you do not wish for us or our partners to share information relating
          to your use of Noom with your health plan or employer, or for
          marketing and advertising purposes, please submit the form below.
        </Text>
        <Text size="lg">
          Please note: Some health plans or employers offer reward programs as
          part of the Employer and Partnership Program (B2B). Opting out may
          affect your ability to participate in these programs. If you have any
          questions about your eligibility, please contact your health plan or
          employer directly.
        </Text>
      </Stack>
      <Box
        border={`1px solid ${CompassColor.black}`}
        borderRadius="8px"
        margin="auto"
        maxWidth="800px"
        padding="var(--spacing-l)"
        textAlign="center"
      >
        <OptOutForm />
      </Box>
    </Box>
  </Box>
);
