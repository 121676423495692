import { parse } from "date-fns";

import type { EligibilityCheckFormValues } from "@/components/forms/EligibilityCheckForm";
import { RetriageEligibility } from "@/models";

export const getEligibilityCheckFormValues = (
  retriageEligibility: RetriageEligibility,
): EligibilityCheckFormValues | undefined => {
  if (!retriageEligibility.eligibilityProperties) {
    return undefined;
  }

  const { firstName, lastName, dateOfBirth, participantId } =
    retriageEligibility.eligibilityProperties;
  // month is [0-11]
  const { year, month, day } = dateOfBirth || {};
  return {
    dob: year && month && day ? new Date(year, month - 1, day) : null,
    employeeId: participantId,
    firstName: firstName || "",
    lastName: lastName || "",
    isMedEligible: retriageEligibility.medEligible,
  };
};

export const parseEligibilityFileDate = (date?: string): Date | undefined =>
  date ? parse(date, "yyyy-MM-dd", new Date()) : undefined;
