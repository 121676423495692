import { differenceInYears } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";

import { captureException } from "@/utils/sentry";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "enrolledInMedicare";

declare module "@/contexts" {
  interface SurveyAnswers {
    enrolledInMedicare?: string;
  }
}

const EnrolledInMedicareQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "yes", text: t("enrolledInMedicare.options.yes") },
    { id: "no", text: t("enrolledInMedicare.options.no") },
    { id: "notSure", text: t("enrolledInMedicare.options.notSure") },
  ];

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionId={QUESTION_ID}
      questionText={t("enrolledInMedicare.questionText")}
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_ENROLLED_IN_MEDICARE: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    selectedProgram,
    surveyAnswers: { eligibilityCheck },
  }) => {
    if (selectedProgram !== "MED") {
      return false;
    }

    const age = eligibilityCheck?.dob
      ? differenceInYears(new Date(), eligibilityCheck?.dob)
      : eligibilityCheck?.age;

    if (!age) {
      const message = `Age was not able to be calculated - DOB: ${eligibilityCheck?.dob}, age: ${eligibilityCheck?.age}`;
      captureException(message);
      return true;
    }

    return age > 64;
  },
  component: EnrolledInMedicareQuestion,
};
