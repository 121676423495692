import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Spacing, Stack } from "@noom/wax-component-library";

import {
  PasswordInput,
  PasswordInputValidationSchema,
} from "@/components/form";

import { QuestionComponentProps, QuestionDefinition } from "../../Question";
import { BaseQuestion } from "../core";

const QUESTION_ID = "createPassword";

declare module "@/contexts" {
  interface SurveyAnswers {
    createPassword?: string;
  }
}

type CreatePasswordFormValues = {
  password: string;
};

const CreatePasswordQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const initialValues: CreatePasswordFormValues = {
    password: surveyAnswers.createPassword || "",
  };

  const onSubmit = ({ password }: CreatePasswordFormValues) => {
    onClickNext(password);
  };

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionHelperText={t("createPassword.questionHelperText")}
      questionId={QUESTION_ID}
      questionText={t("createPassword.questionText")}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={PasswordInputValidationSchema}
      >
        {({ handleChange, handleSubmit, validateField }) => {
          const handleInputChange = async (
            e: React.ChangeEvent<HTMLInputElement>,
            fieldName: keyof CreatePasswordFormValues,
          ) => {
            // This is a hacky method to get each field to validate on change
            // individually.
            await handleChange(e);
            validateField(fieldName);
          };

          return (
            <Form onSubmit={handleSubmit}>
              <Stack spacing={Spacing[8]}>
                <PasswordInput
                  name="password"
                  onChange={(e) => handleInputChange(e, "password")}
                  userInputs={[]}
                />
                <Button colorScheme="primary" size="xl" type="submit">
                  Create account
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </BaseQuestion>
  );
};

export const Q_CREATE_PASSWORD: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ hasB2cAccount }) => !hasB2cAccount,
  component: CreatePasswordQuestion,
};
