import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import styled from "@emotion/styled";
import { useField, useFormikContext } from "formik";
import libphonenumber from "google-libphonenumber";
import i18next from "i18next";
import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import * as Yup from "yup";

import {
  Box,
  CompassColor,
  InputProps,
  Spacing,
} from "@noom/wax-component-library";

import { defaultFieldStyles } from "@/app/themes/reskin/defaultFieldStyles";

import "react-phone-input-2/lib/style.css";
import { valueToPercent } from "@mui/base";

type PhoneInputProps = InputProps & {
  focusPhoneNumber?: boolean;
  name: string;
};

export type PhoneNumber = {
  value: string;
  country: string;
  dialCode: string;
};

const StyledCountryInput = styled(ReactPhoneInput)(({ isInvalid }) => ({
  flex: 1,

  ".flag-dropdown": {
    backgroundColor: CompassColor.offWhite,
    border: "none",
    borderRadius: "8px 0 0 8px",
    fontFamily: "Untitled Sans",
    margin: "1px",

    "&.open": {
      borderRadius: "8px 0 0 8px",
      ".selected-flag": {
        borderRadius: "8px 0 0 8px",
      },
    },
  },
  ".selected-flag": {
    borderRadius: "8px 0 0 8px",

    "&:hover": {
      backgroundColor: CompassColor.sand01,
    },
  },
  "input.form-control": {
    ...defaultFieldStyles,
    borderColor: isInvalid ? CompassColor.cinnamon : CompassColor.grey2,
    boxShadow: isInvalid ? `0 0 0 1px ${CompassColor.cinnamon}` : "none",
    fontFamily: "Untitled Sans",
    fontSize: "18px",
    transitionDuration: "var(--chakra-transition-duration-normal)",

    ":hover": {
      borderColor: isInvalid ? CompassColor.cinnamon : CompassColor.lagoon,
    },

    ":focus": {
      borderColor: CompassColor.lagoon,
      boxShadow: "none",
    },
  },
}));

const StyledPhoneInput = styled(StyledCountryInput)({
  flex: 2,

  ".flag-dropdown": {
    display: "none",
  },
  "input.form-control": {
    paddingLeft: "var(--spacing-m)",
  },
});

export const isValidPhoneNumber = ({
  country,
  value,
  dialCode,
}: PhoneNumber) => {
  if (!value) {
    return false;
  }

  const phoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance();

  try {
    const phoneNumber = phoneNumberUtil.parse(dialCode.concat(value), country);

    if (!phoneNumber) {
      return false;
    }

    return phoneNumberUtil.isValidNumber(phoneNumber);
  } catch (e) {
    return false;
  }
};

export const PhoneInputSchema = Yup.mixed()
  .required(() => i18next.t("form.phoneNumber.errors.required"))
  .test({
    message: () => i18next.t("form.phoneNumber.errors.invalid"),
    name: "is-valid-phone-number",
    test: isValidPhoneNumber,
  });

export const PhoneInput: React.FC<PhoneInputProps> = ({
  focusPhoneNumber,
  helper,
  label,
  name,
  placeholder,
}) => {
  const [field, meta, helpers] = useField({ name });
  const { validateField } = useFormikContext();

  const error = meta.touched && meta.error;

  const handleInputChange = async (value: PhoneNumber) => {
    // This is a hacky method to get each field to validate on change
    // individually.
    await helpers.setValue(value);
    validateField(name);
  };

  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Box display="flex" gap="var(--spacing-m)">
        <StyledCountryInput
          {...field}
          autocompleteSearch
          country="us"
          countryCodeEditable={false}
          enableSearch
          inputProps={{
            // ideally this should be `autoComplete: "off"` but there are known issues with Chrome ignoring <autoComplete=off> attribute
            // workaround: use `autoComplete: <some_string>`
            // https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off?page=1&tab=scoredesc#tab-top
            autoComplete: "none",
            "aria-label": "country code",
            id: `${name}Country`,
            name,
            tabIndex: -1,
          }}
          isInvalid={!!error}
          onBlur={() => helpers.setTouched(true)}
          onChange={(dialCode: string, country: { countryCode: string }) => {
            handleInputChange({
              ...field.value,
              country: country.countryCode,
              dialCode,
            });
          }}
          placeholder="+1"
          value={field.value.dialCode}
        />
        <StyledPhoneInput
          {...field}
          disableCountryCode
          disableCountryGuess
          disableDropdown
          inputProps={{
            autoComplete: "tel-national",
            autoFocus: !!focusPhoneNumber,
            id: name,
            name,
          }}
          isInvalid={!!error}
          onBlur={() => helpers.setTouched(true)}
          onChange={(value: string) => {
            handleInputChange({ ...field.value, value });
          }}
          placeholder={placeholder}
          showDropdown={false}
          value={field.value.value}
        />
      </Box>
      {helper && !error && <FormHelperText>{helper}</FormHelperText>}
      {typeof error === "string" && (
        <FormErrorMessage padding={Spacing[1]} position="relative" top="-5px">
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
