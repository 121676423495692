// List of employers who have logos that look poor when converted to white.
// We will maintain the original logo when showing it on the passcode page.
export const MAINTAIN_ORIGINAL_LOGO_LIST: string[] = [
  "activelee",
  "acushnet",
  "acushnetspouses",
  "akrs",
  "ansell",
  "bestwestern",
  "carefirst",
  "cityofnorthport",
  "corpuschristi",
  "cort",
  "csdtrust",
  "danafarber",
  "daytonchildrens",
  "denso",
  "emotrans",
  "fambrands",
  "federalreservebankofatlanta",
  "ferrara",
  "G-III",
  "gwinnettcounty",
  "hawthornerp",
  "hilti",
  "ilwisewoman",
  "jacobsentertainment",
  "lmsv",
  "longbeachtransit",
  "lowensteinsandler",
  "magnit",
  "mandansd",
  "moderntrans",
  "monumenthealth",
  "navitus",
  "nboe",
  "octaxcol",
  "palomar",
  "phmg",
  "sdnb",
  "sdomc",
  "southeasterncontainer",
  "stanislaus",
  "stmartinparish",
  "tampageneral",
  "timken",
  "townofbabylon",
  "trinityra",
  "veritas",
  "virginiabankersassociation",
];
