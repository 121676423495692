import {
  allowCategories,
  consentFramework,
  LibCategories,
} from "@/utils/consent";
import { optOutAllowsCookies } from "@/utils/consent/opt-out";
import { fireFacebookCapi } from "@/utils/pixels/FacebookCapiApi";
import { registerHandler } from "@/utils/pixels/handler";
import { generateEventId } from "@/utils/pixels/utils";

import { shouldAdjustELTV, adjustELTV } from "../eltvAdjustment";

declare const fbq: any;

export interface FacebookPixelParameters {
  external_id?: string;
  value?: number;
  currency?: string;
  product?: string;
}

const FB_EREV9_PIXEL = "1204952429523059";
const FB_ELTV13_PIXEL = "1557275494440599";

/**
 * Workaround for being able to add information as advanced matching latter in the funnel (if the purchase is successful)
 * This would have not been possible otherwise as the advanced matching parameters can only be added at the first call of 'init'
 */
function reinitForMatchingParameters() {
  if (allowCategories([LibCategories.targetingCookies])) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://connect.facebook.net/signals/config/${FB_ELTV13_PIXEL}?v=2.9.48&r=stable`;
    script.id = "facebookEltv13PixelScript";

    const head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
  }
}

export function shouldApplyLDUFlag() {
  return consentFramework() === "optOut" && !optOutAllowsCookies();
}

export function applyLDUFlag() {
  fbq("dataProcessingOptions", ["LDU"], 1, 1000);
}

export default function registerHandlers() {
  registerHandler(
    "purchase",
    ({ emailHashes, erev_13_months, eltv_25_months, userId }) => {
      // Check to add LDU flag for CCPA opt out.
      if (shouldApplyLDUFlag()) {
        applyLDUFlag();
      }

      // refresh facebook script as early as possible
      reinitForMatchingParameters();

      const pixelProperties: FacebookPixelParameters = {
        value: erev_13_months,
        currency: "USD",
        external_id: userId,
      };

      const country = window.OneTrust?.getGeolocationData?.().country;
      const eltv13Value =
        shouldAdjustELTV() && country
          ? adjustELTV(eltv_25_months, country)
          : eltv_25_months;

      fbq("init", FB_EREV9_PIXEL, {
        em: emailHashes.sha256,
      });
      fbq("trackSingle", FB_EREV9_PIXEL, "Purchase", pixelProperties);

      const eventID = generateEventId(userId);
      const fbParamsELTV13: FacebookPixelParameters = {
        ...pixelProperties,
        value: eltv13Value,
      };

      fbq("init", FB_ELTV13_PIXEL, {
        em: emailHashes.sha256,
      });
      fbq("trackSingle", FB_ELTV13_PIXEL, "Purchase", fbParamsELTV13, {
        eventID,
      });
      fireFacebookCapi(FB_ELTV13_PIXEL, "Purchase", {
        ...fbParamsELTV13,
        eventID,
        email: emailHashes.sha256,
      });
    },
  );
}
