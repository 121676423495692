import React, { useEffect } from "react";
import { Trans } from "react-i18next";

import { Api } from "@noom/noomscape";
import {
  CompassColor,
  Image,
  useMediaQuery,
} from "@noom/wax-component-library";

import { QuestionDefinition } from "@/components/survey/Question";
import { BaseInterstitial } from "@/components/survey/questionTypes/core";
import { useAppContext } from "@/contexts";
import { useTrackEvent } from "@/hooks";

// This question is only reachable via direct navigation. Use caution when updating this ID.
const QUESTION_ID = "ineligible";

export const RetriageExclusion: React.FC = () => {
  const { retriageEligibility, screenedOutReason } = useAppContext();
  const [isBrowser] = useMediaQuery("(min-width: 1160px)");
  const { trackAnonEvent } = useTrackEvent();

  useEffect(() => {
    Api.call("dpp.alertUserCoach", Api.api.dpp.alertUserCoach, {
      accessCode: retriageEligibility?.accessCode,
    });
  }, []);

  useEffect(() => {
    if (!screenedOutReason) {
      return;
    }

    trackAnonEvent("b2bEnrollmentAnonUserExcluded", {
      exclusionReason: screenedOutReason,
    });
  }, [screenedOutReason]);

  return (
    <BaseInterstitial
      backgroundColor={CompassColor.sky}
      onClickBack={() => null}
      onClickNext={() => Promise.resolve()}
      questionId={QUESTION_ID}
      width={isBrowser ? "600px" : undefined}
    >
      <Image
        height={{ base: "48px", lg: "64px" }}
        margin="var(--spacing-l) 0px var(--spacing-s) 0px"
        src="/assets/img/diamond-tarocco.svg"
      />
      <Trans i18nKey="retriageExclusion.message">
        <strong>
          Thanks for letting us know. Based on your answer, we don&#39;t believe
          the Diabetes Prevention Program is the right program for you.
        </strong>
        <br />
        <br />
        We&#39;ve also notified your Noom guide/coach with this information, and
        they will be reaching out shortly.
      </Trans>
    </BaseInterstitial>
  );
};

export const Q_RETRIAGE_EXCLUSION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => false,
  component: RetriageExclusion,
};
