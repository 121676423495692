import styled from "@emotion/styled";
import React from "react";

import { CompassColor } from "@noom/wax-component-library";

type CheckboxProps = {
  checked?: boolean;
};

const CheckmarkSvg = styled.svg<CheckboxProps>`
  height: 70%;
  margin: 15%;
  transform: ${(props) => (props.checked ? "scale(1)" : "scale(0)")};
  transition: 166ms transform ease-in-out;
  width: 70%;
`;

const Container = styled.div<CheckboxProps>`
  background-color: ${CompassColor.white};
  border: 1px solid
    ${(props) => (props.checked ? CompassColor.lagoon : CompassColor.sand02)};
  border-radius: 4px;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 24px;
  margin-right: var(--spacing-m);
  width: 24px;
`;

export const Checkbox: React.FC<CheckboxProps> = ({ checked }) => (
  <Container checked={checked}>
    <CheckmarkSvg
      checked={checked}
      fill="none"
      height="100%"
      viewBox="0 0 10 8"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.526.253L3.28 5.506 1.473 3.703a.863.863 0 10-1.22 1.223l2.418 2.412a.863.863 0 001.22-.002l5.857-5.863A.863.863 0 008.526.253z"
        fill="#05727a"
      />
    </CheckmarkSvg>
  </Container>
);
