import { loadFacebookPixel } from "./facebook";
import { loadAdWords, loadGA } from "./google";
import { loadHorizon } from "./horizon";
import { loadVWO } from "./vwo";

export function initializeGlobalVendorScripts() {
  loadGA();
  loadVWO();
}

export function initializePixels() {
  loadFacebookPixel();
  loadAdWords();
  loadHorizon();
}
