import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { B2BEnrollmentEnumsProto_EntryPoint } from "@noom/noom-contracts/noom_contracts/events/web/b2b/shared";

import { useQueryParams, useTrackEvent } from "@/hooks";
import { EnrollmentInformation } from "@/models";

import { TriageLandingForm } from "./TriageLandingForm";

type EntryFormProps = {
  enrollmentInfo: EnrollmentInformation;
  upid: string;
};

export const EntryForm: React.FC<EntryFormProps> = ({
  enrollmentInfo,
  upid,
}) => {
  const { employerSlug } = useParams<AppParams>();
  const { eligibilityId, sharedEligibility } = useQueryParams();
  const { trackIdentifiedEvent } = useTrackEvent();

  useEffect(() => {
    let entryPoint: B2BEnrollmentEnumsProto_EntryPoint =
      "ENTRY_POINT_UNSPECIFIED";

    if (eligibilityId && sharedEligibility !== "true") {
      entryPoint = "SSO";
    } else if (employerSlug) {
      entryPoint = "PASSCODE";
    } else {
      entryPoint = "UPID";
    }

    trackIdentifiedEvent("b2bEnrollmentIdentifiedEnrollmentFlowStarted", {
      entryPoint,
    });
  }, [employerSlug, upid]);

  return <TriageLandingForm />;
};
