import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import {
  Box,
  Button,
  CompassColor,
  CompassLoader,
  Heading,
  Size,
  Stack,
} from "@noom/wax-component-library";

import { ConfettiBox } from "@/components/ConfettiBox";
import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { useAppContext } from "@/contexts";
import { useGoto, useMigrateToDpp } from "@/hooks";
import { sanitizeDppSurveyAnswers } from "@/utils/dpp";

import { BaseOutline } from "../core";

const QUESTION_ID = "retriageTransition";

const RetriageTransition: React.FC<QuestionComponentProps> = ({
  onClickNext,
  surveyAnswers,
}) => {
  const [hasMinTimeElapsed, setHasMinTimeElapsed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { retriageEligibility } = useAppContext();
  const { migrateToDpp, apiLoading, apiError } = useMigrateToDpp();
  const goto = useGoto();

  const { confirmChangeProgram } = surveyAnswers;

  useEffect(() => {
    if (!apiLoading && !apiError && hasMinTimeElapsed) {
      setIsSuccess(true);
    }
  }, [apiError, apiLoading, hasMinTimeElapsed]);

  useEffect(() => {
    if (confirmChangeProgram) {
      setTimeout(() => setHasMinTimeElapsed(true), 2500);
      migrateToDpp({
        accessCode: retriageEligibility?.accessCode || "",
        survey: sanitizeDppSurveyAnswers(surveyAnswers),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasMinTimeElapsed && apiError) {
      goto.error();
    }
  }, [hasMinTimeElapsed, apiError, goto]);

  return (
    <BaseOutline>
      <Stack alignItems="center" pt={{ base: "40px", md: "100px" }}>
        {isSuccess ? (
          <Stack alignItems="center">
            <ConfettiBox />
            <Heading textAlign="center" size={Size.lg} maxWidth="20em">
              You&rsquo;re in!
            </Heading>
            <Heading textAlign="center" size={Size.lg} maxWidth="20em">
              The next time you open the app, you&rsquo;ll see your new course.
            </Heading>
            {isMobile && (
              <Box pt="30px">
                <Button
                  colorScheme="primary"
                  onClick={() => onClickNext(null)}
                  size="md"
                  whiteSpace="unset"
                >
                  Return to App
                </Button>
              </Box>
            )}
          </Stack>
        ) : (
          <Stack alignItems="center">
            <CompassLoader color={CompassColor.black} size="lg" />
            <Heading mt={{ base: "30px", md: "50px" }}>
              Updating your program...
            </Heading>
          </Stack>
        )}
      </Stack>
    </BaseOutline>
  );
};

export const Q_RETRIAGE_TRANSITION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ surveyAnswers }) =>
    surveyAnswers.confirmChangeProgram === true,
  component: RetriageTransition,
};
