import axios from "axios";

import type {
  GetB2bEmailAvailabilityRequestProto,
  GetB2bEmailAvailabilityResponseProto,
} from "@noom/noom-contracts/noom_contracts/backend/enrollment/b2b/enrollment_information";
import { Api } from "@noom/noomscape";

Api.register(
  "account.getB2bEmailAvailabilityInformation",
  async (
    params: GetB2bEmailAvailabilityRequestProto,
  ): Promise<GetB2bEmailAvailabilityResponseProto> => {
    const response = await axios({
      baseURL: window.ENV.SERVER_URL,
      params,
      method: "GET",
      url: "/b2b-enrollment/v1/getB2bEmailAvailabilityInformation",
      urlWithPathParams:
        "/b2b-enrollment/v1/getB2bEmailAvailabilityInformation",
    });

    return response.data;
  },
);
