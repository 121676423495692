import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  Heading,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { useAppContext } from "@/contexts";
import { useStoreOutcomes } from "@/hooks";

import { BaseInterstitial } from "../core";

const QUESTION_ID = "outcomesSurveyComplete";

const OutcomesSurveyComplete: React.FC<QuestionComponentProps> = ({
  onClickNext,
}) => {
  const { userProfile } = useAppContext();
  const { storeRecurringOutcomesAnswers } = useStoreOutcomes();
  const { t } = useTranslation();

  const firstName = userProfile?.firstName || "Noomer";

  useEffect(() => {
    storeRecurringOutcomesAnswers();
  }, []);

  return (
    <BaseInterstitial
      backgroundColor={CompassColor.sky}
      onClickBack={() => null}
      onClickNext={() => Promise.resolve()}
      questionId={QUESTION_ID}
      width="550px"
    >
      <Stack spacing={Spacing[8]} alignItems="stretch">
        <Image
          alt="Checkmark with star"
          margin="auto"
          src="/assets/img/check-star.svg"
          mt="20px"
        />
        <Heading fontSize="24px" fontWeight={500} lineHeight="32px">
          {t("outcomesSurveyComplete.heading", { firstName })}
        </Heading>
        <Text fontSize="20px">
          <Trans i18nKey="outcomesSurveyComplete.body">
            Each check in is a great opportunity to reflect on your health and
            how it&apos;s changing as you make progress towards your goals.
            We&apos;re thrilled to be your partner in this.
            <br />
            <br />
            See you next month!
          </Trans>
        </Text>
      </Stack>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        marginTop="var(--spacing-xl)"
      >
        <Button
          colorScheme="primary"
          onClick={() => onClickNext(null)}
          padding="unset"
          size="xl"
          whiteSpace="unset"
        >
          <Trans i18nKey="outcomesSurveyComplete.cta">Finish</Trans>
        </Button>
      </Box>
    </BaseInterstitial>
  );
};

export const Q_OUTCOMES_SURVEY_COMPLETE: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => true,
  component: OutcomesSurveyComplete,
};
