import React, { useState } from "react";

import { Button } from "@noom/wax-component-library";

import { CheckboxOptions } from "@/components/survey/checkbox";

import { QuestionComponentProps, QuestionOption } from "../../Question";

import { BaseQuestion } from "./BaseQuestion";

type CheckboxQuestionComponentProps = Omit<
  QuestionComponentProps,
  "surveyAnswers"
> & {
  options: QuestionOption[];
  questionHelperText?: string;
  questionId: string;
  questionText: string;
  selectedItems: string[];
};

const isSpecialCheckbox = (optionId: string) =>
  optionId === "none" || optionId === "preferNotToSay";

export const CheckboxQuestion: React.FC<CheckboxQuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  options,
  questionHelperText,
  questionId,
  questionText,
  selectedItems,
}) => {
  const [checkedItems, setCheckedItems] = useState(selectedItems);

  const toggleCheckedState = (
    optionId: string,
    currentCheckedState: boolean,
  ) => {
    /**
     * If the item is currently checked: remove the item from the array.
     * If the item is currently not checked:
     * - if it is a special checkbox, make that the only checked item
     * - otherwise, remove existing special checkboxes and add the item to the
     *   array
     */
    const newCheckedItems = currentCheckedState
      ? checkedItems.filter((checkedItem) => checkedItem !== optionId)
      : isSpecialCheckbox(optionId)
        ? [optionId]
        : [
            ...checkedItems.filter(
              (checkedItem) => !isSpecialCheckbox(checkedItem),
            ),
            optionId,
          ];

    setCheckedItems(newCheckedItems);
  };

  const submitCheckboxQuestion = () => {
    if (checkedItems.length > 0) {
      onClickNext(checkedItems);
    }
  };

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionHelperText={questionHelperText}
      questionId={questionId}
      questionText={questionText}
    >
      <CheckboxOptions
        checkedItems={checkedItems}
        options={options}
        submitCheckboxQuestion={submitCheckboxQuestion}
        toggleCheckedState={toggleCheckedState}
      />
      <Button
        colorScheme="primary"
        disabled={checkedItems.length === 0}
        onClick={() => submitCheckboxQuestion()}
        size="xl"
      >
        Next
      </Button>
    </BaseQuestion>
  );
};
