import { DppTriageProto_CompletionStatus } from "@noom/noom-contracts/noom_contracts/healthcare/dpp_triage";
import { Api } from "@noom/noomscape";

import { SurveyAnswers, useAppContext } from "@/contexts";
import { sanitizeDppSurveyAnswers } from "@/utils/dpp";

export const useSaveTriageData = (partnerId: number | undefined) => {
  const { eligibilityInfo, ssTriageUuid, setSsTriageUuid } = useAppContext();

  const saveTriageData = async (
    surveyAnswers: SurveyAnswers,
    completionStatus: DppTriageProto_CompletionStatus,
  ) => {
    const participantId =
      surveyAnswers.eligibilityCheck?.employeeId || eligibilityInfo?.employeeId;

    const { key } = await Api.call(
      "dpp.saveTriageData",
      Api.api.dpp.saveTriageData,
      {
        completionStatus,
        partnerId,
        survey: sanitizeDppSurveyAnswers(surveyAnswers),
        ...(!!participantId && { participantId }),
        ...(!!ssTriageUuid && { resourceKey: ssTriageUuid }),
      },
    );

    if (!ssTriageUuid) {
      setSsTriageUuid(key);
    }

    return key;
  };

  return { saveTriageData };
};
