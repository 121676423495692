import React, { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { NoomMedMoreOptionsSelection, ProgramOffering } from "@/constants";
import { useAppContext } from "@/contexts";
import { partnerOffersPrograms } from "@/utils/programs";

import { BaseInterstitial, BaseQuestion } from "../../core";

const QUESTION_ID = "programSet";

type ProgramSetConfig = {
  backgroundColor?: CompassColor;
  ctaText: string;
  footerText: string;
  headerText: string;
  helperText: string;
  imageAlt: string;
  imageSrc: string;
};

type ValueProp = {
  caption: ReactElement;
  url: string;
};

// Mood program does not show this question
type WeightLossProgram = Exclude<ProgramOffering, "MOOD">;

const VALUE_PROPS: ValueProp[] = [
  {
    caption: <Trans i18nKey="programSet.weightDescriptionForMed.valueProp1" />,
    url: "/assets/img/icon-trophy.svg",
  },
  {
    caption: <Trans i18nKey="programSet.weightDescriptionForMed.valueProp2" />,
    url: "/assets/img/icon-graph.svg",
  },
  {
    caption: <Trans i18nKey="programSet.weightDescriptionForMed.valueProp3" />,
    url: "/assets/img/icon-fire.svg",
  },
];

const WeightDescriptionForMedQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const { eligibilityCheck, noomMedMoreOptions } = surveyAnswers;

  return (
    <BaseQuestion
      footer={t("programSet.weightDescriptionForMed.footerText")}
      footerPosition="static"
      onClickBack={onClickBack}
      questionId={QUESTION_ID}
      questionText={t("programSet.weightDescriptionForMed.questionText", {
        firstName: eligibilityCheck?.firstName,
      })}
    >
      {/* This isn't using the prop in BaseQuestion cause of the font size */}
      <Text fontSize="18px" lineHeight="26px">
        {t("programSet.weightDescriptionForMed.questionHelperText")}
      </Text>
      <Stack spacing={Spacing[6]}>
        {VALUE_PROPS.map(({ caption, url }) => (
          <Box
            alignItems="flex-start"
            display="flex"
            gap="var(--spacing-m)"
            key={url}
          >
            <Image alt="" src={url} />
            <Text fontSize="18px" lineHeight="26px">
              {caption}
            </Text>
          </Box>
        ))}
      </Stack>
      <Box display="flex" justifyContent="center">
        <Image
          alt="A graph representing how conventional diets can result in regaining weight vs. keeping it off with Noom"
          src="/assets/img/yoyo-graph.svg"
          width="100%"
        />
      </Box>
      <Button
        colorScheme="primary"
        onClick={() => onClickNext(null)}
        size="xl"
        type="submit"
      >
        {t("programSet.weightDescriptionForMed.ctaText")}
      </Button>
      {noomMedMoreOptions !== NoomMedMoreOptionsSelection.OTHER && (
        <Box
          backgroundColor={CompassColor.offWhite}
          marginTop="var(--spacing-m)"
          padding="12px"
        >
          <Text>{t("programSet.weightDescriptionForMed.disclaimer")}</Text>
        </Box>
      )}
    </BaseQuestion>
  );
};

const ProgramSet: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { enrollmentInfo, partnerInfo, selectedProgram } = useAppContext();
  const programSetConfigMap: Record<WeightLossProgram, ProgramSetConfig> = {
    DPP: {
      ctaText: "Join program",
      footerText:
        "Scientific Reports - 78% of participants using Noom lost weight over 6 month study",
      headerText: `Fantastic ${surveyAnswers.eligibilityCheck?.firstName} - we've got just the program for you.`,
      helperText:
        "Based on your answers, you're at an increased risk of prediabetes. 1 in 3 American adults have prediabetes, and our Diabetes Prevention Program can help. Get ready to lose the weight for good.",
      imageAlt:
        "A graph representing how conventional diets can result in regaining weight vs. keeping it off with Noom",
      imageSrc: "/assets/img/yoyo-graph.svg",
    },
    MED: {
      backgroundColor: CompassColor.salmon,
      ctaText: "Next",
      footerText:
        "* Noom subscribers who lost at least 2% of their original weight as of October 2021.",
      headerText: "You're in! Get ready to lose the weight for good.",
      helperText:
        "Based on your answers, we've customized a weight loss program for you and enrolled you in Noom Med. You're in good hands too - we've helped 3,637,436 people like you lose weight!*",
      imageAlt: "A big thumb with two people climbing up it",
      imageSrc: "/assets/img/big-thumb-mountain.webp",
    },
    WEIGHT: {
      ctaText: "Join program",
      footerText:
        "* Noom subscribers who lost at least 2% of their original weight as of October 2021.",
      headerText: `Fantastic ${surveyAnswers.eligibilityCheck?.firstName} - we've got just the program for you.`,
      helperText:
        "Based on your answers, we've customized a weight loss program for you. And, you're in good hands. We've helped 3,637,436 people like you lose weight!* Get ready to lose the weight for good.",
      imageAlt:
        "A graph representing how conventional diets can result in regaining weight vs. keeping it off with Noom",
      imageSrc: "/assets/img/yoyo-graph.svg",
    },
  };

  if (
    partnerOffersPrograms(["MED"], enrollmentInfo, partnerInfo) &&
    selectedProgram === "WEIGHT"
  ) {
    return (
      <WeightDescriptionForMedQuestion
        onClickBack={onClickBack}
        onClickNext={onClickNext}
        surveyAnswers={surveyAnswers}
      />
    );
  }

  const interstitialProps =
    programSetConfigMap[selectedProgram as WeightLossProgram];

  return (
    <BaseInterstitial
      {...interstitialProps}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      questionId={QUESTION_ID}
      width="600px"
    />
  );
};

export const Q_PROGRAM_SET: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ selectedProgram }) => selectedProgram !== "MOOD",
  component: ProgramSet,
};
