import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

import { Image } from "@noom/wax-component-library";

import { FullscreenMessage, TryAgainVideo } from "@/components";
import {
  NoomEmailAddressLink,
  NoomSupportLink,
} from "@/components/noom-branding";
import { ErrorState } from "@/constants";
import { B2C_PARTNERS } from "@/constants/Partners";
import { useAppContext } from "@/contexts";
import { useTrackEvent } from "@/hooks";
import { getDisplayName } from "@/models";

type ErrorStateTextConfig = {
  callToAction: React.ReactElement;
  errorMessage: string;
  visual?: React.ReactElement;
};

type ErrorStateB2CWhitelist = Extract<
  ErrorState,
  ErrorState.DEFAULT | ErrorState.CODE_CLAIMED | ErrorState.ACCOUNT_EXISTS
>;

export const ErrorView: React.FC = () => {
  const {
    eligibilityInfo,
    errorState,
    maskedEmail,
    partnerInfo,
    surveyMetadata,
  } = useAppContext();
  const { trackAnonEvent, trackIdentifiedEvent, trackSurveyEvent } =
    useTrackEvent();
  const { t } = useTranslation();

  const isB2C = B2C_PARTNERS.includes(partnerInfo?.id as number);

  const goLiveDate =
    errorState === ErrorState.BEFORE_GOLIVE_DATE &&
    partnerInfo?.goLiveDate?.toLocaleString([], {
      dateStyle: "full",
      ...((partnerInfo?.goLiveDate?.getHours() > 0 ||
        (partnerInfo?.goLiveDate?.getHours() === 0 &&
          partnerInfo?.goLiveDate?.getMinutes() > 0)) && {
        timeStyle: "short",
      }),
    });

  const effectiveDate =
    errorState === ErrorState.PRIOR_TO_EFFECTIVE_DATE
      ? eligibilityInfo?.effectiveDate?.toLocaleString([], {
          dateStyle: "full",
          timeZone: "UTC",
        }) || ""
      : "";

  const errorTextConfigs: { [key in ErrorState]: ErrorStateTextConfig } = {
    [ErrorState.CODE_CLAIMED]: {
      callToAction: (
        <Trans i18nKey="error.codeClaimed.callToAction">
          Each signup link contains a code that lets one person sign up for Noom
          - and it looks like this code has already been claimed. Please contact
          your employer for support. If you believe this message is an error,
          contact
          {NoomEmailAddressLink}
          for assistance.
        </Trans>
      ),
      errorMessage: t("error.codeClaimed.errorMessage"),
    },
    [ErrorState.DEFAULT]: {
      callToAction: (
        <Trans i18nKey="error.default.callToAction">
          Please reach out to
          {NoomEmailAddressLink}
          if you have questions or need help.
        </Trans>
      ),
      errorMessage: t("error.default.errorMessage"),
    },
    [ErrorState.ELIGIBILITY_CHECK_EXISTING_ACCOUNT]: {
      callToAction: (
        <Trans i18nKey="error.eligibilityCheckExistingAccount.callToAction">
          Each eligible user can sign up for Noom one time, and a spot has
          already been claimed with the information you provided. Please contact
          your Noom sponsor for support.
          <br />
          <br />
          If you believe this message is an error, contact
          {NoomEmailAddressLink} for assistance.
        </Trans>
      ),
      errorMessage: t("error.eligibilityCheckExistingAccount.errorMessage"),
    },
    [ErrorState.ACCOUNT_EXISTS]: {
      callToAction: (
        <Trans i18nKey="error.existingAccount.callToAction">
          Please login to your existing account via the Noom app. If you can't
          remember your password, you can
          <a href="https://account.noom.com/password-reset">
            reset your password here
          </a>
          . Still having problems? Contact {NoomEmailAddressLink} for additional
          support.
        </Trans>
      ),
      errorMessage: t("error.existingAccount.errorMessage"),
    },
    [ErrorState.SSO_ACCOUNT_EXISTS]: {
      callToAction: (
        <Trans i18nKey="error.ssoExistingAccount.callToAction">
          Please login to your existing account via the Noom app. If you can't
          remember your password, you can
          <a href="https://account.noom.com/password-reset">
            reset your password here
          </a>
          . Still having problems? Contact {NoomEmailAddressLink} for additional
          support.
        </Trans>
      ),
      errorMessage: t("error.ssoExistingAccount.errorMessage"),
      visual: <Image alt="" margin="auto" src="/assets/img/thumbs-up.svg" />,
    },
    [ErrorState.EXISTING_VIRGIN_PULSE_USER]: {
      callToAction: (
        <Trans i18nKey="error.existingVirginPulseUser.callToAction">
          Having trouble accessing your account or have a question? Please reach
          out to
          {NoomEmailAddressLink}
          for assistance.
        </Trans>
      ),
      errorMessage: t("error.existingVirginPulseUser.errorMessage", {
        context: maskedEmail ? "hasEmail" : "",
        email: maskedEmail,
      }),
    },
    [ErrorState.BATCH_FULL]: {
      callToAction: (
        <Trans i18nKey="error.fullBatch.callToAction">
          We're sorry about that. Please reach out to your employer if you have
          any questions about program capacity. Other issues? Drop us a line at
          {NoomEmailAddressLink}.
        </Trans>
      ),
      errorMessage: t("error.fullBatch.errorMessage"),
    },
    [ErrorState.SUPPORT_REQUEST_CREATED]: {
      callToAction: (
        <Trans i18nKey="error.supportRequestCreated.callToAction">
          Unfortunately we encountered an issue while setting up your account.
          Hang tight - we've alerted our support team and they will reach out to
          you soon to set up your Noom program.
          <br />
          <br />
          If you have any questions before then, you can reach out to us at
          partnersupport@noom.com.
        </Trans>
      ),
      errorMessage: t("error.supportRequestCreated.errorMessage"),
    },
    [ErrorState.PENDING_DELETION_REQUEST]: {
      callToAction: (
        <Trans i18nKey="error.pendingDeletion.callToAction">
          Our records show that an account using this email address was recently
          deleted. For privacy reasons we cannot create a new account using that
          email right now.
          <br />
          <br />
          Please sign up again using a different email address. If you are still
          having issues you can reach out to our customer support team at
          partnersupport@noom.com.
        </Trans>
      ),
      errorMessage: t("error.pendingDeletion.errorMessage"),
    },
    [ErrorState.BEFORE_GOLIVE_DATE]: {
      callToAction: (
        <Trans i18nKey="error.beforeGoliveDate.callToAction">
          We haven't launched with {getDisplayName(partnerInfo)} yet. Please
          come back on {goLiveDate} to sign up for Noom.
        </Trans>
      ),
      errorMessage: t("error.beforeGoliveDate.errorMessage"),
    },
    [ErrorState.FAILED_ELIGIBILITY_CHECK]: {
      callToAction: (
        <Trans i18nKey="error.failedEligibilityCheck.callToAction">
          We need a match in order to confirm your eligibility. Please go back
          and double check the details you provided.
          <br />
          <br />
          Still having issues? Email {NoomEmailAddressLink} with your name,
          email, and membership sponsor and our team will do our best to help
          you out.
          <br />
          <br />
          If you aren't eligible and still want to use Noom, you can sign up and
          pay for a membership at
          <a href="https://www.noom.com">noom.com</a>.
        </Trans>
      ),
      errorMessage: t("error.failedEligibilityCheck.errorMessage"),
    },
    [ErrorState.SSO_INELIGIBLE]: {
      callToAction: (
        <Trans i18nKey="error.ssoIneligible.callToAction">
          Something went wrong while trying to confirm your eligibility.
          <br />
          <br />
          Please email {NoomEmailAddressLink} with your name, email, and
          membership sponsor and our team will do our best to help you out.
          <br />
          <br />
          If you aren't eligible and still want to use Noom, you can sign up and
          pay for a membership at
          <a href="https://www.noom.com">noom.com</a>.
        </Trans>
      ),
      errorMessage: t("error.ssoIneligible.errorMessage"),
    },
    [ErrorState.PAST_CONTRACT_END_DATE]: {
      callToAction: (
        <Trans i18nKey="error.pastContractEndDate.callToAction">
          Thanks for your interest in Noom! Right now,{" "}
          {getDisplayName(partnerInfo)} isn't sponsoring more Noom memberships.
          However, you can still sign up for the Noom program on your own by
          going to <a href="https://www.noom.com">noom.com</a>.
          <br />
          <br />
          If you have questions or are concerned you're seeing this message in
          error, please email {NoomEmailAddressLink} or contact your Noom
          sponsor.
        </Trans>
      ),
      errorMessage: t("error.pastContractEndDate.errorMessage", {
        partnerName: getDisplayName(partnerInfo),
      }),
    },
    [ErrorState.PRIOR_TO_EFFECTIVE_DATE]: {
      callToAction: t("error.priorToEffectiveDate.callToAction", {
        effectiveDate,
      }),
      errorMessage: t("error.priorToEffectiveDate.errorMessage"),
    },
  };

  const errorTextB2CConfigs: {
    [key in ErrorStateB2CWhitelist]: ErrorStateTextConfig;
  } = {
    [ErrorState.CODE_CLAIMED]: {
      callToAction: (
        <Trans
          i18nKey="error.codeClaimed.callToAction_b2c"
          components={{ supportLink: NoomSupportLink }}
        />
      ),
      errorMessage: t("error.codeClaimed.errorMessage"),
    },
    [ErrorState.DEFAULT]: {
      callToAction: (
        <Trans
          i18nKey="error.default.callToAction_b2c"
          components={{ supportLink: NoomSupportLink }}
        />
      ),
      errorMessage: t("error.default.errorMessage"),
    },
    [ErrorState.ACCOUNT_EXISTS]: {
      callToAction: (
        <Trans
          i18nKey="error.existingAccount.callToAction_b2c"
          components={{ supportLink: NoomSupportLink }}
        />
      ),
      errorMessage: t("error.existingAccount.errorMessage"),
    },
  };

  const errorText =
    (isB2C &&
      errorTextB2CConfigs[errorState as unknown as ErrorStateB2CWhitelist]) ||
    errorTextConfigs[errorState];

  const { callToAction, errorMessage, visual } = errorText;

  useEffect(() => {
    trackIdentifiedEvent("b2bEnrollmentIdentifiedErrorPageViewed", {
      b2bErrorType: errorState,
    });

    if (surveyMetadata) {
      trackSurveyEvent("b2bSurveyAnonErrorPageViewed", {
        errorType: errorState,
      });
    } else {
      trackAnonEvent("b2bEnrollmentAnonErrorPageViewed", {
        b2bErrorType: errorState,
      });
    }
  }, [errorState]);

  return (
    <FullscreenMessage
      heading={errorMessage}
      message={callToAction}
      visual={visual || <TryAgainVideo />}
    />
  );
};
