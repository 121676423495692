import { HandlerCallback } from "@/utils/pixels/handler";
import { captureException } from "@/utils/sentry";

export const PIXEL_RETRY_DELAY_MS = 5000;
export const PIXEL_RETRY_MAX_ATTEMPTS = 10;

export async function fireCallbackWithRetry<T>(
  callback: HandlerCallback<T>,
  args: T,
  delay = PIXEL_RETRY_DELAY_MS,
  maxAttempts = PIXEL_RETRY_MAX_ATTEMPTS,
  attempt = 0,
): Promise<void> {
  try {
    await callback(args);
  } catch (e) {
    // In Non-Production environments, log the exception for easy debugging.
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    const nextAttempt = attempt + 1;
    if (nextAttempt < maxAttempts) {
      await new Promise((resolve) => {
        setTimeout(resolve, delay);
      });
      await fireCallbackWithRetry(
        callback,
        args,
        delay,
        maxAttempts,
        nextAttempt,
      );
    } else {
      captureException(e);
    }
  }
}

/**
 * This function is used by some of the Facebook Pixels for creating our custom event_id
 * Used for deduplication between browser pixel and server pixel
 */
export function generateEventId(userId: string) {
  const currentTimestamp = new Date().getTime();
  const randomString = (Math.random() + 1).toString(36).substring(8);

  return `${userId}_${currentTimestamp}_${randomString}`;
}
