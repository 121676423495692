/**
 * Endpoint for getting an upid for a given batch
 *
 * Copyright (C) 2020C Noom, Inc.
 * @author Hubert
 */

import axios from "axios";
import { Api } from "@noom/noomscape";

Api.register(
  "upid.getEnrollmentInformation",
  async ({ upid }: { upid: string }) => {
    const response = await axios({
      url: `/programs/upid/${upid}:getEnrollmentInformation`,
      urlWithPathParams: "/programs/upid/{upid}:getEnrollmentInformation",
      baseURL: window.ENV.SERVER_URL,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  },
);
