import { EligibilityFeaturesProto } from "@noom/noom-contracts/noom_contracts/backend/partner/model/eligibility";
import { B2BEnrollmentEnumsProto_Program } from "@noom/noom-contracts/noom_contracts/events/web/b2b/shared";
import { EntitlementsProto } from "@noom/noom-contracts/noom_contracts/usermodel/baselined/entitlements/entitlements";

import { Curriculum } from "@/constants";

export enum RetriageEligibilityReason {
  ELIGIBLE = "ELIGIBLE",
  NOT_OFFERED = "NOT_OFFERED",
  NOT_CURRENTLY_ENROLLED = "NOT_CURRENTLY_ENROLLED",
  NOT_IN_LATEST_FILE = "NOT_IN_LATEST_FILE",
}

export type RetriageEligibilityVerificationProperties = {
  dateOfBirth?: string;
  eligibilityId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  participantId?: string;
};

export type RetriageEligibilityResponse = {
  accessCode?: string;
  currentBatchMembership?: number;
  currentEntitlements?: EntitlementsProto;
  eligibilityReason: RetriageEligibilityReason;
  triageCurriculums?: Array<Curriculum>;
};

export type RetriageEligibility = {
  accessCode?: string;
  currentProgram: B2BEnrollmentEnumsProto_Program;
  eligibilityReason: RetriageEligibilityReason;
  triageCurriculums: Array<Curriculum>;
  eligibilityProperties?: EligibilityFeaturesProto;
  didVerifyUsingAutologinData?: boolean;
  medEligible?: boolean;
};
