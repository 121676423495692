import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Spacing, Stack } from "@noom/wax-component-library";

import { HeightWeightForm, HeightWeightSchema } from "@/components/forms";
import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { ScreenedOutReason } from "@/constants";
import { CAREFIRST_PARTNER_ID } from "@/constants/Partners";
import { useAppContext } from "@/contexts";
import { calculateBMI } from "@/utils/calculateBMI";

import { BaseQuestion } from "../core";

const QUESTION_ID = "heightWeight";

type FormValues = {
  heightFeetPortion?: number;
  heightInchesPortion?: number;
  weight?: number;
};

declare module "@/contexts" {
  interface SurveyAnswers {
    heightWeight?: FormValues;
  }
}

const defaultInitialValues: FormValues = {
  heightFeetPortion: undefined,
  heightInchesPortion: undefined,
  weight: undefined,
};

const HeightWeightQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { enrollmentType, partnerInfo, retriageEligibility } = useAppContext();
  const { t } = useTranslation();

  const screenOutOrProceed = (values: FormValues) => {
    // If height and weight are submitted we expect them to be numbers due to
    // them being required fields in the validation schema
    const bmi = calculateBMI(
      values.heightFeetPortion!,
      values.heightInchesPortion!,
      values.weight!,
    );

    if (bmi < 18 || (partnerInfo?.id === CAREFIRST_PARTNER_ID && bmi < 23)) {
      onClickNext(values, {}, ScreenedOutReason.BMI_TOO_LOW);
      return;
    }

    if (bmi < 23) {
      onClickNext(values, { dppEligible: false });
    } else {
      onClickNext(values);
    }
  };

  const initialValues = surveyAnswers[QUESTION_ID] || defaultInitialValues;

  const retriageQuestionTextContext =
    retriageEligibility?.didVerifyUsingAutologinData ? "firstTouch" : "";

  const questionText =
    enrollmentType === "RETRIAGE"
      ? t("heightWeight.questionText.retriage", {
          context: retriageQuestionTextContext,
        })
      : t("heightWeight.questionText.enrollment");

  let questionHelperText = "";
  if (enrollmentType === "RETRIAGE") {
    questionHelperText = t("heightWeight.questionHelperText.retriage");
  } else if (enrollmentType === "INITIAL_ENROLLMENT") {
    questionHelperText = t("heightWeight.questionHelperText.enrollment");
  }

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionHelperText={questionHelperText}
      questionId={QUESTION_ID}
      questionText={questionText}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => screenOutOrProceed(values)}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={HeightWeightSchema}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={Spacing[4]}>
              <HeightWeightForm focusHeightFeet />
              <Button
                colorScheme="primary"
                isLoading={isSubmitting}
                size="xl"
                type="submit"
              >
                Next
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
    </BaseQuestion>
  );
};

export const Q_HEIGHT_WEIGHT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ selectedProgram }) => selectedProgram !== "MOOD",
  component: HeightWeightQuestion,
};

export const Q_RETRIAGE_HEIGHT_WEIGHT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, triageCurriculums = [] } = {},
  }) => currentProgram === "WEIGHT" && triageCurriculums.length > 1,
  component: HeightWeightQuestion,
};
