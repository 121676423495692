import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, Spacing, Stack } from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { useAppContext } from "@/contexts";
import { useTrackEvent } from "@/hooks";

import { BaseQuestion } from "../core";

const QUESTION_ID = "confirmChangeProgram";

declare module "@/contexts" {
  interface SurveyAnswers {
    confirmChangeProgram?: boolean;
  }
}

const ConfirmChangeProgram: React.FC<QuestionComponentProps> = ({
  onClickNext,
}) => {
  const { retriageEligibility } = useAppContext();
  const { t } = useTranslation();
  const { trackAnonEvent } = useTrackEvent();

  const handleOptIn = (didOptIn: boolean) => {
    if (didOptIn) {
      trackAnonEvent("b2bEnrollmentAnonRetriageNewProgramSelected", {
        currentProgram:
          retriageEligibility?.currentProgram || "PROGRAM_UNSPECIFIED",
        newProgram: "DPP",
      });
    }

    onClickNext(didOptIn);
  };

  return (
    <BaseQuestion
      hideHeader
      onClickBack={() => undefined}
      questionId={QUESTION_ID}
      imageSrc="/assets/img/changeProgram.svg"
      questionText={t("confirmChangeProgram.title")}
    >
      <Stack spacing={Spacing[8]}>
        <Trans i18nKey="confirmChangeProgram.prompt.dpp">
          We do want to be clear: joining means starting a new program. Your
          course progress and Noomcoins will be reset.
        </Trans>
        <Stack spacing={Spacing[4]} pt={Spacing[2]}>
          <Button
            colorScheme="primary"
            onClick={() => {
              handleOptIn(true);
            }}
            size="xl"
            type="submit"
          >
            {t("confirmChangeProgram.continue", "Join new program")}
          </Button>
          <Button
            onClick={() => {
              handleOptIn(false);
            }}
            size="xl"
            variant="outline"
          >
            {t("confirmChangeProgram.cancel", "Stay in current program")}
          </Button>
        </Stack>
      </Stack>
    </BaseQuestion>
  );
};

export const Q_CONFIRM_CHANGE_PROGRAM: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ surveyAnswers: { dppEligible } }) =>
    dppEligible === true,
  component: ConfirmChangeProgram,
};
