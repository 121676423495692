import { Formik } from "formik";
import { TFunction } from "i18next";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { StatusEnumProto_Value } from "@noom/noom-contracts/noom_contracts/backend/privacy/user_policy_options";
import {
  Button,
  Checkbox,
  Input,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import {
  PhoneInput,
  PhoneInputSchema,
  PhoneNumber,
  PreferredLanguageSelector,
} from "@/components/form";
import {
  AddressForm,
  AddressSchema,
  buildAddressSchema,
  HealthDataConsentForm,
} from "@/components/forms";
import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { CONSUMER_HEALTH_LAW_STATES, DEFAULT_ADDRESS } from "@/constants";
import {
  CAREFIRST_PARTNER_ID,
  HEALTH_DATA_CONSENT_DISABLED_PARTNERS,
} from "@/constants/Partners";
import { useAppContext } from "@/contexts";
import { useTrackEvent } from "@/hooks";
import { Address } from "@/models";
import { OPT_OUT_COOKIE_NAME } from "@/utils/consent/opt-out";

import { BaseQuestion } from "../core";

const QUESTION_ID = "completeProfile";

export type FormValues = {
  address: Address;
  sameShippingAddress?: boolean;
  shippingAddress?: Address;
  employeeId?: string;
  locale?: string;
  phoneNumber: PhoneNumber;
  consentStatus?: StatusEnumProto_Value;
  consentSignature?: string;
};

declare module "@/contexts" {
  interface SurveyAnswers {
    completeProfile?: Omit<FormValues, "sameShippingAddress">;
  }
}

/**
 * Determines if the health data consent form should be shown based on the
 * partner and the user's home state.
 *
 * @param region Selected region (US state) in the address form
 * @param partnerSlug Slug of the partner for which the user is enrolling into
 * @returns True if the form is able to be shown to that partner and user is
 * located in an applicable state, false otherwise
 */
const shouldShowHealthDataConsent = (region: string, partnerSlug = "") =>
  !HEALTH_DATA_CONSENT_DISABLED_PARTNERS.includes(partnerSlug) &&
  CONSUMER_HEALTH_LAW_STATES.includes(region);

const getValidationSchema = (
  t: TFunction,
  showEmployeeIdField: boolean,
  partnerSlug = "",
) => {
  let baseSchema = Yup.object({
    address: AddressSchema,
    sameShippingAddress: Yup.boolean(),
    phoneNumber: PhoneInputSchema,
    shippingAddress: buildAddressSchema("shippingAddress").when(
      "sameShippingAddress",
      {
        is: true,
        then: () => Yup.mixed().notRequired(),
        otherwise: (s) => s.required(),
      },
    ),
    consentStatus: Yup.string().when("address.region", {
      is: (region: string) => shouldShowHealthDataConsent(region, partnerSlug),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
    consentSignature: Yup.string().when("address.region", {
      is: (region: string) => shouldShowHealthDataConsent(region, partnerSlug),
      then: (schema) =>
        schema
          .trim()
          .required(
            t("form.healthDataConsent.consentSignature.errors.required"),
          )
          .test(
            "is-valid-length",
            t("form.healthDataConsent.consentSignature.errors.tooLong"),
            (value = "") => value.length <= 100,
          ),
      otherwise: (schema) => schema,
    }),
  });

  if (showEmployeeIdField) {
    baseSchema = baseSchema.shape({
      employeeId: Yup.string().required(t("form.employeeId.errors.required")),
    });
  }

  return baseSchema;
};

const CompleteProfileQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const {
    eligibilityInfo,
    enrollmentInfo: { upfrontEligibilityEnforcement } = {},
    partnerInfo,
    selectedProgram,
  } = useAppContext();
  const { trackAnonEvent } = useTrackEvent();
  const { i18n, t } = useTranslation();

  // The Employee ID field is typically shown on the eligibility page. We want
  // to show it on the complete profile page if it is not used in eligibility
  // but should still be collected.

  const showEmployeeIdFieldOnEligibilityCheck =
    upfrontEligibilityEnforcement === "PARTICIPANT_ID" ||
    upfrontEligibilityEnforcement === "PARTICIPANT_ID_LAST_NAME" ||
    upfrontEligibilityEnforcement === "UHC_OPTUM_BIG_FIVE";

  const showEmployeeIdFieldOnCompleteProfile =
    !showEmployeeIdFieldOnEligibilityCheck &&
    (partnerInfo?.configs?.REQUIRES_PARTICIPANT_ID || false);

  const isNoomWeightProgram = selectedProgram === "WEIGHT";
  const supportedLocales = partnerInfo?.supportedLocales || ["en"];
  const showLanguageDropdown =
    isNoomWeightProgram && supportedLocales.length > 1;

  const defaultInitialValues: FormValues = {
    address: eligibilityInfo?.address || DEFAULT_ADDRESS,
    phoneNumber: {
      country: "us",
      dialCode: "1",
      value: eligibilityInfo?.phoneNumber || "",
    },
    sameShippingAddress: true,
    shippingAddress: eligibilityInfo?.address || DEFAULT_ADDRESS,
  };

  const onSubmit = (values: FormValues) => {
    const { sameShippingAddress, ...answers } = values;
    if (sameShippingAddress) {
      delete answers.shippingAddress;
    }

    if (values.consentStatus === "OPT_OUT_DO_NOT_SHARE") {
      Cookies.set(OPT_OUT_COOKIE_NAME, "true");
    }

    onClickNext(answers);
  };

  useEffect(() => {
    trackAnonEvent("b2bEnrollmentAnonProgramAssigned", {
      b2bProgram: selectedProgram || "PROGRAM_UNSPECIFIED",
    });
  }, []);

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionId={QUESTION_ID}
      questionText="Almost done! Just a few more questions to finish setting up your profile."
    >
      <Formik
        initialValues={{
          ...defaultInitialValues,
          ...surveyAnswers[QUESTION_ID],
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={getValidationSchema(
          t,
          showEmployeeIdFieldOnCompleteProfile,
          partnerInfo?.slug,
        )}
      >
        {({
          errors,
          getFieldProps,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          validateField,
          values,
        }) => {
          const showHealthDataConsent = shouldShowHealthDataConsent(
            values.address.region,
            partnerInfo?.slug,
          );

          const handleInputChange = async (
            e:
              | React.ChangeEvent<HTMLInputElement>
              | React.ChangeEvent<HTMLSelectElement>,
            fieldName: keyof FormValues,
          ) => {
            // This is a hacky method to get each field to validate on change
            // individually.
            await handleChange(e);
            validateField(fieldName);
          };
          return (
            <form onSubmit={handleSubmit}>
              <Stack spacing={Spacing[8]} pb={Spacing[8]}>
                {showEmployeeIdFieldOnCompleteProfile && (
                  <Input
                    {...getFieldProps("employeeId")}
                    autoFocus
                    error={touched.employeeId && errors.employeeId}
                    helper={
                      i18n.exists(`form.employeeId.helper_${partnerInfo?.slug}`)
                        ? t(`form.employeeId.helper_${partnerInfo?.slug}`)
                        : undefined
                    }
                    label={t("form.employeeId.label")}
                    onChange={(e) => handleInputChange(e, "employeeId")}
                    placeholder={t("form.employeeId.placeholder")}
                  />
                )}
                <PhoneInput
                  focusPhoneNumber={!showEmployeeIdFieldOnCompleteProfile}
                  helper={t("form.phoneNumber.helper")}
                  label={t("form.phoneNumber.label")}
                  name="phoneNumber"
                  placeholder={t("form.phoneNumber.placeholder")}
                />
                {showLanguageDropdown && (
                  <PreferredLanguageSelector
                    {...getFieldProps("locale")}
                    supportedLocales={supportedLocales}
                  />
                )}
                <AddressForm
                  countryCode={values.address.country}
                  countrySelectDisabled={selectedProgram === "MED"}
                />
                {surveyAnswers.scaleOptIn && (
                  <Checkbox
                    {...getFieldProps("sameShippingAddress")}
                    isChecked={values.sameShippingAddress === true}
                  >
                    Ship my scale to the same address
                  </Checkbox>
                )}
                {surveyAnswers.scaleOptIn && !values.sameShippingAddress && (
                  <Stack spacing={Spacing[4]}>
                    <Text fontWeight={500}>
                      Ship my scale to this address instead
                    </Text>
                    <AddressForm
                      formName="shippingAddress"
                      countryCode={values.shippingAddress?.country || ""}
                      countrySelectDisabled={selectedProgram === "MED"}
                    />
                  </Stack>
                )}
                {showHealthDataConsent && <HealthDataConsentForm />}
                <Button
                  colorScheme="primary"
                  isDisabled={
                    showHealthDataConsent &&
                    (!values.consentStatus || !values.consentSignature)
                  }
                  isLoading={isSubmitting}
                  size="xl"
                  type="submit"
                >
                  Sign up for Noom
                </Button>
                {partnerInfo?.id === CAREFIRST_PARTNER_ID && (
                  <Text>
                    By clicking "Sign up for Noom", you are enrolling in a Noom
                    subscription at no cost to you, available through CareFirst
                    BlueCross BlueShield.
                  </Text>
                )}
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseQuestion>
  );
};
export const Q_COMPLETE_PROFILE: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => true,
  component: CompleteProfileQuestion,
};
