import { LibCategories, waitForConsent } from "@/utils/consent";
import { toSha1, toSha256 } from "@/utils/cryptography";
import { initializePublishers } from "@/utils/pixels/publishers";

import {
  fireCallbackWithRetry,
  PIXEL_RETRY_DELAY_MS,
  PIXEL_RETRY_MAX_ATTEMPTS,
} from "./utils";

export type PurchaseArgs = {
  email: string;
  eltv_25_months: number;
  erev_13_months: number;
  userId: string;
};

type EmailHashes = {
  sha1: string;
  sha256: string;
};

export type CleanPurchaseArgs = {
  emailHashes: EmailHashes;
  eltv_25_months: number;
  erev_13_months: number;
  userId: string;
};

export type HandlerCallback<T> = (args: T) => void;

export interface HandlerOptions {
  retry?: boolean;
}

type incomingEvents = {
  purchase: PurchaseArgs;
};

const cleanEvents = {
  purchase: [] as HandlerCallback<CleanPurchaseArgs>[],
};

export type EVENTS = keyof typeof cleanEvents;

export async function cleanProps<T extends EVENTS>(
  props: incomingEvents[T],
): Promise<Parameters<(typeof cleanEvents)[T][0]>[0]> {
  const result = { ...props } as any;
  // if (props === null || result === null) {
  //   return null;
  // }

  if (props?.email) {
    result.emailHashes = {
      sha1: await toSha1(props.email),
      sha256: await toSha256(props.email),
    } as EmailHashes;
    delete result.email;
  }

  return result;
}

let initGate: Promise<any>;

/**
 * Fire a conversion pixel event and pass in necessary props.
 * */
export async function firePixelEvent<T extends EVENTS>(
  event: T,
  props: incomingEvents[T],
): Promise<void> {
  const cleanedProps = await cleanProps<T>(props);
  await initGate;

  const handlers = cleanEvents[event];
  const promises = handlers.map((handler) => handler(cleanedProps));

  await Promise.all(promises);
}

/**
 * Register a conversion pixel event handler.
 * */
export function registerHandler<T extends EVENTS>(
  event: T,
  callback: (typeof cleanEvents)[T][0],
  options: HandlerOptions = {},
) {
  // Retry is on by default. Need to explicitly pass in false to turn off.
  const maxAttempts = options?.retry === false ? 1 : PIXEL_RETRY_MAX_ATTEMPTS;

  function wrappedCallback(
    args: Parameters<typeof callback>[0],
  ): Promise<void> {
    return fireCallbackWithRetry(
      callback,
      args,
      PIXEL_RETRY_DELAY_MS,
      maxAttempts,
    );
  }

  cleanEvents[event].push(wrappedCallback);
}

/**
 * Util for unit tests -- unlikely you would ever want to call this in production code
 */
export function clearAllHandlers() {
  Object.keys(cleanEvents).forEach((key) => {
    cleanEvents[key as EVENTS] = [];
  });
}

export async function initializeHandlers() {
  if (initGate) {
    return initGate;
  }

  // Break into async loaded task. Doing this both to prioritize our own
  // code loading and to avoid circular dependencies within the pixels.
  initGate = waitForConsent([LibCategories.targetingCookies])
    .then(() => import(/* webpackChunkName: "publishers" */ "./publishers"))
    .then(() => {
      initializePublishers();
    });

  return initGate;
}
