/**
 * Translation configuration
 *
 * Copyright (C) 2019D Noom, Inc.
 * @author nikola
 */

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

import { enu } from "@noom/noomscape";

// TODO Uncomment languages when translation files are added
export type Language = "en" /* | "es" | "de" | "ko" | "ja" */;

export const LANGUAGES: Record<Language, string> = {
  en: "English",
  // es: "Español",
  // de: "Deutsch",
  // ko: "한국어",
  // ja: "日本語",
};

export const NAMESPACES = enu(["enrollment", "employerLanding"]);

export function init(): void {
  i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: false,
      fallbackLng: "en",
      whitelist: Object.keys(LANGUAGES),
      interpolation: {
        escapeValue: false,
      },
      ns: Object.keys(NAMESPACES),
      defaultNS: NAMESPACES.enrollment,
      fallbackNS: NAMESPACES.enrollment,
      backend: {
        loadPath: "/assets/locales/{{lng}}/{{ns}}.json",
      },
      detection: {
        lookupQuerystring: "lang",
        checkWhitelist: true,
      },
    });
}
