import React from "react";

import { convertLbToKg } from "@/utils/UnitUtils";
import { calculateBMIMetric } from "@/utils/bmi";
import { shouldAskBaselineOutcomes } from "@/utils/survey/outcomes";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "glp1Medication";

declare module "@/contexts" {
  interface SurveyAnswers {
    glp1Medication?: string;
  }
}

const options: QuestionOption[] = [
  { id: "yes", text: "Yes" },
  {
    id: "prescriptionNotYetStarted",
    text: "I have a prescription but have not yet started taking it",
  },
  {
    id: "noButConsidering",
    text: "No, but I am considering",
  },
  { id: "no", text: "No" },
];

const Glp1MedicationQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => (
  <MultipleChoiceQuestion
    onClickBack={onClickBack}
    onClickNext={onClickNext}
    options={options}
    questionId={QUESTION_ID}
    questionText="Are you currently taking a GLP&#8209;1 medication like Ozempic, Mounjaro, Wegovy, Zepbound, etc., to help you with weight loss?"
    selectedItem={surveyAnswers[QUESTION_ID] || ""}
  />
);

export const Q_GLP1_MEDICATION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram }) =>
    selectedProgram !== "MOOD" && shouldAskBaselineOutcomes(partnerInfo),
  component: Glp1MedicationQuestion,
};

export const Q_OUTCOMES_GLP1_MEDICATION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    surveyAnswers: { weight: weightInLbs },
    userProfile,
  }) => {
    const { currentProgram, heightCms, latestWeightKgs } = userProfile || {};

    // Only show for cardiometabolic programs
    if (currentProgram === "MOOD") {
      return false;
    }

    // Only show for BMI >= 27

    // Use BMI obtained from user profile & latest weigh-in if it is available
    if (heightCms !== undefined && latestWeightKgs !== undefined) {
      return calculateBMIMetric(heightCms, latestWeightKgs) >= 27;
    }

    // Fallback to BMI obtained from height on user profile (if available) and weight from survey
    if (heightCms !== undefined && weightInLbs !== undefined) {
      return (
        calculateBMIMetric(
          heightCms,
          convertLbToKg({
            mainUnitValue: weightInLbs,
          }).mainUnitValue,
        ) >= 27
      );
    }

    // If we weren't able to calculate BMI for some reason default to showing question.
    return true;
  },

  component: Glp1MedicationQuestion,
};
