import { CivilDateTimeProto } from "@noom/noom-contracts/noom_contracts/lib/time/civil_time";

export function createCivilDateTime(t: Date): CivilDateTimeProto {
  return {
    date: {
      day: t.getDate(),
      // CivilDateTimeProto expects month in range [1, 12]
      month: t.getMonth() + 1,
      year: t.getFullYear(),
    },
    time: {
      hour: t.getHours(),
      minute: t.getMinutes(),
      second: t.getSeconds(),
    },
  };
}
