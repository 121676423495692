import styled from "@emotion/styled";
import React, { useRef, useState } from "react";

// The video shows black borders around the sides, so this is the
// only component that uses emotion to remove the styling
// https://stackoverflow.com/questions/20771971/video-player-shows-black-border
const VideoComponent = styled.video`
  clip-path: inset(21px 1px);
  margin: auto;
`;

export const TryAgainVideo: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [hasLoopedOnce, setHasLoopedOnce] = useState(false);

  const onEndedVideo = () => {
    if (hasLoopedOnce) {
      return;
    }

    setHasLoopedOnce(true);

    if (videoRef?.current) {
      try {
        videoRef.current.play().catch(() => null);
      } catch (e) {
        // noop
      }
    }
  };

  return (
    <VideoComponent
      autoPlay
      muted
      onEnded={onEndedVideo}
      // playsInline attribute is specifically for iOS - see below link:
      // https://stackoverflow.com/questions/43570460/html5-video-autoplay-on-iphone/45402661#45402661
      playsInline
      ref={videoRef}
      src="/assets/img/try-again.mp4"
      width="300px"
    />
  );
};
