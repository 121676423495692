import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  CompassColor,
  Link,
  Size,
  Spacing,
  Stack,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";

import { BaseQuestion, ImageBulletListCard } from "../core";

const QUESTION_ID = "dppInformation";

const DppInformation: React.FC<QuestionComponentProps> = ({ onClickNext }) => {
  const { t } = useTranslation();
  const bulletListItems = [
    {
      image: "/assets/img/dppInformation-icon-graph.svg",
      text: t("dppInformation.listItems.0"),
    },
    {
      image: "/assets/img/dppInformation-icon-party.svg",
      text: t("dppInformation.listItems.1"),
    },
    {
      image: "/assets/img/dppInformation-icon-mountain.svg",
      text: t("dppInformation.listItems.2"),
    },
  ];
  const footer = (
    <>
      *
      <Link
        href="https://go.noom.com/bmj-study"
        target="_blank"
        fontSize={Size.sm}
        color={CompassColor.black}
        textDecoration="underline"
      >
        {t("dppInformation.studyLinkText", "See published study")}
      </Link>
    </>
  );
  return (
    <BaseQuestion
      hideHeader
      footer={footer}
      onClickBack={() => undefined}
      questionId={QUESTION_ID}
      questionText={t("dppInformation.questionText")}
    >
      <ImageBulletListCard items={bulletListItems} />
      <Stack spacing={Spacing[4]} pt={Spacing[2]}>
        <Button
          colorScheme="primary"
          onClick={() => {
            onClickNext(null);
          }}
          size="xl"
          type="submit"
        >
          Next
        </Button>
      </Stack>
    </BaseQuestion>
  );
};

export const Q_DPP_INFORMATION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ surveyAnswers: { dppEligible } }) =>
    dppEligible === true,
  component: DppInformation,
};
