import React, { ReactElement, useEffect } from "react";

import {
  Box,
  Button,
  CompassColor,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { NoomMedOverviewSelection, ProgramOffering } from "@/constants";
import { useAppContext } from "@/contexts";
import { qualifiesForNoomMed } from "@/utils/qualification";

import { BaseQuestion } from "../core";

const QUESTION_ID = "noomMedOverview";

declare module "@/contexts" {
  interface SurveyAnswers {
    noomMedOverview?: NoomMedOverviewSelection;
    updatedProgram?: ProgramOffering | null;
  }
}

type ValueProp = {
  caption: ReactElement;
  url: string;
};

const VALUE_PROPS: ValueProp[] = [
  {
    caption: (
      <>
        <Text fontWeight={500}>Get tested</Text>
        <Text> for conditions that contribute to weight gain</Text>
      </>
    ),
    url: "/assets/img/icon-recipes.svg",
  },
  {
    caption: (
      <>
        <Text>Get 1:1 virtual support from a </Text>
        <Text fontWeight={500}>specially-trained clinician*</Text>
      </>
    ),
    url: "/assets/img/icon-group-feed.svg",
  },
  {
    caption: (
      <>
        <Text>Learn about </Text>
        <Text fontWeight={500}>medication options</Text>
        <Text> that can support your weight loss</Text>
      </>
    ),
    url: "/assets/img/icon-meds.svg",
  },
];

const NoomMedOverviewQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { setSelectedProgram, setSurveyAnswers } = useAppContext();

  useEffect(() => {
    // 1 of 2 places we assign selectedProgram to MED is in this question.
    // if the user back navigates through this question we should nullify selectedProgram
    setSelectedProgram(null);
    // also delete updatedProgram
    const newSurveyAnswers = { ...surveyAnswers };
    delete newSurveyAnswers.updatedProgram;
    setSurveyAnswers(newSurveyAnswers);
  }, []);

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionHelperText="As a Noom Med member, you'll join our weight program and additionally receive the following benefits:"
      questionId={QUESTION_ID}
      questionText="Great news! Based on your answers, you're eligible for Noom Med."
    >
      <Stack spacing={Spacing[4]}>
        {VALUE_PROPS.map(({ caption, url }) => (
          <Box
            alignItems="flex-start"
            display="flex"
            gap="var(--spacing-m)"
            key={url}
          >
            <Image alt="" src={url} />
            <Text fontSize="18px" lineHeight="26px">
              {caption}
            </Text>
          </Box>
        ))}
      </Stack>
      <Box
        border={`2px solid ${CompassColor.grey1}`}
        borderRadius="8px"
        padding="var(--spacing-l)"
      >
        <Image
          alt="Line graph depicting weight loss data from a study where participants lost more weight with a lifestyle change and medication over a lifestyle change alone"
          src="/assets/img/noom-med-graph.png"
        />
        <Box marginTop="var(--spacing-l)">
          <Text color={CompassColor.grey3} fontSize="14px" lineHeight="20px">
            *Based on a 68 week study of participants with BMI of 30 or greater.
          </Text>
        </Box>
      </Box>
      <Stack spacing={Spacing[4]}>
        <Button
          colorScheme="primary"
          onClick={() => {
            setSelectedProgram("MED");
            onClickNext(NoomMedOverviewSelection.JOIN, {
              updatedProgram: "MED",
            });
          }}
          size="xl"
          type="submit"
        >
          Join Noom Med
        </Button>
        <Button
          onClick={() => onClickNext(NoomMedOverviewSelection.SEE_MORE_OPTIONS)}
          size="xl"
          type="submit"
          variant="outline"
        >
          See more options
        </Button>
      </Stack>
      <Text color={CompassColor.grey3} fontSize="14px" lineHeight="20px">
        *There are no copays for doctor visits. Your insurance covers
        anti-obesity medications, but there may be a medication copay depending
        on your plan.
      </Text>
    </BaseQuestion>
  );
};

export const Q_NOOM_MED_OVERVIEW: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    partnerInfo,
    selectedProgram,
    surveyAnswers,
    telehealthEligibleStates,
  }) =>
    selectedProgram !== "MOOD" &&
    qualifiesForNoomMed(surveyAnswers, partnerInfo, telehealthEligibleStates),
  component: NoomMedOverviewQuestion,
};
