import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { StatusEnumProto_Value } from "@noom/noom-contracts/noom_contracts/backend/privacy/user_policy_options";
import { Input, Spacing, Stack, Text } from "@noom/wax-component-library";

import { useAppContext } from "@/contexts";
import { useTrackEvent } from "@/hooks";
import { getDisplayName } from "@/models";

import { NoomEmailAddressLink } from "../noom-branding";
import { QuestionOption } from "../survey/Question";
import { MultipleChoiceOptions } from "../survey/multipleChoice";
import type { FormValues } from "../survey/questionTypes/dpp-triage/CompleteProfileQuestion";

export const HealthDataConsentForm: React.FC = () => {
  const { partnerInfo } = useAppContext();
  const {
    errors,
    getFieldProps,
    handleChange,
    setFieldValue,
    touched,
    validateField,
    values,
  } = useFormikContext<FormValues>();
  const { trackAnonEvent } = useTrackEvent();
  const { t } = useTranslation();

  const options: (QuestionOption & { id: StatusEnumProto_Value })[] = [
    {
      id: "OPT_IN_MAY_SHARE",
      text: t("form.healthDataConsent.options.optIn"),
    },
    {
      id: "OPT_OUT_DO_NOT_SHARE",
      text: t("form.healthDataConsent.options.optOut"),
    },
  ];

  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
  ) => {
    // This is a hacky method to get each field to validate on change
    // individually.
    await handleChange(e);
    validateField(fieldName);
  };

  useEffect(() => {
    trackAnonEvent("b2bEnrollmentAnonHealthDataConsentFormViewed", {});
  }, []);

  return (
    <Stack spacing={Spacing[4]}>
      <Text>
        <Trans
          i18nKey="form.healthDataConsent.description"
          values={{ partnerName: getDisplayName(partnerInfo) }}
        />
      </Text>
      <MultipleChoiceOptions
        onClick={(optionId: string) => setFieldValue("consentStatus", optionId)}
        options={options}
        selectedItem={values.consentStatus}
      />
      <Input
        {...getFieldProps("consentSignature")}
        error={touched.consentSignature && errors.consentSignature}
        label={t("form.healthDataConsent.consentSignature.label")}
        onChange={(e) => handleInputChange(e, "consentSignature")}
        placeholder={t("form.healthDataConsent.consentSignature.placeholder")}
      />
      <Text size="sm">
        <Trans i18nKey="form.healthDataConsent.disclaimer">
          This authorization lasts for 1 year. Enrollment not conditioned on
          agreement to share data. Data shared may be subject to additional
          disclosures. Consent may be revoked by emailing
          {NoomEmailAddressLink}. Additional details can be found in
          <a
            href="https://www.noom.com/b2b-consumer-health-data-privacy-notice"
            rel="noreferrer"
            target="_blank"
          >
            Noom’s Consumer Health Data Privacy Notice
          </a>
          .
        </Trans>
      </Text>
    </Stack>
  );
};
