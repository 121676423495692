import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Spacing, Stack } from "@noom/wax-component-library";

import {
  HeightWeightForm,
  HeightWeightFormValues,
  HeightWeightSchema,
} from "@/components/forms";
import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";

import { BaseQuestion } from "../core";

const QUESTION_ID = "weight";

declare module "@/contexts" {
  interface SurveyAnswers {
    weight?: number;
  }
}

const WeightQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const initialValues: Pick<HeightWeightFormValues, "weight"> = {
    weight: surveyAnswers[QUESTION_ID],
  };

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionHelperText={t("weight.questionHelperText")}
      questionId={QUESTION_ID}
      questionText={t("weight.questionText")}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onClickNext(values.weight)}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={HeightWeightSchema.pick(["weight"])}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={Spacing[4]}>
              <HeightWeightForm hideHeight />
              <Button
                colorScheme="primary"
                isLoading={isSubmitting}
                size="xl"
                type="submit"
              >
                Next
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
    </BaseQuestion>
  );
};

export const Q_OUTCOMES_WEIGHT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ userProfile }) =>
    userProfile?.currentProgram !== "MOOD" &&
    userProfile?.latestWeightKgs === undefined,
  component: WeightQuestion,
};
