import { LibCategories, waitForConsent } from "@/utils/consent";

import { registerHandler } from "../handler";

function getHealthlineUrl() {
  const queryParams = new URLSearchParams(window.location.search);

  // For the healthline pixel the revenue is hardcoded.
  let revenue =
    window.OneTrust?.getGeolocationData?.().country === "US" ? 40 : 30;

  if (queryParams.get("utm_campaign") === "cpm") {
    revenue = 0;
  }

  const healthLineURL = new URL(
    "https://activation.healthline.com/api/member-offers/postback",
  );
  healthLineURL.searchParams.append("clid", queryParams.get("clid") || "-1");
  healthLineURL.searchParams.append("rev", revenue.toString());

  return healthLineURL.toString();
}

export default function registerHandlers() {
  registerHandler("purchase", () => {
    waitForConsent([LibCategories.targetingCookies]).then(() => {
      fetch(getHealthlineUrl())
        .then((response) => {
          if (!response.ok) {
            throw new Error("Healthline pixel failed");
          }
        })

        .catch(() => {
          /* NOP */
        });
    });
  });
}
