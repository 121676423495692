import Cookies from "js-cookie";

import { loadOneTrust } from "@/utils/vendors/onetrust";

import { getConsent, LibCategories, setConsent } from ".";

const localLoadTime = Date.now();

// Create seedConsent function that sets the groups values in the OptanonConsent cookie,
// per https://community.cookiepro.com/s/article/UUID-1e75fb0d-bb79-2af8-cb85-f905d16f1220?language=en_US
export function seedOneTrustConsent(consent: readonly LibCategories[]) {
  const seedConsent = consent.map((category) => `${category}:1`).join(",");

  Cookies.set("OptanonConsent", `groups=${seedConsent}`, {
    path: "/",
    sameSite: "Lax",
  });
}

export function bootstrapOneTrust() {
  return loadOneTrust().then(({ OneTrust, ActiveGroups }) => {
    try {
      const onetrustConsent = ActiveGroups?.split(/,/)
        ?.filter(Boolean)
        .sort() as LibCategories[];

      // Reset consent state to the one returned by OneTrust
      // Always do this to ensure that incorrect storage is cleared.
      if (ActiveGroups !== null) {
        setConsent(onetrustConsent);
      }
    } catch (err) {
      /* NOP */
    }

    // Keep consent list in sync on any future consent updates.
    OneTrust?.OnConsentChanged((event) => {
      setConsent(event.detail);
    });
  });
}
