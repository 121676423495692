/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";

import {
  Box,
  CompassColor,
  CompassLoader,
  Heading,
  Stack,
  useMediaQuery,
} from "@noom/wax-component-library";

import { ConfettiBox } from "@/components/ConfettiBox";
import { NoomHeader } from "@/components/noom-branding";
import { EnrollmentSuccessType, ProgramOffering } from "@/constants";

type ProgressViewProps = {
  setIsDoneShowingProgress: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProgram: ProgramOffering | null;
  enrollmentSuccessType: EnrollmentSuccessType;
};

type ProgressTexts = {
  inProgress: string;
  complete: string;
};

const enrollmentSuccessTypeProgressTextsMap: Record<
  EnrollmentSuccessType,
  ProgressTexts
> = {
  [EnrollmentSuccessType.TRANSITIONED]: {
    inProgress: "Transitioning your membership...",
    complete: "Account transitioned successfully",
  },
  [EnrollmentSuccessType.CREATED]: {
    inProgress: "Setting up your account...",
    complete: "Account created successfully",
  },
  [EnrollmentSuccessType.UPGRADED]: {
    inProgress: "Setting up your account...",
    complete: "Account created successfully",
  },
};

const medCompleteText = "Taking you to the clinical intake...";

export const ProgressView: React.FC<ProgressViewProps> = ({
  setIsDoneShowingProgress,
  selectedProgram,
  enrollmentSuccessType,
}) => {
  const [isBrowser] = useMediaQuery("(min-width: 1160px)");
  const [isDoneSpinning, setIsDoneSpinning] = useState(false);

  useEffect(() => {
    // The default CompassLoader finishes spinning in 2.5s.
    // The setTimeout for 3.75s will allow it to spin for 1.5 rotations.
    window.setTimeout(() => setIsDoneSpinning(true), 3750);
  }, []);

  useEffect(() => {
    if (!isDoneSpinning) {
      return;
    }

    // This will display the confetti gif for 3s after the loader has finished
    // spinning before proceeding to the Download screen.
    window.setTimeout(() => setIsDoneShowingProgress(true), 3000);
  }, [isDoneSpinning]);

  return (
    <Box>
      {isBrowser && <NoomHeader />}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={{ base: "var(--spacing-xxxl)", lg: "var(--spacing-xxh)" }}
        marginTop={{ base: "var(--spacing-xh)", lg: "0px" }}
      >
        {isDoneSpinning && selectedProgram !== "MED" ? (
          <ConfettiBox />
        ) : (
          <Stack alignItems="center">
            <CompassLoader color={CompassColor.black} size="lg" />
          </Stack>
        )}
        <Heading
          fontSize={{ base: "24px", lg: "28px" }}
          fontWeight={500}
          lineHeight={{ base: "32px", lg: "36px" }}
        >
          {!isDoneSpinning
            ? enrollmentSuccessTypeProgressTextsMap[enrollmentSuccessType]
                .inProgress
            : selectedProgram === "MED"
              ? medCompleteText
              : enrollmentSuccessTypeProgressTextsMap[enrollmentSuccessType]
                  .complete}
        </Heading>
      </Box>
    </Box>
  );
};
