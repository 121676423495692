import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Box } from "@noom/wax-component-library";

import { FullscreenMessage } from "@/components";
import { useTrackEvent } from "@/hooks";
import { NoomEmailAddressLink } from "@/components/noom-branding";

/**
 * Landing page for users that attempt to open deep links on desktop.
 */
export const NotFoundView: React.FC = () => {
  const { trackIdentifiedPageViewed } = useTrackEvent();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const reason = queryParams.get("reason");

  useEffect(() => {
    trackIdentifiedPageViewed("notFound");
  }, []);

  return (
    <FullscreenMessage
      heading={t([
        `pageNotFound.errorMessage.${reason}`,
        "pageNotFound.errorMessage.default",
      ])}
      message={
        <Trans
          i18nKey="pageNotFound.callToAction"
          components={{ supportLink: NoomEmailAddressLink }}
        />
      }
      visual={<Box />}
    />
  );
};
