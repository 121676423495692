import { CompassColor } from "@noom/wax-component-library";

import { defaultFieldStyles } from "../defaultFieldStyles";

export const Select = {
  defaultProps: {
    size: "lg",
    variant: "filled",
  },
  variants: {
    filled: {
      field: {
        ...defaultFieldStyles,
        backgroundColor: CompassColor.offWhite,
        borderColor: CompassColor.offWhite,
        cursor: "pointer",
        _focus: {
          borderColor: CompassColor.gold,
        },
        _hover: {
          backgroundColor: CompassColor.sand01,
          borderColor: CompassColor.sand01,
        },
      },
    },
  },
};
