/**
 * Analytics Data model type
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author todd.blank
 */

import { Curriculum } from "@/constants/Curriculum";

import { EligibilityEnforcementType } from "./Eligibility";

export enum BusinessType {
  UNKNOWN = "UNKNOWN",
  B2B = "B2B",
  B2C = "B2C",
  TEST = "TEST",
}

export type EnrollmentInformation = {
  batchId: number;
  batchName: string;
  customSupportEmail?: string;
  deploymentId: number;
  deploymentName: string;
  eligiblePrograms: Curriculum[];
  partnerId: number;
  partnerName: string;
  triagePrograms: Curriculum[];
  upfrontEligibilityEnforcement: EligibilityEnforcementType;
  businessType: BusinessType;
};
