import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import { Loader, Text } from "@noom/wax-component-library";

import { B2C_PARTNERS, OPT_OUT_DISABLED_PARTNERS } from "@/constants/Partners";
import { useAppContext } from "@/contexts";
import { useGeolocation } from "@/hooks";
import { getDisplayName } from "@/models";

export const GenericConsent: React.FC<{ ctaText: string }> = ({ ctaText }) => {
  const { partnerInfo } = useAppContext();
  const { canOptOut, canOptOutMHMDA, isLoading } = useGeolocation();
  const { t } = useTranslation();

  const getTosHtml = () => {
    const hasCustomCopy = i18next.exists(
      `termsOfService.partners.${partnerInfo?.slug}`,
    );

    const shouldShowGeoCopy =
      canOptOut() &&
      !OPT_OUT_DISABLED_PARTNERS.includes(partnerInfo?.slug || "");

    const yourPrivacyChoices = t("termsOfService.yourPrivacyChoices");

    if (hasCustomCopy) {
      const customTos = t(`termsOfService.partners.${partnerInfo?.slug}`, {
        signUpButton: ctaText,
      });

      if (shouldShowGeoCopy) {
        return `${customTos} ${yourPrivacyChoices}`;
      }

      return customTos;
    }

    const baseTos = t("termsOfService.default", {
      signUpButton: ctaText,
    });

    const noomMayShareHtml = t("termsOfService.noomMayShareInformation", {
      partnerName: getDisplayName(partnerInfo),
    });

    if (shouldShowGeoCopy) {
      return `${baseTos} ${noomMayShareHtml}${yourPrivacyChoices}`;
    }

    return baseTos;
  };

  const getTosHtmlB2C = () => {
    const translationKey = canOptOutMHMDA()
      ? "termsOfService.b2cMHMDA"
      : "termsOfService.b2c";

    return t(translationKey, { signUpButton: ctaText });
  };

  if (isLoading) {
    return <Loader size="xl" colorScheme="primary" />;
  }

  const isB2C = B2C_PARTNERS.includes(partnerInfo?.id as number);

  return (
    <Text
      dangerouslySetInnerHTML={{
        __html: isB2C ? getTosHtmlB2C() : getTosHtml(),
      }}
      size="sm"
    />
  );
};
