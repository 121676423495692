import React from "react";

import { Spacing, Stack } from "@noom/wax-component-library";

import { QuestionOption } from "@/components/survey/Question";

import { CheckboxButton } from "./CheckboxButton";

type CheckboxOptionsProps = {
  checkedItems: string[];
  options: QuestionOption[];
  submitCheckboxQuestion: () => void;
  toggleCheckedState: (optionId: string, currentCheckedState: boolean) => void;
};

export const CheckboxOptions: React.FC<CheckboxOptionsProps> = ({
  checkedItems,
  options,
  submitCheckboxQuestion,
  toggleCheckedState,
}) => (
  <Stack spacing={Spacing[4]}>
    {options.map((option) => {
      const checked = !!checkedItems.find(
        (checkedItem) => checkedItem === option.id,
      );

      return (
        <CheckboxButton
          checked={checked}
          key={option.id}
          onChange={() => toggleCheckedState(option.id, checked)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              submitCheckboxQuestion();
            }
          }}
        >
          {option.text}
        </CheckboxButton>
      );
    })}
  </Stack>
);
