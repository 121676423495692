import { CompassColor, extendCompassTheme } from "@noom/wax-component-library";

import { Button, Input, Select } from "./components";

export const reskinTheme = extendCompassTheme({
  breakpoints: {
    sm: "503px",
    md: "768px",
    lg: "1160px",
    xl: "1440px",
    "2xl": "1820px",
  },
  colors: {
    error: {
      500: CompassColor.cinnamon,
    },
  },
  components: {
    Button,
    FormLabel: {
      baseStyle: {
        fontWeight: 400,
      },
    },
    Input,
    Select,
  },
  styles: {
    global: {
      body: {
        color: CompassColor.black,
      },
      a: {
        // Denim Compass color
        color: "#2457C5",
      },
    },
  },
});
