import React from "react";
import { useTranslation } from "react-i18next";

import { Curriculum } from "@/constants";
import { partnerOffersPrograms } from "@/utils/programs";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { CheckboxQuestion } from "../core";

const QUESTION_ID = "difficulties";

declare module "@/contexts" {
  interface SurveyAnswers {
    difficulties?: string[];
  }
}

const DifficultiesQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const options: QuestionOption[] = [
    { id: "hearing", text: t("difficulties.options.hearing") },
    { id: "vision", text: t("difficulties.options.vision") },
    { id: "cognitive", text: t("difficulties.options.cognitive") },
    { id: "mobility", text: t("difficulties.options.mobility") },
    { id: "selfCare", text: t("difficulties.options.selfCare") },
    {
      id: "independentLiving",
      text: t("difficulties.options.independentLiving"),
    },
    { id: "none", text: t("difficulties.options.none") },
    { id: "preferNotToSay", text: t("difficulties.options.preferNotToSay") },
  ];

  return (
    <CheckboxQuestion
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      options={options}
      questionHelperText={t("difficulties.questionHelperText")}
      questionId={QUESTION_ID}
      questionText={t("difficulties.questionText")}
      selectedItems={surveyAnswers[QUESTION_ID] || []}
    />
  );
};

export const Q_DIFFICULTIES: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    enrollmentInfo,
    partnerInfo,
    selectedProgram,
    surveyAnswers: { dppEligible },
  }) =>
    partnerOffersPrograms(["DPP"], enrollmentInfo, partnerInfo) &&
    selectedProgram !== "MED" &&
    selectedProgram !== "MOOD" &&
    dppEligible === true,
  component: DifficultiesQuestion,
};

export const Q_RETRIAGE_DIFFICULTIES: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, triageCurriculums = [] } = {},
    surveyAnswers: { dppEligible },
  }) =>
    currentProgram === "WEIGHT" &&
    triageCurriculums.includes(Curriculum.DPP) &&
    dppEligible === true,
  component: DifficultiesQuestion,
};
