import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CheckRetriageEligibilityResponseProto } from "@noom/noom-contracts/noom_contracts/backend/partner/service/partner_eligibility_service";
import { B2BEnrollmentEnumsProto_Program } from "@noom/noom-contracts/noom_contracts/events/web/b2b/shared";
import { EntitlementProto } from "@noom/noom-contracts/noom_contracts/usermodel/baselined/entitlements/entitlement";
import { EntitlementsProto } from "@noom/noom-contracts/noom_contracts/usermodel/baselined/entitlements/entitlements";
import { Api } from "@noom/noomscape";

import { Curriculum, ErrorState } from "@/constants";
import { useAppContext } from "@/contexts";
import {
  RetriageEligibilityReason,
  RetriageEligibilityResponse,
  RetriageEligibilityVerificationProperties,
} from "@/models";
import { getAutologinData } from "@/utils/cookies";
import { captureException } from "@/utils/sentry";

export type RetriageEligibilityResult = {
  isEligible: boolean;
  eligibilityReason: RetriageEligibilityReason;
};

// NOTE: (osman) this is really gross and the FE shouldn't need this much knowledge
// about how entitlements work. Also, if the backend adds or updates entitlements
// this may no longer work as expected.
function findCurrentProgram(
  entitlements?: EntitlementsProto,
): B2BEnrollmentEnumsProto_Program {
  if (!entitlements) {
    return "PROGRAM_UNSPECIFIED";
  }

  const currentProgramEntitlement: EntitlementProto | undefined = Object.values(
    entitlements,
  ).find((e: EntitlementProto) => e.entitlementType === "PROGRAM");
  switch (currentProgramEntitlement?.name) {
    case "HEALTHY_WEIGHT":
      return "WEIGHT";
    case "DIABETES_PREVENTION":
      return "DPP";
    case "TELEHEALTH":
      return "MED";
    case "HEALTHY_MIND":
      return "MOOD";
    default:
      return "PROGRAM_UNSPECIFIED";
  }
}

export const useRetriageEligibility = (partnerId?: number) => {
  const { retriageEligibility, setErrorState, setRetriageEligibility } =
    useAppContext();
  const navigate = useNavigate();
  const [isLoadingRetriageEligibility, setIsLoadingRetriageEligibility] =
    useState(false);
  const [checkingAutologinData, setCheckingAutologinData] = useState(false);
  const [verificationProperties, setVerificationProperties] =
    useState<RetriageEligibilityVerificationProperties>();

  const resetRetriageEligibility = () => {
    setRetriageEligibility(undefined);
    setVerificationProperties(undefined);
  };

  useEffect(() => {
    if (retriageEligibility || !partnerId || !verificationProperties) {
      return () => undefined;
    }

    let ignore = false;
    async function fetch() {
      setIsLoadingRetriageEligibility(true);
      try {
        const response: RetriageEligibilityResponse = await Api.call(
          "partner.checkRetriageEligibility",
          Api.api.partner.checkRetriageEligibility,
          {
            partnerId,
            properties: verificationProperties,
          },
        );

        if (ignore) {
          return;
        }
        const currentProgram = findCurrentProgram(response.currentEntitlements);
        setRetriageEligibility({
          currentProgram,
          accessCode: response.accessCode,
          eligibilityReason: response.eligibilityReason,
          triageCurriculums: response.triageCurriculums || [],
        });
      } catch (e) {
        if (ignore) {
          return;
        }
        setErrorState(ErrorState.DEFAULT);
        navigate("/error");
      }
      setIsLoadingRetriageEligibility(false);
    }
    fetch();
    return () => {
      ignore = true;
    };
  }, [verificationProperties, partnerId, retriageEligibility]);

  useEffect(() => {
    if (!partnerId) {
      return;
    }

    const autologinData = getAutologinData();
    if (!autologinData) {
      return;
    }

    const { accessCode, accessToken } = autologinData;

    let ignore = false;
    async function fetch() {
      setCheckingAutologinData(true);
      try {
        const response: CheckRetriageEligibilityResponseProto = await Api.call(
          "partner.eligibility",
          Api.api.partner.eligibility,
          {
            accessCode,
            accessToken,
            partnerId,
          },
        );

        if (ignore) {
          return;
        }
        const currentProgram = findCurrentProgram(response.currentEntitlements);
        setRetriageEligibility({
          didVerifyUsingAutologinData: true,
          currentProgram,
          accessCode,
          eligibilityReason:
            response.retriageEligibilityReason as RetriageEligibilityReason,
          triageCurriculums:
            (response.triageCurriculums.map(
              (curriculumProto) => curriculumProto.curriculum,
            ) as Curriculum[]) || [],
          eligibilityProperties: response.eligibilityFeatures,
          medEligible: response.medEligible,
        });
      } catch (e) {
        if (ignore) {
          return;
        }
        captureException(e);
      }
      setCheckingAutologinData(false);
    }
    fetch();
    return () => {
      ignore = true;
    };
  }, [partnerId]);

  return {
    verificationProperties,
    setVerificationProperties,
    resetRetriageEligibility,
    checkingAutologinData,
    isLoadingRetriageEligibility,
  };
};
