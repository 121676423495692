import axios from "axios";

import { CheckRetriageEligibilityResponseProto } from "@noom/noom-contracts/noom_contracts/backend/partner/service/partner_eligibility_service";
import { Api } from "@noom/noomscape";

Api.register(
  "partner.eligibility",
  async ({
    accessCode,
    accessToken,
    partnerId,
  }: {
    accessCode: string;
    accessToken: string;
    partnerId: number;
  }) => {
    const response = await axios({
      url: `/partners/v1/partners/${partnerId}/eligibility/${accessCode}`,
      urlWithPathParams:
        "/partners/v1/partners/{partnerId}/eligibility/{accessCode}",
      baseURL: window.ENV.SERVER_URL,
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
      method: "GET",
    });

    return response.data as CheckRetriageEligibilityResponseProto;
  },
);
