/* eslint-disable react-hooks/exhaustive-deps */
import { differenceInDays } from "date-fns";
import React, { useEffect } from "react";

import { GetOutcomesUserProfileResponseProto } from "@noom/noom-contracts/noom_contracts/backend/outcomes/service/outcomes_service";
import { Api } from "@noom/noomscape";

import { Survey } from "@/components/survey/Survey";
import QuestionSets from "@/components/survey/questionSets/questionSets";
import { ErrorState, ProgramOffering } from "@/constants";
import { useAppContext } from "@/contexts";
import { useGoto } from "@/hooks";
import { getAutologinData } from "@/utils/cookies";
import { captureException, captureMessage } from "@/utils/sentry";

export const RecurringOutcomesView: React.FC = () => {
  const {
    setSurveyMetadata,
    surveyMetadata = null,
    setErrorState,
    setUserProfile,
    userProfile,
  } = useAppContext();
  const autologinData = getAutologinData();
  const goto = useGoto();

  const onSurveyComplete = async () => {
    window.location.href = "https://content.noom.com/?score=1&close=1";
  };

  useEffect(() => {
    const getUserProfile = async () => {
      if (!autologinData) {
        captureMessage("No autologin data provided for getting user profile");
        goto.error();
        return;
      }

      const { accessCode, accessToken } = autologinData;

      try {
        const {
          activeProgram,
          firstName,
          gender,
          heightCms,
          latestWeightKgs,
          loggedExerciseMinsPastTwoWks,
        }: GetOutcomesUserProfileResponseProto = await Api.call(
          "outcomes.getUserProfile",
          Api.api.outcomes.getUserProfile,
          {
            accessCode,
            accessToken,
          },
        );

        const { programConfigurationSlug = "", timeStarted } =
          activeProgram || {};

        let currentProgram: ProgramOffering = "WEIGHT";
        if (programConfigurationSlug.startsWith("HEALTHY_MIND")) {
          currentProgram = "MOOD";
        } else if (programConfigurationSlug.startsWith("DIABETES_PREVENTION")) {
          currentProgram = "DPP";
        }

        const daysInProgram = differenceInDays(
          Date.now(),
          new Date(timeStarted || Date.now()),
        );

        const surveyPeriod = currentProgram === "MOOD" ? 15 : 30;
        const surveyInstance = Math.floor(daysInProgram / surveyPeriod);
        setSurveyMetadata({
          name: "RECURRING_OUTCOMES",
          instance: `${surveyInstance}`,
        });

        setUserProfile({
          currentProgram,
          daysInProgram,
          firstName,
          gender,
          heightCms,
          latestWeightKgs,
          loggedExerciseMinsPastTwoWks,
        });
      } catch (e) {
        captureException(e);
        setErrorState(ErrorState.DEFAULT);
        goto.error();
      }
    };

    if (!userProfile) {
      getUserProfile();
    }
  }, [autologinData, userProfile]);

  return (
    surveyMetadata &&
    userProfile && (
      <Survey
        baseRoute="/recurring-wellness-survey"
        onSurveyComplete={onSurveyComplete}
        questionSet={QuestionSets.RECURRING_OUTCOMES}
      />
    )
  );
};
