const FLOAT_DISALLOWED_CHARS = ["e", "-"];
const INTEGER_DISALLOWED_CHARS = [".", "e", "-"];

/**
 * Prevents certain keys from being entered to enforce the input to be a
 * positive float. This ensures we can still support backspace, delete, enter,
 * copy/paste, etc. operations.
 */
export const enforcePositiveFloat = (
  e: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (FLOAT_DISALLOWED_CHARS.includes(e.key)) {
    e.preventDefault();
  }
};

/**
 * Prevents certain keys from being entered to enforce the input to be a
 * positive integer. This ensures we can still support backspace, delete,
 * enter, copy/paste, etc. operations.
 */
export const enforcePositiveInt = (
  e: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (INTEGER_DISALLOWED_CHARS.includes(e.key)) {
    e.preventDefault();
  }
};
