import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  CompassColor,
  Image,
  Link,
  Loader,
  Spacing,
  Text,
  useMediaQuery,
} from "@noom/wax-component-library";

import { QuestionDefinition } from "@/components/survey/Question";
import {
  BaseInterstitial,
  BaseOutline,
} from "@/components/survey/questionTypes/core";
import { HealthGoal, ScreenedOutReason } from "@/constants";
import {
  B2C_PARTNERS,
  B2C_SLUG,
  CAREFIRST_PARTNER_ID,
} from "@/constants/Partners";
import { useAppContext } from "@/contexts";
import { useTrackEvent } from "@/hooks";
import { getDisplayName } from "@/models";
import { partnerOffersPrograms } from "@/utils/programs";

// This questions is only reachable via direct navigation. Use caution when updating this ID.
const QUESTION_ID = "ineligible";

type ScreenedOutReasonMoodWhitelist = Extract<
  ScreenedOutReason,
  ScreenedOutReason.PREGNANT | ScreenedOutReason.HEALTH_CONDITION
>;

type ScreenedOutReasonB2CWhitelist = Extract<
  ScreenedOutReason,
  | ScreenedOutReason.PREGNANT
  | ScreenedOutReason.EATING_DISORDER
  | ScreenedOutReason.BMI_TOO_LOW
>;

const SUPPORT_EMAIL_MAP: Record<B2C_SLUG, string> = {
  [B2C_SLUG.PENDULUM_SLUG]: "https://help.pendulumlife.com/",
  [B2C_SLUG.NOOM_TEST_SLUG]: "https://www.noom.com/support/",
  [B2C_SLUG.EXTERNAL_COACH_SLUG]: "https://www.noom.com/support/",
};

const supportButton = (
  <Button
    size="md"
    borderRadius="4px"
    backgroundColor={CompassColor.lagoon}
    colorScheme="info"
    margin="var(--spacing-m) 0"
    _hover={{ backgroundColor: CompassColor.lagoonTint }}
    _focus={{
      boxShadow: "none",
    }}
  />
);

export const ScreenedOut: React.FC = () => {
  const {
    enrollmentInfo,
    partnerInfo,
    screenedOutReason,
    surveyAnswers,
    setSelectedProgram,
    setSurveyAnswers,
  } = useAppContext();

  if (!screenedOutReason) {
    return (
      <BaseOutline>
        <Box m="auto">
          <Loader colorScheme="primary" />
        </Box>
      </BaseOutline>
    );
  }

  const { search } = useLocation();
  const navigate = useNavigate();
  const [showNotInterestedInMood, setShowNotInterestedInMood] = useState(false);
  const { trackAnonEvent, trackIdentifiedEvent } = useTrackEvent();
  useTranslation();

  const isB2C = B2C_PARTNERS.includes(partnerInfo?.id as number);

  const partnerOffersMood = partnerOffersPrograms(
    ["MOOD"],
    enrollmentInfo,
    partnerInfo,
  );

  const proceedWithMood = () => {
    setSurveyAnswers({
      ...surveyAnswers,
      healthGoal: HealthGoal.REDUCING_STRESS_ANXIOUS_FEELINGS,
    });
    navigate({ pathname: "/enrollment/programOverview", search });
  };

  const partnerName = getDisplayName(partnerInfo);
  const supportLink = (
    <Link
      data-cy="support-link"
      href={
        SUPPORT_EMAIL_MAP[partnerInfo?.slug as B2C_SLUG] ||
        "mailto:partnersupport@noom.com"
      }
      isExternal
    />
  );

  const screenedOutTextConfigs: {
    [key in ScreenedOutReason]: React.ReactElement;
  } = {
    [ScreenedOutReason.BMI_TOO_LOW]: (
      <Trans
        i18nKey={`screenedOut.bmiTooLow${
          partnerInfo?.id === CAREFIRST_PARTNER_ID ? "_carefirst" : ""
        }`}
        values={{ partnerName }}
      >
        {partnerInfo?.id === CAREFIRST_PARTNER_ID ? (
          <>
            Unfortunately, because your BMI is not 25 or higher, you are not
            eligible to participate in Noom through {partnerName} at this time.
            <br />
            <br />
            If you'd still like to use Noom, you can sign up and pay for a
            membership at
            <a href="https://www.noom.com">noom.com</a>.
          </>
        ) : (
          <>
            Unfortunately,{" "}
            <strong>
              Noom is not currently designed to support those with a BMI of less
              than 18.
            </strong>
            <br />
            <br />
            We recommend seeking the medical advice of a physician if you are
            interested in losing weight.
            <br />
            <br />
            If you have questions or are concerned you're seeing this message in
            error, please contact your Noom sponsor for support.
          </>
        )}
      </Trans>
    ),
    [ScreenedOutReason.EATING_DISORDER]: (
      <Trans i18nKey="screenedOut.eatingDisorder">
        Unfortunately,{" "}
        <strong>
          Noom is not currently designed to support those with an active eating
          disorder.
        </strong>
        <br />
        <br />
        We recommend seeking the advice of a therapist and/or physician for
        support with your diagnosis.
        <br />
        <br />
        If you have questions or are concerned you're seeing this message in
        error, please contact your Noom sponsor for support.
      </Trans>
    ),
    [ScreenedOutReason.PREGNANT]: (
      <Trans i18nKey="screenedOut.pregnant">
        Unfortunately,{" "}
        <strong>
          Noom is not currently designed to support those who are pregnant.
        </strong>
        <br />
        <br />
        We recommend seeking the medical advice of a physician if you are
        interested in losing weight.
        <br />
        <br />
        If you have questions or are concerned you're seeing this message in
        error, please contact your Noom sponsor for support.
      </Trans>
    ),
    [ScreenedOutReason.HEALTH_CONDITION]: (
      <Trans i18nKey="screenedOut.healthCondition">
        We're sorry -{" "}
        <strong>
          Noom isn't available for people with your current condition.
        </strong>
        <br />
        <br />
        We recommend seeking the medical advice of a physician if you are
        interested in losing weight.
        <br />
        <br />
        If you have questions or are concerned you're seeing this message in
        error, please contact your Noom sponsor for support.
      </Trans>
    ),
  };

  const screenedOutTextConfigsMoodOffered: {
    [key in ScreenedOutReasonMoodWhitelist]: React.ReactElement;
  } = {
    [ScreenedOutReason.PREGNANT]: (
      <Trans i18nKey="screenedOut.pregnant_moodOffered">
        Unfortunately, Noom's weight programs are not currently designed to
        support those who are pregnant.
        <br />
        <br />
        However, you're still eligible for our Noom Mood program! Are you
        interested in working on mental wellness?
      </Trans>
    ),
    [ScreenedOutReason.HEALTH_CONDITION]: (
      <Trans i18nKey="screenedOut.healthCondition_moodOffered">
        We're sorry - Noom isn't available for people with your current
        condition.
        <br />
        <br />
        However, you're still eligible for our Noom Mood program! Are you
        interested in working on mental wellness?
      </Trans>
    ),
  };

  const notInterestedInMoodTextConfigsMoodOffered: {
    [key in ScreenedOutReasonMoodWhitelist]: React.ReactElement;
  } = {
    [ScreenedOutReason.PREGNANT]: (
      <Trans i18nKey="screenedOut.pregnant_notInterestedInMood">
        Got it. We look forward to supporting you after your pregnancy if you're
        still interested in Noom. Until then, we recommend seeking the medical
        advice of a physician if you are interested in losing weight.
      </Trans>
    ),
    [ScreenedOutReason.HEALTH_CONDITION]: (
      <Trans i18nKey="screenedOut.healthCondition_notInterestedInMood">
        Got it. We're sorry Noom's programs can't support you right now. If you
        are still interested in losing weight, we recommend seeking the medical
        advice of a physician.
      </Trans>
    ),
  };

  const screenedOutTextConfigsB2C: {
    [key in ScreenedOutReasonB2CWhitelist]: React.ReactElement;
  } = {
    [ScreenedOutReason.BMI_TOO_LOW]: (
      <Trans
        i18nKey="screenedOut.bmiTooLow_b2c"
        values={{ partnerName }}
        components={{
          supportLink,
          supportButton,
        }}
      />
    ),
    [ScreenedOutReason.EATING_DISORDER]: (
      <Trans
        i18nKey="screenedOut.eatingDisorder_b2c"
        values={{ partnerName }}
        components={{
          supportLink,
          supportButton,
        }}
      />
    ),
    [ScreenedOutReason.PREGNANT]: (
      <Trans
        i18nKey="screenedOut.pregnant_b2c"
        values={{ partnerName }}
        components={{
          supportLink,
          supportButton,
        }}
      />
    ),
  };

  // Not all screened out reasons allow continuing with mood, so fallback if not.
  const screenedOutText: React.ReactElement =
    (partnerOffersMood &&
      screenedOutTextConfigsMoodOffered[
        screenedOutReason as unknown as ScreenedOutReasonMoodWhitelist
      ]) ||
    (isB2C &&
      screenedOutTextConfigsB2C[
        screenedOutReason as unknown as ScreenedOutReasonB2CWhitelist
      ]) ||
    screenedOutTextConfigs[screenedOutReason];

  const notInterestedInMoodText: React.ReactElement | undefined =
    notInterestedInMoodTextConfigsMoodOffered[
      screenedOutReason as unknown as ScreenedOutReasonMoodWhitelist
    ];

  const showMoodCTAs: boolean =
    partnerOffersMood &&
    !!screenedOutTextConfigsMoodOffered[
      screenedOutReason as unknown as ScreenedOutReasonMoodWhitelist
    ] &&
    !showNotInterestedInMood;

  useEffect(() => {
    trackAnonEvent("b2bEnrollmentAnonUserExcluded", {
      exclusionReason: screenedOutReason,
    });

    trackIdentifiedEvent("b2bEnrollmentIdentifiedUserExcluded", {});
  }, [screenedOutReason]);

  useEffect(() => {
    // if the user back navigates here and we show Mood CTAs, we can assume
    // that they were screened out of the metabolic path.
    // Restore state to what would be expected at this stage of the metabolic path
    // (nullify selectedProgram and restore healthGoal)
    if (showMoodCTAs) {
      setSelectedProgram(null);
      setSurveyAnswers({
        ...surveyAnswers,
        healthGoal: HealthGoal.LOSING_WEIGHT,
      });
    }
  }, [showMoodCTAs]);

  const [isBrowser] = useMediaQuery("(min-width: 1160px)");

  return (
    <BaseInterstitial
      backgroundColor={CompassColor.sky}
      onClickBack={() => null}
      onClickNext={() => Promise.resolve()}
      questionId={QUESTION_ID}
      width={isBrowser ? "600px" : undefined}
    >
      <Image
        height={{ base: "48px", lg: "64px" }}
        margin="var(--spacing-l) 0px var(--spacing-s) 0px"
        src="/assets/img/diamond-tarocco.svg"
      />
      <Text>
        {showNotInterestedInMood ? notInterestedInMoodText : screenedOutText}
      </Text>
      {showMoodCTAs && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          marginTop="var(--spacing-xl)"
        >
          <Button
            colorScheme="primary"
            onClick={proceedWithMood}
            padding="unset"
            size="xl"
            whiteSpace="unset"
          >
            Yes, let's work on mental wellness
          </Button>
          <Button
            marginTop={Spacing[4]}
            onClick={() => setShowNotInterestedInMood(true)}
            padding="unset"
            size="xl"
            variant="outline"
            whiteSpace="unset"
          >
            No thanks, I'm not interested
          </Button>
        </Box>
      )}
    </BaseInterstitial>
  );
};

export const Q_SCREENED_OUT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => false,
  component: ScreenedOut,
};
