import React from "react";
import { useTranslation } from "react-i18next";

import {
  Curriculum,
  HealthConditionOptionId,
  ScreenedOutReason,
} from "@/constants";
import { B2C_PARTNERS } from "@/constants/Partners";
import { SurveyAnswer, useAppContext } from "@/contexts";
import { shouldAskQuarterlyQuestion } from "@/utils/survey/outcomes";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { CheckboxQuestion } from "../core";

const QUESTION_ID = "healthConditions";

declare module "@/contexts" {
  interface SurveyAnswers {
    healthConditions?: string[];
  }
}

const HealthConditionsQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { surveyMetadata, selectedProgram, userProfile } = useAppContext();
  const { t } = useTranslation();

  const isMale =
    userProfile?.gender === "MALE" || surveyAnswers?.sex === "male";

  const questionText =
    surveyMetadata?.name === "RECURRING_OUTCOMES"
      ? t("healthConditions.questionText.recurringOutcomes")
      : t("healthConditions.questionText.enrollment");

  const options: (Omit<QuestionOption, "id"> & {
    id: HealthConditionOptionId;
  })[] = [
    {
      id: HealthConditionOptionId.HIGH_BLOOD_PRESSURE,
      text: "High blood pressure",
    },
    {
      id: HealthConditionOptionId.TYPE1_DIABETES,
      text: "Type 1 Diabetes",
    },
    { id: HealthConditionOptionId.TYPE2_DIABETES, text: "Type 2 Diabetes" },
    {
      id: HealthConditionOptionId.DEPRESSION_OR_ANXIETY,
      text: "Depression and/or anxiety",
    },
    {
      id: HealthConditionOptionId.ABNORMAL_CHOLESTEROL,
      text: "High or abnormal cholesterol",
    },
    {
      id: HealthConditionOptionId.OSTEOARTHRITIS,
      text: "Osteoarthritis or other joint/muscle pains",
    },
    {
      id: HealthConditionOptionId.SLEEP_PROBLEMS,
      text: "Sleep apnea or snoring",
    },
    {
      id: HealthConditionOptionId.LIVER_DISEASE,
      text: "Liver disease",
    },
    { id: HealthConditionOptionId.HEART_DISEASE, text: "Heart disease" },
    isMale
      ? {
          id: HealthConditionOptionId.LOW_TESTOSTERONE,
          text: "Low testosterone",
        }
      : {
          id: HealthConditionOptionId.PCOS_OR_FERTILITY_PROBLEMS,
          text: "PCOS or fertility problems",
        },
    {
      id: HealthConditionOptionId.CANCER_UNDER_TREATMENT,
      text: "Active cancer under treatment",
    },
    { id: HealthConditionOptionId.NONE, text: "None" },
  ];

  const screenOutOrProceed = async (answer: SurveyAnswer) => {
    const dppIneligibleConditions = [
      HealthConditionOptionId.TYPE1_DIABETES,
      HealthConditionOptionId.TYPE2_DIABETES,
    ];

    const healthConditions = answer as HealthConditionOptionId[];
    if (
      healthConditions.includes(
        HealthConditionOptionId.CANCER_UNDER_TREATMENT,
      ) &&
      selectedProgram !== "MOOD"
    ) {
      onClickNext(answer, {}, ScreenedOutReason.HEALTH_CONDITION);
      return;
    }

    if (healthConditions.some((hc) => dppIneligibleConditions.includes(hc))) {
      onClickNext(answer, {
        dppEligible: false,
      });
    } else {
      onClickNext(answer);
    }
  };

  return (
    <CheckboxQuestion
      onClickBack={onClickBack}
      onClickNext={screenOutOrProceed}
      options={options}
      questionId={QUESTION_ID}
      questionText={questionText}
      selectedItems={surveyAnswers[QUESTION_ID] || []}
    />
  );
};

export const Q_HEALTH_CONDITIONS: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    partnerInfo,
    screenedOutReason,
    selectedProgram,
    surveyAnswers: { healthConditions },
  }) =>
    // Hide question if the user answered in the metabolic path and was screened out.
    !(
      healthConditions &&
      selectedProgram === "MOOD" &&
      // Should include its own ScreenedOutReason (if applicable) and any that follow this question.
      [ScreenedOutReason.HEALTH_CONDITION].includes(
        screenedOutReason as ScreenedOutReason,
      )
    ) && !B2C_PARTNERS.includes(partnerInfo?.id as number),
  component: HealthConditionsQuestion,
};

export const Q_OUTCOMES_HEALTH_CONDITIONS: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ userProfile }) =>
    shouldAskQuarterlyQuestion(userProfile),
  component: HealthConditionsQuestion,
};

export const Q_RETRIAGE_HEALTH_CONDITIONS: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, triageCurriculums = [] } = {},
  }) =>
    currentProgram === "WEIGHT" && triageCurriculums.includes(Curriculum.DPP),
  component: HealthConditionsQuestion,
};
