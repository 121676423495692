/**
 * Endpoint for creating an account
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import axios from "axios";

import { Api } from "@noom/noomscape";

import { User } from "@/models";

export type ResponseData = {
  accessCode: string;
  wasCreated: boolean;
  businessType: string;
  pricingType: string;
  credentialsType: "EMAIL_AND_PASSWORD" | "FACEBOOK" | "GOOGLE";
};

export type AccountErrorResponse = {
  message: string;
};

Api.register(
  "account.create",
  async ({ upid, userData }: { upid: string; userData: User }) => {
    const response = await axios({
      url: "/account/users",
      urlWithPathParams: "/account/users",
      baseURL: window.ENV.PRODUCT_API_URL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { ...userData, upid },
    });

    return response.data;
  },
);
