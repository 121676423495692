import React from "react";

import { Curriculum } from "@/constants";
import { partnerOffersPrograms } from "@/utils/programs";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { CheckboxQuestion } from "../core";

const QUESTION_ID = "prediabetesHistory";

declare module "@/contexts" {
  interface SurveyAnswers {
    prediabetesHistory?: string[];
  }
}

const options: QuestionOption[] = [
  { id: "prediabetes", text: "Been diagnosed with prediabetes" },
  { id: "a1c", text: "Had an A1c level between 5.7 - 6.4" },
  {
    id: "fastingBloodSugarLevel",
    text: "Had a fasting blood sugar level between 100-125 mg/dl",
  },
  { id: "none", text: "None of the above" },
];

const PrediabetesQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => (
  <CheckboxQuestion
    onClickBack={onClickBack}
    onClickNext={onClickNext}
    options={options}
    questionId={QUESTION_ID}
    questionText="In the last 12 months, have you ever..."
    selectedItems={surveyAnswers[QUESTION_ID] || []}
  />
);

export const Q_PREDIABETES_HISTORY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    enrollmentInfo,
    partnerInfo,
    selectedProgram,
    surveyAnswers: { dppEligible },
  }) =>
    partnerOffersPrograms(["DPP"], enrollmentInfo, partnerInfo) &&
    selectedProgram !== "MED" &&
    selectedProgram !== "MOOD" &&
    dppEligible !== false,
  component: PrediabetesQuestion,
};

export const Q_RETRIAGE_PREDIABETES_HISTORY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, triageCurriculums = [] } = {},
    surveyAnswers: { dppEligible },
  }) =>
    currentProgram === "WEIGHT" &&
    triageCurriculums.includes(Curriculum.DPP) &&
    dppEligible !== false,
  component: PrediabetesQuestion,
};
