import * as Sentry from "@sentry/react";

import { AxiosError } from "axios";

let sentryInitialized = false;

const isNetworkError = (error: Sentry.Event): boolean => {
  const exception = error.exception?.values?.[0];
  const value = exception?.value || "";

  // Ignore Apple "Load Failed" and "Failed to fetch" errors - they could be any network error
  // https://sentry.io/answers/load-failed-javascript/
  // https://sentry.io/answers/failed-to-fetch-javascript/
  const ignoredTypeErrors = ["Load failed", "Failed to fetch"];
  if (exception?.type === "TypeError" && ignoredTypeErrors.includes(value)) {
    return true;
  }
  return false;
};

const transformAxiosEvent = (event: Sentry.Event, hint: Sentry.EventHint) => {
  const err = hint.originalException as AxiosError;
  if (!err?.isAxiosError) {
    return event;
  }
  return {
    ...event,
    fingerprint: [err.name, err.message, err.config?.urlWithPathParams || ""],
    transaction: err.config?.urlWithPathParams,
    extra: {
      ...event.extra,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: (err.response?.data as any)?.errorMessage,
    },
  };
};

const beforeSend = (
  event: Sentry.Event,
  hint: Sentry.EventHint,
): Sentry.Event | null => {
  if (window.ENV.NOOM_ENVIRONMENT === "development") {
    // eslint-disable-next-line no-console
    console.log("Sentry Event (not sent)", event);
    return null;
  }
  if (isNetworkError(event)) {
    return null;
  }

  return transformAxiosEvent(event, hint);
};

export function initializeSentry() {
  if (sentryInitialized) {
    return;
  }
  sentryInitialized = true;

  Sentry.init({
    beforeSend,
    release: process.env.HIVE_SHA || "dev",
    dsn: "https://60703e580c164301b457b00b9df7c1ae@o1000882.ingest.sentry.io/6528389",
    environment: window.ENV.NOOM_ENVIRONMENT,
    ignoreErrors: [
      "__gCrWeb.learningToolsRuntimeBridge.raiseMessageFromHost",
      "AbortError: The play() request was interrupted because video-only background media was paused to save power.",
      "ceCurrentVideo.currentTime",
      "ChunkLoadError",
      "R.tracking_details",
      "Network Error",
      "Failed to load script",
      "Request aborted",
      // These two are errors experienced by Microsoft crawlers as part of Outlook Safe Links checking.
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
    ],
    denyUrls: [
      "cookielaw.org",
      "googleapis.com", // Errors in minified Google Translate scripts
    ],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ["error"] }),
    ],
  });
}

export function captureException(
  err: Error | unknown,
  extra?: Record<string, unknown>,
) {
  initializeSentry();
  Sentry.captureException(err, { extra });
}

export function captureMessage(message: string) {
  initializeSentry();
  Sentry.captureMessage(message);
}
