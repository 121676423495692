import React from "react";

import { Curriculum } from "@/constants";
import { partnerOffersPrograms } from "@/utils/programs";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "education";

declare module "@/contexts" {
  interface SurveyAnswers {
    education?: string;
  }
}

const options: QuestionOption[] = [
  { id: "lessThanHighSchool", text: "Less than high school diploma" },
  { id: "highSchool", text: "High school diploma or GED" },
  { id: "collegeNoDegree", text: "Some college, but no degree" },
  { id: "collegeDegree", text: "At least a 4 year college degree" },
  { id: "preferNotToSay", text: "Prefer not to say" },
];

const EducationQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => (
  <MultipleChoiceQuestion
    onClickBack={onClickBack}
    onClickNext={onClickNext}
    options={options}
    questionId={QUESTION_ID}
    questionText="What is the highest level of school you've completed?"
    selectedItem={surveyAnswers[QUESTION_ID] || ""}
  />
);

export const Q_EDUCATION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    enrollmentInfo,
    partnerInfo,
    selectedProgram,
    surveyAnswers: { dppEligible },
  }) =>
    partnerOffersPrograms(["DPP"], enrollmentInfo, partnerInfo) &&
    selectedProgram !== "MED" &&
    selectedProgram !== "MOOD" &&
    dppEligible === true,
  component: EducationQuestion,
};

export const Q_RETRIAGE_EDUCATION: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, triageCurriculums = [] } = {},
    surveyAnswers: { dppEligible },
  }) =>
    currentProgram === "WEIGHT" &&
    triageCurriculums.includes(Curriculum.DPP) &&
    dppEligible === true,
  component: EducationQuestion,
};
