import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  Heading,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import { BaseInterstitial } from "@/components/survey/questionTypes/core";

type BaseSuccessProps = {
  onClickNext: () => void;
  headingText: string;
  bodyContent: ReactNode | string;
  questionId: string;
  ctaText: string;
};

export const BaseSuccess: React.FC<BaseSuccessProps> = ({
  onClickNext,
  headingText,
  bodyContent,
  ctaText,
  questionId,
}) => {
  useTranslation();

  return (
    <BaseInterstitial
      backgroundColor={CompassColor.sky}
      onClickBack={() => null}
      onClickNext={() => Promise.resolve()}
      questionId={questionId}
      width="550px"
    >
      <Stack spacing={Spacing[4]} alignItems="stretch">
        <Heading fontSize="24px" fontWeight={500} lineHeight="32px">
          {headingText}
        </Heading>
        <Text>{bodyContent}</Text>
        <Image
          alt=""
          margin="auto"
          src="/assets/img/thumbs-up_clear.png"
          mt="20px"
          mb="40px"
        />
      </Stack>
      {ctaText && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          marginTop="var(--spacing-xl)"
        >
          <Button
            colorScheme="primary"
            onClick={onClickNext}
            padding="unset"
            size="xl"
            whiteSpace="unset"
          >
            {ctaText}
          </Button>
        </Box>
      )}
    </BaseInterstitial>
  );
};
