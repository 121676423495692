import Cookies from "js-cookie";

import { SurveyAnswers } from "@/contexts/app-context";
import { captureException } from "@/utils/sentry";

export type AutologinData = {
  accessCode: string;
  accessToken: string;
};

/**
 * Attempts to read autologin_data cookie set by the mobile app in web views.
 * If its not present or an error occurs, return undefined and let the caller handle it.
 */
export const getAutologinData = (): AutologinData | undefined => {
  try {
    const autologinDataCookie = Cookies.get("autologin_data");

    if (!autologinDataCookie) {
      return undefined;
    }
    const autologinData: AutologinData = JSON.parse(autologinDataCookie);

    if (!autologinData.accessCode || !autologinData.accessToken) {
      throw Error("autologin_data cookie is missing expected data");
    }
    return autologinData;
  } catch (error) {
    captureException(error);
  }
  return undefined;
};

const SURVEY_ANSWER_COOKIE_NAME = "noom_b2b_wea_sa";

// IMPORTANT - Before expanding the allow list, we need to ensure the survey answers will be properly parsed in getSurveyAnswersFromCookie.
// Due to type conversions of objects that don't have corresponding primitive value (ie Date)
// we need to make sure we recreate the expected object/type before returning the surveyAnswer object.
type SURVEY_ANSWER_ALLOW_LIST = "eligibilityCheck" | "createAccount";

/**
 * Stores survey answers (from SURVEY_ANSWER_ALLOW_LIST) in a cookie.
 * @param surveyAnswers
 */
export const setSurveyAnswerCookie = (
  surveyAnswers: Pick<SurveyAnswers, SURVEY_ANSWER_ALLOW_LIST>,
) => {
  Cookies.set(SURVEY_ANSWER_COOKIE_NAME, JSON.stringify(surveyAnswers), {
    expires: 1,
  });
};

export const getSurveyAnswersFromCookie = (): SurveyAnswers | undefined => {
  const surveyAnswerCookie = Cookies.get(SURVEY_ANSWER_COOKIE_NAME);
  try {
    if (surveyAnswerCookie) {
      let surveyAnswersFromCookie: SurveyAnswers =
        JSON.parse(surveyAnswerCookie);

      // dob is actually a string here due to surveyAnswersFromCookie
      // having been stringified and re-parsed so convert it back to a Date.
      if (surveyAnswersFromCookie.eligibilityCheck?.dob) {
        surveyAnswersFromCookie = {
          ...surveyAnswersFromCookie,
          eligibilityCheck: {
            ...surveyAnswersFromCookie.eligibilityCheck,
            dob: new Date(surveyAnswersFromCookie.eligibilityCheck.dob),
          },
        };
      }
      return surveyAnswersFromCookie;
    }
  } catch (error) {
    captureException(error);
  }
  return undefined;
};
