import React from "react";

import { Curriculum } from "@/constants";
import { SurveyAnswer } from "@/contexts";
import { calculateDppRiskScore } from "@/utils/dpp";
import { partnerOffersPrograms } from "@/utils/programs";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "physicalActivity";

declare module "@/contexts" {
  interface SurveyAnswers {
    physicalActivity?: string;
    riskScore?: number;
    dppEligible?: boolean;
  }
}

const options: QuestionOption[] = [
  { id: "yes", text: "Yes" },
  { id: "no", text: "No" },
];

const PhysicalActivityQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const calculateDppRiskAndProceed = async (answer: SurveyAnswer) => {
    const riskScore = calculateDppRiskScore({
      ...surveyAnswers,
      [QUESTION_ID]: answer as string,
    });

    onClickNext(answer, {
      riskScore,
      dppEligible: surveyAnswers.dppEligible !== false && riskScore >= 5,
    });
  };

  return (
    <MultipleChoiceQuestion
      onClickBack={onClickBack}
      onClickNext={calculateDppRiskAndProceed}
      options={options}
      questionId={QUESTION_ID}
      questionText="Are you physically active?"
      selectedItem={surveyAnswers[QUESTION_ID] || ""}
    />
  );
};

export const Q_PHYSICAL_ACTIVITY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    enrollmentInfo,
    partnerInfo,
    selectedProgram,
    surveyAnswers: { dppEligible },
  }) =>
    partnerOffersPrograms(["DPP"], enrollmentInfo, partnerInfo) &&
    selectedProgram !== "MED" &&
    selectedProgram !== "MOOD" &&
    dppEligible !== false,
  component: PhysicalActivityQuestion,
};

export const Q_RETRIAGE_PHYSICAL_ACTIVITY: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, triageCurriculums = [] } = {},
    surveyAnswers: { dppEligible },
  }) =>
    currentProgram === "WEIGHT" &&
    triageCurriculums.includes(Curriculum.DPP) &&
    dppEligible !== false,
  component: PhysicalActivityQuestion,
};
