import React from "react";

import { Select } from "@noom/wax-component-library";

import {
  NOOM_SUPPORTED_ENGLISH_LOCALES,
  NOOM_SUPPORTED_LANGUAGES,
} from "@/constants";

type DropdownOption = {
  label: string;
  value: string;
};

type PreferredLanguageSelectorProps = {
  helper?: string;
  label?: string;
  name?: string;
  supportedLocales: string[];
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export const getDefaultLanguageValue = (): string => {
  const browserLanguages = window.navigator.languages;

  for (let i = 0; i < browserLanguages.length; i += 1) {
    const browserLanguage = browserLanguages[i];
    if (NOOM_SUPPORTED_LANGUAGES.includes(browserLanguage)) {
      return browserLanguage;
    }

    if (NOOM_SUPPORTED_LANGUAGES.includes(browserLanguage.slice(0, 2))) {
      return browserLanguage.slice(0, 2);
    }
  }

  return "en";
};

export const PreferredLanguageSelector: React.FC<
  PreferredLanguageSelectorProps
> = ({ supportedLocales, ...rest }) => {
  const browserLanguages = window.navigator.languages;

  const englishLanguageValue =
    browserLanguages.find((browserLanguage) =>
      NOOM_SUPPORTED_ENGLISH_LOCALES.includes(browserLanguage),
    ) || "en";

  const allLanguageOptions = [
    {
      label: "English",
      value: englishLanguageValue,
    },
    {
      label: "Spanish",
      value: "es",
    },
    {
      label: "German",
      value: "de",
    },
    {
      label: "Korean",
      value: "ko",
    },
  ];

  const languageOptions: DropdownOption[] = allLanguageOptions.filter(
    ({ value }) =>
      supportedLocales.find((language) => language === value.slice(0, 2)) ||
      false,
  );

  return (
    <Select
      helper="This is the language that your program will be delivered in."
      label="Preferred Language"
      {...rest}
    >
      {languageOptions.map(({ label, value }: DropdownOption) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Select>
  );
};
