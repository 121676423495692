import { Curriculum, ProgramOffering } from "@/constants";
import { EnrollmentInformation, Partner } from "@/models";

const CURRICULUM_TO_PROGRAM_OFFERING_MAP: Record<
  Curriculum,
  ProgramOffering | undefined
> = {
  [Curriculum.CURRICULUM_UNSPECIFIED]: undefined,
  [Curriculum.DPP]: "DPP",
  [Curriculum.HM]: "MOOD",
  [Curriculum.HW]: "WEIGHT",
};

export const PROGRAM_OFFERING_TO_CURRICULUM_MAP: Record<
  ProgramOffering,
  Curriculum
> = {
  DPP: Curriculum.DPP,
  MED: Curriculum.HW,
  MOOD: Curriculum.HM,
  WEIGHT: Curriculum.HW,
};

/**
 * Gets the curriculum associated with a program.
 *
 * @param {ProgramOffering | null} program
 * @returns {Curriculum}
 */
export const getCurriculumFromProgramOffering = (
  program: ProgramOffering | null,
): Curriculum => {
  if (program === null) {
    return Curriculum.CURRICULUM_UNSPECIFIED;
  }

  return PROGRAM_OFFERING_TO_CURRICULUM_MAP[program];
};

/**
 * Determines if a partner offers all of the given programs - used to know if
 * certain pages or features should be gated behind those programs.
 *
 * @param programs List of programs to check against
 * @param enrollmentInfo Enrollment info of the UPID - used to check what
 * programs the UPID can be enrolled into
 * @param partnerInfo Information about the partner - used to check if certain
 * configs are enabled (e.g. if Noom Med is offered)
 * @returns True if the given programs are offered, false otherwise
 */
export const partnerOffersPrograms = (
  programs: ProgramOffering[],
  enrollmentInfo: EnrollmentInformation | undefined,
  partnerInfo: Partner | null,
): boolean => {
  const { eligiblePrograms = [], triagePrograms = [] } = enrollmentInfo || {};
  const { configs: { OFFERS_MED = false } = {} } = partnerInfo || {};

  const partnerProgramOfferings = [...eligiblePrograms, ...triagePrograms]
    .map((curriculum) => CURRICULUM_TO_PROGRAM_OFFERING_MAP[curriculum])
    .filter((program) => program) as ProgramOffering[];

  if (OFFERS_MED) {
    partnerProgramOfferings.push("MED");
  }

  return programs.every((program) => partnerProgramOfferings.includes(program));
};
