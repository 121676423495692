import Cookies from "js-cookie";

import { NoomB2BEnrollmentIdentifiedEventProto } from "@noom/noom-contracts/noom_contracts/events/web/b2b/enrollment_identified";

import { OPT_OUT_STATES } from "@/constants";
import { bootstrapOneTrust } from "@/utils/consent/one-trust";
import { bootstrapOptOut, OPT_OUT_COOKIE_NAME } from "@/utils/consent/opt-out";
import { isEU } from "@/utils/userSegment";
import { loadOneTrust } from "@/utils/vendors/onetrust";

export enum LibCategories {
  strictlyNecessary = "C0001",
  performanceCookies = "C0002",
  functionalCookies = "C0003",
  targetingCookies = "C0004",
  socialMediaCookies = "C0005",
}

const waiting = new Set<() => void>();

const CONSENT_KEY = "noom_consent";
let consent: LibCategories[] = (() => {
  try {
    return JSON.parse(localStorage.getItem(CONSENT_KEY) || "");
  } catch (err) {
    return undefined;
  }
})();

/** Which consent framework should be applied for a given user. */
export function consentFramework() {
  const country = window.OneTrust?.getGeolocationData?.().country || "";
  const state = window.OneTrust?.getGeolocationData?.().state || "";
  if (isEU(country)) {
    return "gdpr";
  }
  if (country === "US") {
    if (
      OPT_OUT_STATES.includes(state) ||
      // honor past opt-out cookie set even if the user is no longer in the affected
      // jurisdiction. does not cover the case where we need to check the submitted
      // email to determine opt-out status.
      Cookies.get(OPT_OUT_COOKIE_NAME) === "true"
    ) {
      return "optOut";
    }
    // cookie banner should be shown to all US users even if not in an opt-out state.
    return "cookieBanner";
  }

  return "none";
}

// eslint-disable-next-line no-underscore-dangle
let _trackingCallback: <
  T extends keyof Omit<
    NoomB2BEnrollmentIdentifiedEventProto,
    "sharedProperties"
  >,
>(
  eventType: T,
  payload: NoomB2BEnrollmentIdentifiedEventProto[T],
) => void;

export function setConsent(categories: LibCategories[]) {
  consent = categories;

  // Store consent in local storage for immediate init on future visits.
  // This lets us short-circuit lib loading once user has consented.
  try {
    localStorage.setItem(CONSENT_KEY, JSON.stringify(categories));
  } catch {
    // no-op, as this is a non-essential optimization
  }

  _trackingCallback?.("b2bEnrollmentIdentifiedConsentSet", {
    categories,
  });

  waiting.forEach((waiter) => waiter());
}

let consentLoader: Promise<void> | undefined;

export async function bootstrapConsent(
  trackingCallback: typeof _trackingCallback,
) {
  if (!_trackingCallback) {
    _trackingCallback = trackingCallback;
  }

  if (consentLoader) {
    return consentLoader;
  }

  await loadOneTrust();

  if (consentFramework() === "optOut") {
    consentLoader = bootstrapOptOut();
  } else if (["gdpr", "cookieBanner"].includes(consentFramework())) {
    // cookie banner should be shown to all EU and US users, but we are keeping them
    // in separate frameworks in anticipation of future legal divergences.
    consentLoader = bootstrapOneTrust();
  } else {
    // No consent framework, grant implicit consent
    setConsent([
      LibCategories.strictlyNecessary,
      LibCategories.performanceCookies,
      LibCategories.functionalCookies,
      LibCategories.targetingCookies,
      LibCategories.socialMediaCookies,
    ]);
    consentLoader = Promise.resolve();
  }
  return consentLoader;
}

export function getConsent(): readonly LibCategories[] {
  return consent;
}

/**
 * Checks if all of the given categories currently have consent.
 */
export function allowCategories(categories: LibCategories[]) {
  return categories
    .filter((category) => category !== LibCategories.strictlyNecessary)
    .every((category) => consent?.includes(category));
}

export function waitForConsent(categories: LibCategories[]): Promise<void> {
  return new Promise((resolve) => {
    if (allowCategories(categories)) {
      resolve();
    } else {
      waiting.add(function waiter() {
        if (allowCategories(categories)) {
          waiting.delete(waiter);
          resolve();
        }
      });
    }
  });
}
