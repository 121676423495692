import React, { useEffect } from "react";

import {
  Box,
  Button,
  CompassColor,
  Heading,
  Image,
  Spacing,
  Stack,
  StackItem,
  Text,
  useMediaQuery,
} from "@noom/wax-component-library";

import { QuestionComponentProps } from "@/components/survey/Question";
import { useTrackEvent } from "@/hooks";

import { BaseOutline } from "./BaseOutline";

const DEFAULT_AUTO_ADVANCE_MS = 6000;

type BaseInterstitialProps = {
  autoAdvance?: boolean;
  autoAdvanceMs?: number;
  backgroundColor?: CompassColor;
  centerChildren?: boolean;
  children?: React.ReactNode;
  ctaText?: string;
  footerText?: string;
  headerText?: string;
  helperText?: string;
  imageAlt?: string;
  imageSrc?: string;
  onClickBack: () => void;
  onClickNext: QuestionComponentProps["onClickNext"];
  questionId: string;
  showBackButton?: boolean;
  width?: string;
};

export const BaseInterstitial: React.FC<BaseInterstitialProps> = ({
  autoAdvance = false,
  autoAdvanceMs = DEFAULT_AUTO_ADVANCE_MS,
  backgroundColor,
  centerChildren = false,
  children,
  ctaText,
  footerText,
  headerText,
  helperText,
  imageAlt,
  imageSrc,
  onClickBack,
  onClickNext,
  questionId,
  showBackButton = false,
  width = "400px",
}) => {
  const [isBrowser] = useMediaQuery("(min-width: 1160px)");
  const { trackAnonPageViewed } = useTrackEvent();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (autoAdvance) {
      timeoutId = setTimeout(() => onClickNext(null), autoAdvanceMs);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    trackAnonPageViewed(questionId);
  }, [questionId]);

  return (
    <BaseOutline backgroundColor={backgroundColor}>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={centerChildren ? 1 : 0}
        padding="var(--spacing-m)"
      >
        {showBackButton && (
          <button type="button" tabIndex={-1}>
            <Image
              alt="Back"
              cursor="pointer"
              onClick={onClickBack}
              paddingBottom="var(--spacing-m)"
              src="/assets/img/left-arrow.svg"
            />
          </button>
        )}
        <Stack
          margin="auto"
          maxWidth={{
            base: "400px",
            lg: width,
          }}
          padding={
            !showBackButton
              ? {
                  lg: "var(--spacing-xxl) 0",
                }
              : undefined
          }
          spacing={isBrowser ? Spacing[8] : Spacing[4]}
        >
          {(headerText || helperText) && (
            <Stack spacing={Spacing[4]}>
              {headerText && (
                <Heading fontSize="24px" fontWeight={500} lineHeight="32px">
                  {headerText}
                </Heading>
              )}
              {helperText && <Text>{helperText}</Text>}
            </Stack>
          )}
          {imageSrc ? (
            <Image
              alt={imageAlt}
              margin="auto"
              src={imageSrc}
              width="fit-content"
            />
          ) : (
            children
          )}
          {ctaText && (
            <StackItem display="flex" justifyContent="center">
              <Button
                autoFocus
                colorScheme="primary"
                marginBottom="var(--spacing-m)"
                onClick={() => onClickNext(null)}
                size="xl"
                type="submit"
                _focus={{
                  boxShadow: "none",
                }}
              >
                {ctaText}
              </Button>
            </StackItem>
          )}
        </Stack>
        {footerText && (
          <Box
            bottom={0}
            paddingBottom={isBrowser ? "var(--spacing-m)" : "0px"}
            position={isBrowser ? "absolute" : "static"}
          >
            <Text color={CompassColor.black} fontSize="14px" lineHeight="18px">
              {footerText}
            </Text>
          </Box>
        )}
      </Box>
    </BaseOutline>
  );
};
