import { PHQ_OPTIONS } from "@/constants";
import { SurveyAnswers } from "@/contexts";

export const calculatePhqScore = ({
  phq1,
  phq2,
  phq3,
  phq4,
}: SurveyAnswers): number => {
  const phqAnswers = [phq1, phq2, phq3, phq4];
  if (phqAnswers.some((phqAnswer) => !phqAnswer)) {
    throw Error("PHQ survey answers expected");
  }

  return phqAnswers.reduce((acc, phqAnswer) => {
    const phqOption = PHQ_OPTIONS.find((option) => option.id === phqAnswer);
    if (!phqOption) {
      throw Error("encountered unexpected PHQ survey answer");
    }
    return acc + phqOption.weight;
  }, 0);
};
