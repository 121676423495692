import { LibCategories } from "@/utils/consent";
import { loadScript } from "@/utils/scriptLoader";

declare const gtag: any;
declare const dataLayer: any;
declare const window: Window & {
  gtag: any;
  dataLayer: any;
};

window.dataLayer = window.dataLayer || [];
window.gtag = function () {
  dataLayer.push(arguments);
};
gtag("js", new Date());

export function loadGA() {
  gtag("config", "G-6R0EQ2V6YH");
  return Promise.all([
    loadScript("https://www.googletagmanager.com/gtag/js?id=G-6R0EQ2V6YH", [
      LibCategories.targetingCookies,
    ]),
  ]).catch(() => {
    // no-op
  });
}

export function loadAdWords() {
  gtag("config", "AW-783925782");
  return Promise.all([
    // <!-- Global site tag (gtag.js) - Google AdWords: AW-783925782 -->
    loadScript("https://www.googletagmanager.com/gtag/js?id=AW-783925782", [
      LibCategories.targetingCookies,
    ]),
  ]);
}
