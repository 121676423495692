import { AxiosError } from "axios";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";

import { Api } from "@noom/noomscape";

import { AccountErrorResponse } from "@/api/account/create";
import {
  DEFAULT_ADDRESS,
  EnrollmentSuccessType,
  ErrorCode,
  ErrorState,
  ProgramOffering,
  ResultType,
} from "@/constants";
import { isDoNotTrackPartner } from "@/constants/Partners";
import { useAppContext } from "@/contexts";
import { Address, EnrollmentInformation, getDisplayName, User } from "@/models";
import { trackBuyflowConversion } from "@/utils/ConversionTracker";
import { getExperimentProperties } from "@/utils/experiment";
import { getCurriculumFromProgramOffering } from "@/utils/programs";
import { captureException } from "@/utils/sentry";
import { isBuyflowTraffic } from "@/utils/userSegment";

import { useEnrollMed } from "./useEnrollMed";
import { useGoto } from "./useGoto";
import { useTrackEvent } from "./useTrackEvent";

export const useCreateAccount = () => {
  const {
    b2bIdentifiedUserId,
    partnerInfo,
    setEnrollmentInfo,
    setErrorState,
    setUserData,
  } = useAppContext();
  const { enrollMed } = useEnrollMed();
  const goto = useGoto();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { linkB2BIdentity, trackAnonEvent, trackIdentifiedEvent } =
    useTrackEvent();

  const validateAndGetCorrectedAddress = async (
    address: Address,
  ): Promise<Address> =>
    Api.call("address.validate", Api.api.address.validate, {
      address,
    })
      .then((_correctedAddress: Address) => {
        // The address returned by the address validation service omits null address fields.
        // We need to include all address fields (even if they are empty) when saving to the backend.
        const correctedAddress = {
          ...DEFAULT_ADDRESS,
          ..._correctedAddress,
        };
        return correctedAddress;
      })
      .catch((error: AxiosError<WebApiErrorResponse>) => {
        return address;
      });

  const createAccount = async (
    upid: string,
    user: User,
    selectedProgram: ProgramOffering | null,
  ): Promise<string | undefined> => {
    const userData = { ...user };
    const curriculum = getCurriculumFromProgramOffering(selectedProgram);

    try {
      // Currently, we don't know which curriculum associated with a UPID.
      // As a workaround, always update the mapping if there are multiple curriculum choices.
      if (selectedProgram !== null) {
        await Api.call(
          "upid.updateUpidCurriculumMapping",
          Api.api.upid.updateUpidCurriculumMapping,
          {
            curriculum,
            upid,
          },
        );
      }

      userData.extras.address = await validateAndGetCorrectedAddress(
        userData.extras.address,
      );

      userData.extras = {
        ...userData.extras,
        experimentAssignments: getExperimentProperties(b2bIdentifiedUserId),
        partnerName: getDisplayName(partnerInfo),
      };

      // We need to get updated enrollment information in case the upid was moved to a new batch
      const updatedEnrollmentInfo: EnrollmentInformation = await Api.call(
        "upid.getEnrollmentInformation",
        Api.api.upid.getEnrollmentInformation,
        {
          upid,
        },
      );
      setEnrollmentInfo(updatedEnrollmentInfo);

      // cache userdata to enable use elsewhere (ie transitioning B2C->B2B)
      setUserData(userData);
      const { accessCode, wasCreated } = await Api.call(
        "account.create",
        Api.api.account.create,
        {
          upid,
          userData,
        },
      );

      if (selectedProgram === "MED") {
        await enrollMed(accessCode, userData);
      }

      // Set cookie expiration to 1 hour
      Cookies.set("lastEnrolledUpid", upid, { expires: 1 / 24 });

      linkB2BIdentity(userData.email, upid, accessCode);

      trackIdentifiedEvent("b2bEnrollmentIdentifiedSignupSubmitted", {
        b2bProgram: selectedProgram || "PROGRAM_UNSPECIFIED",
        b2bSignupResult: ResultType.SUCCESS,
        b2cTransitionedAccount: false,
        freeAccountExists: !wasCreated,
        upid,
      });

      trackAnonEvent("b2bEnrollmentAnonSignupSubmitted", {
        b2bProgram: selectedProgram || "PROGRAM_UNSPECIFIED",
        b2bSignupResult: ResultType.SUCCESS,
        b2cTransitionedAccount: false,
        freeAccountExists: !wasCreated,
      });

      const growthUserId = Cookies.get("_userId");
      if (
        partnerInfo &&
        !isDoNotTrackPartner(partnerInfo) &&
        isBuyflowTraffic() &&
        growthUserId
      ) {
        trackBuyflowConversion(curriculum, userData.email, growthUserId);
      }

      const successType = wasCreated
        ? EnrollmentSuccessType.CREATED
        : EnrollmentSuccessType.UPGRADED;
      goto.success(successType);
      return accessCode;
    } catch (e: unknown) {
      // Existing accounts have a dedicated error message
      const errorMessage = (e as AxiosError<AccountErrorResponse>)?.response
        ?.data?.message;

      const existingB2BAccount =
        errorMessage === "ALREADY_ENROLLED_IN_B2B_PROGRAM";
      const existingB2CAccount = errorMessage === "NON_FREE_ACCOUNT_EXISTS";
      const pendingDeleteRequest = errorMessage === "PENDING_DELETION_REQUEST";
      const claimed = errorMessage === "Invalid UPID parameter";
      const isIneligible = errorMessage === "B2B_INELIGIBLE";

      const medEnrollmentFailed =
        (e as Error)?.message === ErrorCode.MED_ENROLLMENT_FAILED;

      if (existingB2CAccount) {
        navigate({
          pathname: "/transition",
          search,
        });
        return undefined;
      }

      if (isIneligible) {
        throw new Error("B2B_INELIGIBLE");
      }

      let resultType = ResultType.ERROR;

      if (existingB2BAccount) {
        resultType = ResultType.ACCOUNT_EXISTS;
        setErrorState(ErrorState.ACCOUNT_EXISTS);
      } else if (claimed) {
        resultType = ResultType.CODE_CLAIMED;
        setErrorState(ErrorState.CODE_CLAIMED);
      } else if (pendingDeleteRequest) {
        resultType = ResultType.PENDING_DELETION_REQUEST;
        setErrorState(ErrorState.PENDING_DELETION_REQUEST);
      } else if (medEnrollmentFailed) {
        setErrorState(ErrorState.SUPPORT_REQUEST_CREATED);
      } else {
        setErrorState(ErrorState.DEFAULT);
      }

      trackIdentifiedEvent("b2bEnrollmentIdentifiedSignupSubmitted", {
        b2bProgram: selectedProgram || "PROGRAM_UNSPECIFIED",
        b2bSignupResult: resultType,
        b2cTransitionedAccount: false,
        freeAccountExists: false,
        upid,
      });

      trackAnonEvent("b2bEnrollmentAnonSignupSubmitted", {
        b2bProgram: selectedProgram || "PROGRAM_UNSPECIFIED",
        b2bSignupResult: resultType,
        b2cTransitionedAccount: false,
        freeAccountExists: false,
      });

      if (resultType === ResultType.ERROR) {
        captureException(e);
      }
      navigate("/error");
      return undefined;
    }
  };

  return {
    createAccount,
  };
};
