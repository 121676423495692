import { LibCategories } from "@/utils/consent";
import { loadScript } from "@/utils/scriptLoader";

let resolveFullyLoaded: () => void;
const fullyLoaded = new Promise<void>((_resolve) => {
  resolveFullyLoaded = _resolve;
});

if (!window.OneTrust) {
  // @ts-expect-error will be populated by one trust code
  window.OneTrust = {};
}

(window as any).OptanonWrapper = function OptanonWrapper() {
  resolveFullyLoaded();
};

export async function loadOneTrust() {
  await loadScript(
    "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
    [LibCategories.strictlyNecessary],
    {
      // They appear to support it but this breaks their geolocation API,
      // but in an inconsistent fashion. Disabling as they don't seem to
      // have sufficient QA in CORS mode.
      enableCORS: false,
      htmlAttr: {
        dataset: {
          domainScript: window.ENV.ONE_TRUST_DOMAIN_ID,
        },
      },
    },
  );

  // Chaining promise here to allow upstream visibility should the first scrip call fail
  await fullyLoaded;

  return {
    OneTrust: window.OneTrust,
    ActiveGroups: window.OnetrustActiveGroups,
  };
}
