import type { Address } from "@/models";

export enum HealthConditionOptionId {
  HIGH_BLOOD_PRESSURE = "highBloodPressure",
  TYPE1_DIABETES = "type1Diabetes",
  TYPE2_DIABETES = "type2Diabetes",
  DEPRESSION_OR_ANXIETY = "depressionOrAnxiety",
  ABNORMAL_CHOLESTEROL = "abnormalCholesterol",
  LOW_TESTOSTERONE = "lowTestosterone",
  PCOS_OR_FERTILITY_PROBLEMS = "pcosOrFertilityProblems",
  CANCER_UNDER_TREATMENT = "cancerUnderTreatment",
  LIVER_DISEASE = "liverDisease",
  HEART_DISEASE = "heartDisease",
  OSTEOARTHRITIS = "osteoarthritis",
  SLEEP_PROBLEMS = "sleepProblems",
  NONE = "none",
}

/**
 * Used to group programs - it is different than curriculum as a weight loss
 * program could refer to Noom Weight or Noom DPP curriculums.
 */
export enum ProgramType {
  MENTAL_WELLNESS = "MENTAL_WELLNESS",
  WEIGHT_LOSS = "WEIGHT_LOSS",
}

export enum ResultType {
  SUCCESS = "Success",
  ACCOUNT_EXISTS = "B2B Account exists",
  ERROR = "Generic error",
  CODE_CLAIMED = "UPID claimed",
  BATCH_FULL = "Batch full",
  PENDING_DELETION_REQUEST = "Pending delete request",
  PAST_CONTRACT_END_DATE = "Past contract end date",
}

export enum USState {
  AL = "Alabama",
  AK = "Alaska",
  AZ = "Arizona",
  AR = "Arkansas",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DE = "Delaware",
  DC = "District of Columbia",
  FL = "Florida",
  GA = "Georgia",
  HI = "Hawaii",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  IA = "Iowa",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  ME = "Maine",
  MD = "Maryland",
  MA = "Massachusetts",
  MI = "Michigan",
  MN = "Minnesota",
  MS = "Mississippi",
  MO = "Missouri",
  MT = "Montana",
  NE = "Nebraska",
  NV = "Nevada",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NM = "New Mexico",
  NY = "New York",
  NC = "North Carolina",
  ND = "North Dakota",
  OH = "Ohio",
  OK = "Oklahoma",
  OR = "Oregon",
  PA = "Pennsylvania",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VT = "Vermont",
  VA = "Virginia",
  WA = "Washington",
  WV = "West Virginia",
  WI = "Wisconsin",
  WY = "Wyoming",
}

export const DEFAULT_ADDRESS: Address = {
  country: "US",
  region: "",
  city: "",
  address1: "",
  address2: "",
  zipcode: "",
};

export const INPUT_FIELD_WIDTH = 400;

export const MAX_AGE = 110;
export const MIN_AGE = 18;
