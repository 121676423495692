import React, { forwardRef, useRef } from "react";

import { Box, Input, InputProps, Text } from "@noom/wax-component-library";

type InputWithSuffixProps = InputProps & {
  suffix: string | React.ReactNode;
};

// This is a temp component because the current Input component in WAX
// does not adjust the suffix with height adjustments.
export const InputWithSuffix = forwardRef(
  (
    { maxWidth, suffix, ...props }: InputWithSuffixProps,
    ref: React.ForwardedRef<any>,
  ) => {
    const inputRef = useRef<HTMLInputElement>();
    return (
      <Box position="relative" maxWidth={maxWidth}>
        <Input
          maxWidth={maxWidth}
          ref={(el: HTMLInputElement) => {
            if (ref as any) (ref as any).current = el;
            if (inputRef) inputRef.current = el;
          }}
          textAlign="center"
          {...props}
        />
        <Text
          marginRight="var(--spacing-m)"
          position="absolute"
          right="0px"
          top="20px"
          zIndex="overlay"
          onClick={() => inputRef.current?.focus()}
        >
          {suffix}
        </Text>
      </Box>
    );
  },
);
