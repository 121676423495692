import { Api } from "@noom/noomscape";

import { useAppContext } from "@/contexts";
import { getAutologinData } from "@/utils/cookies";
import { captureMessage } from "@/utils/sentry";
import {
  BASELINE_OUTCOMES_SURVEY_EXPERIENCE_SLUG,
  BASELINE_OUTCOMES_SURVEY_UUID,
  extractOutcomesSurveyAnswers,
  RECURRING_OUTCOMES_SURVEY_EXPERIENCE_SLUG,
  RECURRING_OUTCOMES_SURVEY_UUID,
  shouldAskBaselineOutcomes,
} from "@/utils/survey/outcomes";

import { useCreateLoginToken } from "./useCreateLoginToken";
import { useGoto } from "./useGoto";

export const useStoreOutcomes = () => {
  const {
    hasB2cAccount,
    partnerInfo,
    selectedProgram,
    surveyAnswers,
    userProfile,
  } = useAppContext();
  const { createLoginToken } = useCreateLoginToken();
  const goto = useGoto();

  const {
    createAccount: { email } = {},
    createPassword,
    existingPassword,
  } = surveyAnswers;

  const password = hasB2cAccount ? existingPassword : createPassword;

  const storeBaselineOutcomesAnswers = async (accessCode: string) => {
    // The only case this will happen is if the user goes through the single
    // page enrollment form because we don't store email and password in App
    // state. But we don't ask baseline outcomes survey questions on the single
    // page enrollment flow so this wouldn't have any effect anyway.
    if (!email || !password) {
      return;
    }

    if (!shouldAskBaselineOutcomes(partnerInfo)) {
      return;
    }

    const accessToken = await createLoginToken(email, password);

    Api.call("outcomes.store", Api.api.outcomes.store, {
      accessCode,
      accessToken,
      surveyAnswers: extractOutcomesSurveyAnswers({
        program: selectedProgram,
        surveyAnswers,
      }),
      surveyExperienceSlug: BASELINE_OUTCOMES_SURVEY_EXPERIENCE_SLUG,
      surveyId: BASELINE_OUTCOMES_SURVEY_UUID,
    });
  };

  const storeRecurringOutcomesAnswers = async () => {
    const autologinData = getAutologinData();

    if (!autologinData) {
      captureMessage("No autologin data provided for survey completion");
      goto.error();
      return;
    }

    const { accessCode, accessToken } = autologinData;

    Api.call("outcomes.store", Api.api.outcomes.store, {
      accessCode,
      accessToken,
      surveyAnswers: extractOutcomesSurveyAnswers({
        program: userProfile?.currentProgram || null,
        surveyAnswers,
        userProfile,
      }),
      surveyExperienceSlug: RECURRING_OUTCOMES_SURVEY_EXPERIENCE_SLUG,
      surveyId: RECURRING_OUTCOMES_SURVEY_UUID,
    });
  };

  return { storeBaselineOutcomesAnswers, storeRecurringOutcomesAnswers };
};
