import { UpfrontEligibilityEnforcementProto_Value } from "@noom/noom-contracts/noom_contracts/backend/enrollment/deployments/upfront_eligibility_enforcement";

import type { Address } from "./Address";

export type EligibilityEnforcementType = Exclude<
  UpfrontEligibilityEnforcementProto_Value,
  "UNKNOWN" | "UNRECOGNIZED"
>;

export enum EligibilityReason {
  ELIGIBLE = "ELIGIBLE",
  EXISTING_ACCOUNT = "EXISTING_ACCOUNT",
  INELIGIBLE = "INELIGIBLE",
  NOT_IN_LATEST_FILE = "NOT_IN_LATEST_FILE",
  PRIOR_TO_EFFECTIVE_DATE = "PRIOR_TO_EFFECTIVE_DATE",
}

export type EligibilityVerificationProperties = {
  email?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  participantId?: string;
  eligibilityId?: string;
  policyId?: string;
};

export type EligibilityExtras = Partial<{
  address: Address;
  phoneNumber: string;
  policyId: string;
}>;

export type EligibilityResponse = {
  accessCode?: string;
  effectiveDate: string;
  eligible: boolean;
  eligibilityEnforcementType: EligibilityEnforcementType;
  eligibilityReason: EligibilityReason;
  extras?: EligibilityExtras;
  medEligible: boolean;
  partnerId?: number;
  upid?: string;
} & EligibilityVerificationProperties;

export type EligibilityInfo = Partial<{
  dob: Date;
  effectiveDate: Date;
  email: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  isMedEligible: boolean;
}> &
  EligibilityExtras;
