/**
 * Main front end point of entry.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import React from "react";
import ReactDOM from "react-dom";

import App from "@/app/App";
import bootstrap from "@/utils/bootstrap";

import "@/api/index";
import { AppProvider } from "./contexts/app-context";

bootstrap();

// Render the app
ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById("root"),
);
