import { Slider, SliderMark, SliderThumb, SliderTrack } from "@chakra-ui/react";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  Image,
  Text,
  useMediaQuery,
} from "@noom/wax-component-library";

import { QuestionComponentProps, QuestionDefinition } from "../../Question";
import { BaseQuestion } from "../core";

const QUESTION_ID = "mskPainLevel";

declare module "@/contexts" {
  interface SurveyAnswers {
    mskPainLevel?: number;
  }
}

type LevelConfig = {
  altText: string;
  imgSrc: string;
  text: string;
};

export const getLevelConfig = (level: number, t: TFunction): LevelConfig => {
  switch (level) {
    case 0:
      return {
        altText: t("mskPainLevel.levels.noPain.altText"),
        text: t("mskPainLevel.levels.noPain.text"),
        imgSrc: "/assets/img/noPain.svg",
      };
    case 1:
    case 2:
      return {
        altText: t("mskPainLevel.levels.veryMild.altText"),
        text: t("mskPainLevel.levels.veryMild.text"),
        imgSrc: "/assets/img/veryMildPain.svg",
      };
    case 3:
    case 4:
      return {
        altText: t("mskPainLevel.levels.mild.altText"),
        text: t("mskPainLevel.levels.mild.text"),
        imgSrc: "/assets/img/mildPain.svg",
      };
    case 5:
    case 6:
      return {
        altText: t("mskPainLevel.levels.moderate.altText"),
        text: t("mskPainLevel.levels.moderate.text"),
        imgSrc: "/assets/img/moderatePain.svg",
      };
    case 7:
    case 8:
      return {
        altText: t("mskPainLevel.levels.severe.altText"),
        text: t("mskPainLevel.levels.severe.text"),
        imgSrc: "/assets/img/severePain.svg",
      };
    case 9:
    case 10:
      return {
        altText: t("mskPainLevel.levels.verySevere.altText"),
        text: t("mskPainLevel.levels.verySevere.text"),
        imgSrc: "/assets/img/verySeverePain.svg",
      };
    default:
      throw Error("Unexpected slider value");
  }
};

/**
 * A slider (range type input) question prompting the user for their pain level based on Visual Analog Scale (VAS) pain scale.
 *
 * To avoid anchoring, the slider thumb is translucent (and VAS imagery/labeling hidden) until after the user clicks/touches
 * a location on the scale or the thumb gains focus via keyboard/accessible navigation.
 */
const MskPainLevelQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();
  const [mskPainLevel, setMskPainLevel] = useState(
    surveyAnswers.mskPainLevel || 0,
  );
  const [touched, setTouched] = useState(
    surveyAnswers.mskPainLevel !== undefined,
  );

  const mskPainLevelConfig = getLevelConfig(mskPainLevel, t);

  const [isBrowser] = useMediaQuery("(min-width: 1160px)");

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionId={QUESTION_ID}
      questionText={t("mskPainLevel.questionText")}
      questionHelperText={t("mskPainLevel.questionHelperText")}
    >
      <Box margin={isBrowser ? "96px 0px 0px" : "96px 32px 0px"}>
        <Slider
          min={0}
          max={10}
          onChange={(val) => {
            setMskPainLevel(val);
            setTouched(true);
          }}
          value={mskPainLevel}
        >
          {Array(11)
            .fill(0)
            .map((_, i) => (
              <SliderMark
                key={i}
                value={i}
                mt="-10px"
                ml="-5%"
                w="10%"
                fontSize="sm"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Text>|</Text>
                <Text>{i}</Text>
              </SliderMark>
            ))}
          {touched && (
            <SliderMark
              mt="-100px"
              ml="-32px"
              w="64px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              value={mskPainLevel}
              whiteSpace="nowrap"
            >
              <Image
                alt={`${t("mskPainLevel.levels.altTextPrefix")} ${
                  mskPainLevelConfig.altText
                }`}
                ml="-16px"
                h="64px"
                src={mskPainLevelConfig.imgSrc}
              />
              <Text data-testid={mskPainLevelConfig.text}>
                {mskPainLevelConfig.text}
              </Text>
            </SliderMark>
          )}
          <SliderTrack
            data-testid="slider-track"
            bgColor={CompassColor.black}
            height="1px"
          />
          <SliderThumb
            data-testid="slider-thumb"
            onFocus={() => setTouched(true)}
            bgColor={CompassColor.lagoon}
            opacity={Number(touched)}
          />
        </Slider>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        mb="16px"
        sx={
          isBrowser
            ? {
                width: "calc(100% + 72px)",
                left: "-36px",
                position: "relative",
              }
            : undefined
        }
      >
        <Text>{t("mskPainLevel.scaleMin")}</Text>
        <Text>{t("mskPainLevel.scaleMax")}</Text>
      </Box>
      <Button
        colorScheme="primary"
        onClick={() => onClickNext(mskPainLevel)}
        disabled={!touched}
        size="xl"
      >
        {t("mskPainLevel.nextButton")}
      </Button>
    </BaseQuestion>
  );
};

export const Q_MSK_PAIN_LEVEL: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ surveyAnswers }) =>
    !!surveyAnswers.mskPain && !surveyAnswers.mskPain.includes("none"),
  component: MskPainLevelQuestion,
};

export const Q_OUTCOMES_MSK_PAIN_LEVEL: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ surveyAnswers, userProfile }) =>
    userProfile?.currentProgram !== "MOOD" &&
    !!surveyAnswers.mskPain &&
    !surveyAnswers.mskPain.includes("none"),
  component: MskPainLevelQuestion,
};
