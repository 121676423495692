import {
  HealthConditionOptionId,
  NoomMedMoreOptionsSelection,
  PregnantSelection,
} from "@/constants";
import { SurveyAnswers } from "@/contexts";
import { Partner } from "@/models";

import { calculateBMI } from "./calculateBMI";

const ELIGIBLE_COMORBIDITIES = [
  HealthConditionOptionId.ABNORMAL_CHOLESTEROL,
  HealthConditionOptionId.LIVER_DISEASE,
  HealthConditionOptionId.LOW_TESTOSTERONE,
  HealthConditionOptionId.HEART_DISEASE,
  HealthConditionOptionId.HIGH_BLOOD_PRESSURE,
  HealthConditionOptionId.OSTEOARTHRITIS,
  HealthConditionOptionId.PCOS_OR_FERTILITY_PROBLEMS,
  HealthConditionOptionId.TYPE2_DIABETES,
];

/**
 * This function returns whether a user qualifies for Noom Med based on the
 * current survey answer state. This is used to determine question visibility
 * so it does mean it will return true if some questions are not answered
 * (e.g. if a user does not answer the pregnant question - user is male, or has
 * not answered the state of residency question yet)
 *
 * @param {SurveyAnswers} surveyAnswers The survey answers state
 * @returns {boolean} True if the user qualifies for Noom Med, false otherwise
 */
export const qualifiesForNoomMed = (
  surveyAnswers: SurveyAnswers,
  partnerInfo: Partner | null,
  telehealthEligibleStates: string[] | null,
): boolean => {
  const {
    eligibilityCheck: { dob, isMedEligible = false } = {},
    heightWeight: { heightFeetPortion, heightInchesPortion, weight } = {},
    locationState,
    noomMedMoreOptions,
    pregnant,
    healthConditions,
  } = surveyAnswers;

  const { configs: { OFFERS_MED = false } = {} } = partnerInfo || {};

  if (
    !dob ||
    heightFeetPortion === undefined ||
    heightInchesPortion === undefined ||
    weight === undefined
  ) {
    return false;
  }

  if (
    healthConditions &&
    healthConditions.includes(HealthConditionOptionId.TYPE1_DIABETES)
  ) {
    return false;
  }

  const bmi = calculateBMI(heightFeetPortion, heightInchesPortion, weight);
  const meetsBmiCriteria =
    bmi >= 30 ||
    (bmi >= 27 &&
      !!healthConditions?.some((hc) =>
        ELIGIBLE_COMORBIDITIES.includes(hc as HealthConditionOptionId),
      ));

  return (
    OFFERS_MED &&
    isMedEligible &&
    meetsBmiCriteria &&
    (!pregnant || pregnant === PregnantSelection.NO) &&
    (!locationState ||
      (telehealthEligibleStates || []).includes(locationState)) &&
    (!noomMedMoreOptions ||
      noomMedMoreOptions === NoomMedMoreOptionsSelection.MED)
  );
};
