import { differenceInYears, format } from "date-fns";
import { omit } from "lodash";

import type { EligibilityCheckFormValues } from "@/components/forms/EligibilityCheckForm";
import { SurveyAnswers } from "@/contexts";

import { calculateBMI } from "./calculateBMI";

export const calculateDppRiskScore = (_surveyAnswers: SurveyAnswers) => {
  const {
    eligibilityCheck,
    familyHistory,
    gestationalDiabetes,
    heightWeight,
    hypertension,
    physicalActivity,
    prediabetesHistory,
    sex,
  } = <
    {
      eligibilityCheck: EligibilityCheckFormValues;
      familyHistory: string;
      gestationalDiabetes: string;
      heightWeight: {
        heightFeetPortion: number;
        heightInchesPortion: number;
        weight: number;
      };
      hypertension: string;
      physicalActivity: string;
      prediabetesHistory: string[];
      sex: string;
    }
  >_surveyAnswers;

  let dppRiskScore = 0;

  const bmi = calculateBMI(
    heightWeight?.heightFeetPortion,
    heightWeight?.heightInchesPortion,
    heightWeight?.weight,
  );

  if (bmi >= 23 && bmi < 30) {
    dppRiskScore += 1;
  } else if (bmi >= 30 && bmi < 40) {
    dppRiskScore += 2;
  } else if (bmi >= 40) {
    dppRiskScore += 3;
  } else {
    dppRiskScore += 0;
  }

  const age = eligibilityCheck?.dob
    ? differenceInYears(new Date(), eligibilityCheck?.dob as Date)
    : (eligibilityCheck?.age as number);
  if (age >= 40 && age < 50) {
    dppRiskScore += 1;
  } else if (age >= 50 && age < 60) {
    dppRiskScore += 2;
  } else if (age >= 60) {
    dppRiskScore += 3;
  } else {
    dppRiskScore += 0;
  }

  dppRiskScore +=
    prediabetesHistory?.reduce(
      (acc, risk) =>
        acc +
        (["prediabetes", "a1c", "fastingBloodSugarLevel"].includes(risk)
          ? 5
          : 0),
      0,
    ) || 0;

  dppRiskScore += sex === "male" ? 1 : 0;
  dppRiskScore += gestationalDiabetes === "yes" ? 5 : 0;
  dppRiskScore += hypertension === "yes" ? 1 : 0;
  dppRiskScore += familyHistory === "yes" ? 1 : 0;
  dppRiskScore += physicalActivity === "no" ? 1 : 0;

  return dppRiskScore;
};

export const sanitizeDppSurveyAnswers = (
  surveyAnswers: SurveyAnswers,
): Record<string, any> => {
  const QUESTION_BLOCKLIST = [
    "createAccount",
    "createPassword",
    "completeProfile",
    "existingPassword",
    "phoneNumber",
  ];
  let sanitizedSurveyAnswers: Record<string, any> = omit(
    surveyAnswers,
    QUESTION_BLOCKLIST,
  );

  // convert date to string (don't need/want time info)
  if (surveyAnswers.eligibilityCheck?.dob) {
    sanitizedSurveyAnswers = {
      ...sanitizedSurveyAnswers,
      eligibilityCheck: {
        ...surveyAnswers.eligibilityCheck,
        dob: format(surveyAnswers.eligibilityCheck.dob as Date, "yyyy-MM-dd"),
      },
    };
  }
  return sanitizedSurveyAnswers;
};
