import React from "react";

import { Button, Spacing, Stack, Text } from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";

import { BaseQuestion, ImageBulletListCard } from "../core";

const QUESTION_ID = "scaleOptIn";

declare module "@/contexts" {
  interface SurveyAnswers {
    scaleOptIn?: boolean;
  }
}

const ScaleOptIn: React.FC<QuestionComponentProps> = ({ onClickNext }) => {
  const bulletListItems = [
    {
      image: "/assets/img/scaleOptIn-chart.png",
      text: "Daily weigh-ins lead to more self-awareness and long-term weight loss",
    },
    {
      image: "/assets/img/scaleOptIn-scale.png",
      text: "Connected scales help by auto-syncing your weight to your account",
    },
  ];
  return (
    <BaseQuestion
      hideHeader
      onClickBack={() => undefined}
      questionId={QUESTION_ID}
      questionText="You can boost your success with a cellular-connected scale."
    >
      <ImageBulletListCard items={bulletListItems} />
      <Text>
        Do you want to be sent a cellular-connected scale at{" "}
        <b>no cost to you?</b>
      </Text>
      <Stack spacing={Spacing[4]} pt={Spacing[2]}>
        <Button
          colorScheme="primary"
          onClick={() => {
            onClickNext(true);
          }}
          size="xl"
          type="submit"
        >
          Yes, I want a free scale
        </Button>
        <Button
          onClick={() => {
            onClickNext(false);
          }}
          size="xl"
          variant="outline"
        >
          No thanks, I have my own scale
        </Button>
      </Stack>
    </BaseQuestion>
  );
};

export const Q_SCALE_OPT_IN: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ partnerInfo, selectedProgram }) =>
    selectedProgram !== "MOOD" &&
    (selectedProgram === "MED" ||
      partnerInfo?.configs.OFFER_SCALE_SHIPMENT === true),
  component: ScaleOptIn,
};
