import { Curriculum } from "@/constants";
import { init as urlParamsPiiInit } from "@/utils/pixels/urlParamsPii";

import { EVENTS, firePixelEvent, initializeHandlers } from "./pixels/handler";
import { initializePixels } from "./vendors";

const ensureInit = () => {
  urlParamsPiiInit();
  initializePixels();
  initializeHandlers();
};

const getRevenueMetrics = (curriculum: Curriculum) => {
  // Revenue values should end in $0.01 to be uniquely identifiable.
  if (curriculum === Curriculum.HM) {
    return {
      eltv_25_months: 119.01,
      erev_13_months: 169.01,
    };
  }
  if (curriculum === Curriculum.HW) {
    return {
      eltv_25_months: 131.01,
      erev_13_months: 169.01,
    };
  }
  if (curriculum === Curriculum.DPP) {
    return {
      eltv_25_months: 261.01,
      erev_13_months: 405.01,
    };
  }
  throw Error("Unknown curriculum");
};

export const trackBuyflowConversion = (
  curriculum: Curriculum,
  email: string,
  userId: string,
) => {
  ensureInit();
  const eventname: EVENTS = "purchase";

  const { eltv_25_months, erev_13_months } = getRevenueMetrics(curriculum);
  firePixelEvent(eventname, {
    userId,
    eltv_25_months,
    erev_13_months,
    email,
  });
};
