import Cookies from "js-cookie";
import { customAlphabet } from "nanoid";
import { useEffect, useMemo, useState } from "react";

import { toSha256 } from "@/utils/cryptography";
import { captureException } from "@/utils/sentry";

export type AnalyticsUserDetails = {
  email: string;
};

enum CookieName {
  ANONYMOUS_ID = "b2b_anonymous_user_id",
  IDENTIFIED_ID = "b2b_identified_user_id",
  IDENTITY_HASH = "b2b_identity_hash",
}

// Generate user ids in the same format as the growth Buyflow (32-char lowercase hex string)
const generateUserId = customAlphabet("0123456789abcdef", 32);
const validateIdFormat = (id?: string) =>
  id && /^[0-9a-f]{32}$/.test(id) ? id : undefined;

function getCookieOrDefault(name: CookieName, createValue: () => string) {
  const value = Cookies.get(name);
  if (value) {
    return value;
  }
  const newValue = createValue();
  Cookies.set(name, newValue);
  return newValue;
}

async function checkAndUpdateIdentifiableId(
  details: AnalyticsUserDetails | undefined,
) {
  let identifiableId = getCookieOrDefault(
    CookieName.IDENTIFIED_ID,
    generateUserId,
  );

  if (details) {
    const currentHash = await toSha256(`${details.email}`);
    const storedHash = getCookieOrDefault(
      CookieName.IDENTITY_HASH,
      () => currentHash,
    );
    if (storedHash !== currentHash) {
      identifiableId = generateUserId();
      Cookies.set(CookieName.IDENTIFIED_ID, identifiableId);
      Cookies.set(CookieName.IDENTITY_HASH, currentHash);
    }
  }
  return identifiableId;
}

export const useAnalyticsIds = () => {
  const [userDetails, setUserDetails] = useState<
    AnalyticsUserDetails | undefined
  >();

  const anonymousId = useMemo(
    () => getCookieOrDefault(CookieName.ANONYMOUS_ID, generateUserId),
    [],
  );
  const [identifiableId, setIdentifiableId] = useState(() => {
    const initialId = getCookieOrDefault(
      CookieName.IDENTIFIED_ID,
      generateUserId,
    );
    // some old clients may have an incorrectly formatted identified id (before 12/2023)
    if (!validateIdFormat(initialId)) {
      const newId = generateUserId();
      Cookies.set(CookieName.IDENTIFIED_ID, newId);
      return newId;
    }
    return initialId;
  });

  useEffect(() => {
    checkAndUpdateIdentifiableId(userDetails)
      .then((id) => {
        setIdentifiableId(id);
      })
      .catch((err) => {
        captureException(err);
      });
  }, [userDetails]);

  return {
    anonymousId,
    identifiableId,
    setUserDetails,
  };
};
