import { format } from "date-fns";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Api } from "@noom/noomscape";
import { Box, CompassColor, Loader } from "@noom/wax-component-library";

import { ResponseData as UpidValidationData } from "@/api/account/validateUpid";
import WelcomeToNoomAnimation from "@/components/WelcomeToNoomAnimation";
import type { EligibilityCheckFormValues } from "@/components/forms";
import {
  CREATE_ACCOUNT_QUESTION_ID,
  ELIGIBILITY_CHECK_QUESTION_ID,
} from "@/components/survey/questionTypes/dpp-triage";
import { DOB_FORMAT, ErrorState, ResultType } from "@/constants";
import { DPP_PARTNERS } from "@/constants/Partners";
import { useAppContext } from "@/contexts";
import {
  EligibilityResult,
  useAnswerSurveyQuestion,
  useEligibility,
  useGoto,
  useQueryParams,
  useSubmitPasscode,
  useTrackEvent,
} from "@/hooks";
import {
  EligibilityInfo,
  EligibilityResponse,
  EnrollmentInformation,
  getDisplayName,
  Partner,
} from "@/models";
import { getSurveyAnswersFromCookie } from "@/utils/cookies";
import { parseEligibilityFileDate } from "@/utils/eligibility";
import { captureException, captureMessage } from "@/utils/sentry";
import { isBuyflowTraffic } from "@/utils/userSegment";

import { AxiosError } from "axios";

import { EnrollmentStaticContent } from "./EnrollmentStaticContent";
import { CodeInputForm } from "./code-input/CodeInputForm";
import { EntryForm } from "./enrollment/EntryForm";
import { PostEnrollmentRefreshView } from "./success/PostEnrollmentRefreshView";

async function validateUpid(upid: string) {
  const validationData: UpidValidationData = await Api.call(
    "account.validateUpid",
    Api.api.account.validateUpid,
    {
      upid,
    },
  );

  return validationData;
}

async function getEnrollmentInformation(upid: string) {
  const enrollmentInformation: EnrollmentInformation = await Api.call(
    "upid.getEnrollmentInformation",
    Api.api.upid.getEnrollmentInformation,
    {
      upid,
    },
  );

  return enrollmentInformation;
}

async function getPartnerBySlug(slug: string) {
  try {
    const partnerInfo: Partner = await Api.call(
      "partner.getPartnerBySlug",
      Api.api.partner.getPartnerBySlug,
      {
        slug,
      },
    );

    return {
      ...partnerInfo,
      isDppPartner: DPP_PARTNERS.includes(partnerInfo.id),
    };
  } catch (e) {
    const { response } = e as AxiosError;
    if (response && response.status === 404) {
      return null;
    }
    throw e;
  }
}

async function getPartnerByUpid(upid: string) {
  const partnerInfo: Partner = await Api.call(
    "partner.getPartnerByUpid",
    Api.api.partner.getPartnerByUpid,
    {
      upid,
    },
  );

  return {
    ...partnerInfo,
    isDppPartner: DPP_PARTNERS.includes(partnerInfo.id),
  };
}

export const ReskinView: React.FC = () => {
  const { answerSurveyQuestion } = useAnswerSurveyQuestion();
  const {
    eligibilityInfo,
    enrollmentInfo,
    partnerInfo,
    selectedProgram,
    setEligibilityInfo,
    setEnrollmentInfo,
    setEnrollmentType,
    setErrorState,
    setHasB2cAccount,
    setPartnerInfo,
  } = useAppContext();
  const { checkEligibility, fetchEligibilityInfo } = useEligibility();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { employerSlug } = useParams<AppParams>();
  const [eligibilityResponse, setEligibilityResponse] =
    useState<EligibilityResponse>();
  const [hasAlreadyEnrolled, setHasAlreadyEnrolled] = useState(false);
  // Used for storing the result from the check eligibility API and triggers
  // effects off it needed for storing triage data based on eligibility info
  const [localEligibilityResult, setLocalEligibilityResult] = useState<
    EligibilityResult | undefined
  >();
  const [ssoAnimationDone, setSsoAnimationDone] = useState(false);
  const submitPasscode = useSubmitPasscode();
  const { trackAnonEvent, trackIdentifiedEvent } = useTrackEvent();
  const { notFound } = useGoto();

  const {
    eligibilityId,
    leadSource,
    passcode,
    pwResetRedirect,
    sharedEligibility,
    upid: rawUpid,
  } = useQueryParams();
  const upid = rawUpid ?? "";

  const surveyAnswersFromCookie = getSurveyAnswersFromCookie();
  const [checkingSurveyAnswerCookie, setCheckingSurveyAnswerCookie] = useState(
    !!(surveyAnswersFromCookie && pwResetRedirect === "true"),
  );

  const isLoading =
    !!(upid || passcode) &&
    !(enrollmentInfo && partnerInfo && !(eligibilityId && !eligibilityInfo));

  const hideNoomCostMention = partnerInfo?.configs?.HIDE_COST || false;

  const validateUpidAndGetEnrollmentInfo = async () => {
    if (Cookies.get("lastEnrolledUpid")?.toLowerCase() === upid) {
      setHasAlreadyEnrolled(true);
      return;
    }

    try {
      const [validationData, enrollmentInformation] = await Promise.all([
        validateUpid(upid),
        getEnrollmentInformation(upid),
      ]);

      setEnrollmentInfo(enrollmentInformation);

      if (!partnerInfo) {
        const partnerInformation = await getPartnerByUpid(upid);

        setPartnerInfo(partnerInformation);
        if (
          partnerInformation.goLiveDate &&
          partnerInformation.goLiveDate > new Date()
        ) {
          setErrorState(ErrorState.BEFORE_GOLIVE_DATE);
          navigate("/error");
          return;
        }
      }

      if (!validationData.available) {
        const errorStateMap = {
          ERROR_ACTIVATION_CODE_CLAIMED: ErrorState.CODE_CLAIMED,
          ERROR_ACTIVATION_CODE_DOESNT_EXIST: ErrorState.DEFAULT,
          ERROR_BATCH_FULL: ErrorState.BATCH_FULL,
          ERROR_PAST_CONTRACT_END_DATE: ErrorState.PAST_CONTRACT_END_DATE,
        };

        const resultTypeMap = {
          ERROR_ACTIVATION_CODE_CLAIMED: ResultType.CODE_CLAIMED,
          ERROR_ACTIVATION_CODE_DOESNT_EXIST: ResultType.ERROR,
          ERROR_BATCH_FULL: ResultType.BATCH_FULL,
          ERROR_PAST_CONTRACT_END_DATE: ResultType.PAST_CONTRACT_END_DATE,
        };

        const errorState = validationData.requestError
          ? errorStateMap[validationData.requestError] || ErrorState.DEFAULT
          : ErrorState.DEFAULT;

        const resultType = validationData.requestError
          ? resultTypeMap[validationData.requestError] || ResultType.ERROR
          : ResultType.ERROR;

        trackIdentifiedEvent("b2bEnrollmentIdentifiedSignupSubmitted", {
          b2bProgram: selectedProgram || "PROGRAM_UNSPECIFIED",
          b2bSignupResult: resultType,
          b2cTransitionedAccount: false,
          freeAccountExists: false,
          upid,
        });

        trackAnonEvent("b2bEnrollmentAnonSignupSubmitted", {
          b2bProgram: selectedProgram || "PROGRAM_UNSPECIFIED",
          b2bSignupResult: resultType,
          b2cTransitionedAccount: false,
          freeAccountExists: false,
        });

        setErrorState(errorState);
        navigate("/error");
        return;
      }
    } catch (e) {
      captureException(e);
      setErrorState(ErrorState.DEFAULT);
      navigate("/error");
    }
  };

  useEffect(() => {
    setEnrollmentType("INITIAL_ENROLLMENT");
  }, []);

  useEffect(() => {
    if (!upid) {
      return;
    }

    validateUpidAndGetEnrollmentInfo();
  }, [upid]);

  useEffect(() => {
    if (!employerSlug) {
      return;
    }

    const getPartnerInfo = async () => {
      const partnerInformation = await getPartnerBySlug(employerSlug);

      if (!partnerInformation) {
        notFound();
        return;
      }

      setPartnerInfo(partnerInformation);
      if (
        partnerInformation.goLiveDate &&
        partnerInformation.goLiveDate > new Date()
      ) {
        setErrorState(ErrorState.BEFORE_GOLIVE_DATE);
        navigate("/error");
      }
    };

    getPartnerInfo();
  }, [employerSlug]);

  useEffect(() => {
    if (passcode && employerSlug) {
      submitPasscode(passcode, "AUTOFILLED").catch(() => {
        const qs = new URLSearchParams(search);
        qs.delete("passcode");
        navigate(`/employer/${employerSlug}?${qs.toString()}`);
      });
    }
  }, [passcode, employerSlug]);

  useEffect(() => {
    if (!isBuyflowTraffic()) {
      return;
    }

    if (eligibilityInfo) {
      answerSurveyQuestion(
        ELIGIBILITY_CHECK_QUESTION_ID,
        eligibilityInfo,
        {},
        true,
      );
    }

    if (!isLoading) {
      navigate(
        {
          pathname: "/enrollment",
          search,
        },
        { replace: true },
      );
    }
  }, [enrollmentInfo, partnerInfo, eligibilityInfo]);

  useEffect(() => {
    if (eligibilityId && enrollmentInfo?.partnerId && !eligibilityInfo) {
      fetchEligibilityInfo(eligibilityId, enrollmentInfo.partnerId).then(
        (res: EligibilityResponse) => setEligibilityResponse(res),
      );
    }
  }, [eligibilityId, enrollmentInfo]);

  useEffect(() => {
    if (eligibilityId && !upid) {
      setErrorState(ErrorState.DEFAULT);
      navigate("/error");
    }
  }, [eligibilityId, upid]);

  const handleEligibilityResponse = () => {
    if (eligibilityResponse) {
      const {
        accessCode,
        dateOfBirth,
        effectiveDate: effectiveDateString,
        eligible,
        email,
        participantId,
        firstName,
        lastName,
        medEligible,
        extras,
      } = eligibilityResponse;
      if (!eligible && accessCode) {
        setErrorState(ErrorState.SSO_ACCOUNT_EXISTS);
        return navigate("/error");
      }
      if (!eligible) {
        setErrorState(ErrorState.SSO_INELIGIBLE);
        return navigate("/error");
      }

      if (!(dateOfBirth && firstName && lastName)) {
        setErrorState(ErrorState.DEFAULT);
        return navigate("/error");
      }

      setEligibilityInfo({
        dob: parseEligibilityFileDate(dateOfBirth),
        effectiveDate: parseEligibilityFileDate(effectiveDateString),
        email,
        employeeId: participantId,
        firstName,
        lastName,
        isMedEligible: medEligible,
        ...extras,
      });
    }
  };

  useEffect(() => {
    if (sharedEligibility === "true") {
      handleEligibilityResponse();
    }
  }, [eligibilityResponse, sharedEligibility]);

  useEffect(() => {
    if (
      !(checkingSurveyAnswerCookie && surveyAnswersFromCookie && partnerInfo)
    ) {
      return;
    }

    const wrappedCheckEligibility = async () => {
      const { eligibilityCheck } = surveyAnswersFromCookie;
      const eligibilityResult = await checkEligibility(
        {
          dateOfBirth: eligibilityCheck?.dob
            ? format(eligibilityCheck.dob, DOB_FORMAT)
            : undefined,
          firstName: eligibilityCheck?.firstName,
          lastName: eligibilityCheck?.lastName,
          participantId: eligibilityCheck?.employeeId,
          policyId: eligibilityCheck?.policyId,
        },
        partnerInfo.id,
        leadSource,
      );

      setLocalEligibilityResult(eligibilityResult);
    };

    wrappedCheckEligibility();
  }, [partnerInfo]);

  const maybeNavigateToExistingPasswordQuestion = async () => {
    if (!(localEligibilityResult && surveyAnswersFromCookie)) {
      return;
    }

    const { eligibilityInfo: freshEligibilityInfo, isEligible } =
      localEligibilityResult;

    // If the user is ineligible for some reason show them the landing page
    // (rather than immediately sending them to an error page)
    if (!isEligible) {
      captureMessage("User redirected from password reset is ineligible");
      setCheckingSurveyAnswerCookie(false);
      return;
    }
    const { createAccount, eligibilityCheck } = surveyAnswersFromCookie;

    // Delete any undefined values from the fresh eligibility info before using it to update the eligibility check survey answer.
    // This is necessary in the event the user provided info not present in the eligibility info from the backend
    // or the partner doesn't use upfront eligibility checking and we didn't match a user from an eligibility file.
    Object.keys(freshEligibilityInfo).forEach(
      (key) =>
        freshEligibilityInfo[key as keyof EligibilityInfo] === undefined &&
        delete freshEligibilityInfo[key as keyof EligibilityInfo],
    );

    await answerSurveyQuestion(
      ELIGIBILITY_CHECK_QUESTION_ID,
      {
        ...eligibilityCheck,
        ...freshEligibilityInfo,
      },
      { [CREATE_ACCOUNT_QUESTION_ID]: createAccount },
      true,
    );

    setHasB2cAccount(true);
    navigate(
      {
        pathname: "/enrollment/existingPassword",
        search,
      },
      { replace: true, state: { resetOnOpen: false } },
    );
  };

  // Only call maybeNavigateToExistingPasswordQuestion after eligibilityInfo
  // has been evaluated and updated in app state through the checkEligibility
  // call.
  useEffect(() => {
    if (!(eligibilityInfo && localEligibilityResult)) {
      return;
    }
    maybeNavigateToExistingPasswordQuestion();
  }, [eligibilityInfo, localEligibilityResult]);

  if (hasAlreadyEnrolled) {
    return <PostEnrollmentRefreshView />;
  }

  // if we don't have a passcode or upid for some reason, the user should see the
  // CodeInputForm and the rest of the landing page content.
  // Also using !(enrollmentInfo && partnerInfo) rather than isLoading as isLoading isn't reliable due to it being
  // mutated in multiple async actions.
  if ((isLoading && isBuyflowTraffic()) || checkingSurveyAnswerCookie) {
    return (
      <Box
        position="absolute"
        height="100vh"
        width="100vw"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Loader size="xl" colorScheme="primary" />
      </Box>
    );
  }

  if (
    eligibilityId &&
    sharedEligibility !== "true" &&
    !ssoAnimationDone &&
    !eligibilityInfo
  ) {
    return (
      <WelcomeToNoomAnimation
        animationDone={() => {
          handleEligibilityResponse();
          setSsoAnimationDone(true);
        }}
        isEligible={Boolean(
          eligibilityResponse?.eligible &&
            eligibilityResponse?.dateOfBirth &&
            eligibilityResponse?.firstName &&
            eligibilityResponse?.lastName,
        )}
      />
    );
  }

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", lg: "row" }}
      height="100vh"
    >
      <Box
        backgroundColor={CompassColor.blueberry}
        borderBottomRightRadius="96px"
        display="flex"
        justifyContent={{
          base: "center",
          sm: "left",
        }}
        width={{ base: "100%", lg: "60%" }}
      >
        <Box
          margin={{
            base: "var(--spacing-xxl) var(--spacing-l)",
            sm: "var(--spacing-xxl) var(--spacing-xxxh) 0 var(--spacing-xxxh)",
            lg: "var(--spacing-xxl) var(--spacing-xh) 0 var(--spacing-xl)",
            xl: "var(--spacing-xxl) var(--spacing-xh) 0 auto",
          }}
          maxWidth="768px"
          width={{
            base: "343px",
            sm: "100%",
          }}
        >
          <EnrollmentStaticContent
            employerName={getDisplayName(partnerInfo)}
            employerSlug={employerSlug || partnerInfo?.slug}
            showPricePill={!isLoading && !hideNoomCostMention}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent={{
          base: "center",
          sm: "left",
        }}
        overflowY={{
          base: "unset",
          lg: "auto",
        }}
        width={{
          base: "100%",
          lg: "40%",
        }}
      >
        <Box
          margin={{
            base: "var(--spacing-xxl) var(--spacing-l)",
            sm: "var(--spacing-xxl) var(--spacing-xxxh)",
            lg: "var(--spacing-xxl) var(--spacing-xl)",
          }}
          width={{
            base: "343px",
            sm: "100%",
          }}
        >
          {isLoading ? (
            <Box display="flex" justifyContent="center" maxWidth="343px">
              <Loader size="xl" colorScheme="primary" />
            </Box>
          ) : !upid ? (
            <CodeInputForm />
          ) : (
            enrollmentInfo && (
              <EntryForm enrollmentInfo={enrollmentInfo} upid={upid} />
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ReskinView;
