import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Button, Input, Spacing, Stack } from "@noom/wax-component-library";

import { enforcePositiveFloat } from "@/components/form/utils";

import { QuestionComponentProps, QuestionDefinition } from "../../Question";
import { BaseQuestion } from "../core";

const QUESTION_ID = "exercise";

declare module "@/contexts" {
  interface SurveyAnswers {
    exercise?: number;
  }
}

type ExerciseFormValues = {
  exercise?: number;
};

const ExerciseQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { t } = useTranslation();

  const initialValues: ExerciseFormValues = {
    exercise: surveyAnswers.exercise,
  };

  const MAX_MINUTES = 10000; // roughtly 10000 minutes in a week
  const validationSchema = Yup.object({
    exercise: Yup.number()
      .min(0, t("exercise.errors.invalid"))
      .max(MAX_MINUTES, t("exercise.errors.invalid"))
      .required(t("exercise.errors.required")),
  });

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionId={QUESTION_ID}
      questionText={t("exercise.questionText")}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={({ exercise }: ExerciseFormValues) => {
          if (exercise === undefined) {
            return;
          }

          onClickNext(exercise);
        }}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ errors, getFieldProps, handleSubmit, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={Spacing[8]}>
              <Input
                {...getFieldProps("exercise")}
                autoFocus
                error={touched.exercise && errors.exercise}
                onKeyDown={enforcePositiveFloat}
                type="number"
              />
              <Button colorScheme="primary" size="xl" type="submit">
                Next
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </BaseQuestion>
  );
};

export const Q_EXERCISE: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ userProfile }) =>
    userProfile?.currentProgram !== "MOOD" &&
    !userProfile?.loggedExerciseMinsPastTwoWks,
  component: ExerciseQuestion,
};
