import { useField } from "formik";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Input, InputProps } from "@noom/wax-component-library";

import { MAX_AGE, MIN_AGE } from "@/constants";

export const AgeInputValidationSchema = Yup.object({
  age: Yup.number()
    .nullable(true)
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(() => i18next.t("form.age.errors.required"))
    .positive(() => i18next.t("form.age.errors.invalid"))
    .min(MIN_AGE, () => i18next.t("form.age.errors.tooYoung"))
    .max(MAX_AGE, () => i18next.t("form.age.errors.invalid")),
});

type AgeInputProps = InputProps & {
  name: string;
};

export const AgeInput: React.FC<AgeInputProps> = ({ name, ...rest }) => {
  const [field, { touched, error }] = useField({ name });
  const { t } = useTranslation();

  return (
    <Input
      {...field}
      error={touched && error}
      label={t("form.age.label")}
      placeholder={t("form.age.placeholder")}
      type="number"
      {...rest}
    />
  );
};
