import React from "react";

import { Box, Heading, Text } from "@noom/wax-component-library";

import { NoomHeader } from "@/components/noom-branding";

type FullscreenMessageProps = {
  button?: React.ReactElement;
  heading: string;
  message: React.ReactElement;
  visual: React.ReactElement;
};

export const FullscreenMessage: React.FC<FullscreenMessageProps> = ({
  button,
  heading,
  message,
  visual,
}) => (
  <Box>
    <NoomHeader forceHeader />
    <Box
      display="flex"
      flexDirection="column"
      margin={{ base: "0 var(--spacing-l)", lg: "auto" }}
      maxWidth="600px"
    >
      <Box display="flex" justifyContent="center">
        {visual}
      </Box>
      <Heading
        fontSize={{ base: "20px", lg: "24px" }}
        fontWeight={500}
        lineHeight={{ base: "26px", lg: "32px" }}
        marginTop="var(--spacing-l)"
      >
        {heading}
      </Heading>
      <Text lineHeight="24px" marginTop="var(--spacing-xl)" size="l">
        {message}
      </Text>
      {button && <Box marginTop="var(--spacing-xl)">{button}</Box>}
    </Box>
  </Box>
);
