import axios, { AxiosResponse } from "axios";

import { Api } from "@noom/noomscape";

type CreateLoginTokenRequest = {
  email: string;
  password: string;
};

export type CreateLoginTokenResponse = {
  access_token: string;
};

Api.register(
  "account.createLoginToken",
  async ({ email, password }: CreateLoginTokenRequest) => {
    const response: AxiosResponse<CreateLoginTokenResponse> = await axios({
      baseURL: window.ENV.SERVER_URL,
      data: {
        client_id: "noom-coach",
        grant_type: "password",
        request: JSON.stringify({
          credentials: password,
          mode: "LOGIN",
          principal: email,
          type: "EMAIL_AND_PASSWORD",
        }),
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "POST",
      url: "/oauth/token",
      urlWithPathParams: "/oauth/token",
    });

    return response.data;
  },
);
