import React, { useEffect } from "react";
import { isBrowser } from "react-device-detect";

import {
  Box,
  CompassColor,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

import { ProgressBar } from "@/components/ProgressBar";
import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { useAppContext } from "@/contexts";

import { BaseInterstitial } from "../../core";

const QUESTION_ID = "personalizingProgram";

type TestimonialProps = {
  imageSrc: string;
  name: string;
  quote: string;
};

const femaleTestimonialProps: TestimonialProps = {
  imageSrc: "/assets/img/testimonial-female.png",
  name: "Jennifer",
  quote:
    // eslint-disable-next-line quotes
    `"Noom fits into my lifestyle perfectly. I can turn to this app day or night and they're there for me."`,
};

const maleTestimonialProps: TestimonialProps = {
  imageSrc: "/assets/img/testimonial-male.png",
  name: "Mike",
  quote:
    // eslint-disable-next-line quotes
    `"Noom changed the way I think about portion control. It changed my relationship with food."`,
};

const Testimonial: React.FC<TestimonialProps> = ({ imageSrc, name, quote }) => (
  <Box
    display="flex"
    flexDirection="column"
    gap="var(--spacing-m)"
    paddingTop={isBrowser ? "0px" : "var(--spacing-xl)"}
  >
    <Box display="flex" gap="var(--spacing-xl)">
      <Image alt="" objectFit="contain" src={imageSrc} />
      <Box
        display="flex"
        flexDirection="column"
        gap="var(--spacing-m)"
        margin="auto"
      >
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          fontWeight={500}
          lineHeight={{ base: "22px", lg: "26px" }}
        >
          {quote}
        </Text>
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          fontWeight={500}
          lineHeight={{ base: "22px", lg: "26px" }}
        >
          -{name}
        </Text>
      </Box>
    </Box>
    <Text color={CompassColor.grey3} fontSize="14px">
      *Noom users that also adopt a healthy lifestyle typically lose 1-2 lbs per
      week
    </Text>
  </Box>
);

const PersonalizingProgram: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { selectedProgram, setSelectedProgram } = useAppContext();
  // If gender is defined, use gender, otherwise use sex.
  const gender = surveyAnswers.gender ?? surveyAnswers.sex;
  const isMale = gender === "male";

  const testimonialProps = isMale
    ? maleTestimonialProps
    : femaleTestimonialProps;

  useEffect(() => {
    // if this is the first time the user is seeing this screen selectedProgram may be null
    // if they've back navigated, we'll have set selectedProgram to either DPP or WEIGHT
    // setting selectedProgram again ensure that the correct program is set in the event
    // the user back navigated and dpp eligibility changed
    if ([null, "DPP", "WEIGHT"].includes(selectedProgram)) {
      setSelectedProgram(surveyAnswers.dppEligible ? "DPP" : "WEIGHT");
    }
  }, []);

  return (
    <BaseInterstitial
      headerText="Get ready to lose the weight and keep it off."
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      questionId={QUESTION_ID}
      showBackButton={false}
    >
      <Stack align="center" spacing={Spacing[16]}>
        <Testimonial {...testimonialProps} />
        <ProgressBar
          onComplete={() => {
            onClickNext(null);
          }}
          totalTimeMs={6000}
        />
      </Stack>
    </BaseInterstitial>
  );
};

export const Q_PERSONALIZING_PROGRAM: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ selectedProgram }) => selectedProgram !== "MOOD",
  component: PersonalizingProgram,
};
