import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, Text } from "@noom/wax-component-library";

import { useAppContext } from "@/contexts";
import { getDisplayName } from "@/models";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { CheckboxOptions } from "../../checkbox";
import { BaseQuestion } from "../core";

const QUESTION_ID = "medicareConsent";

type MedicareConsentOption = "agree" | "disagree";

declare module "@/contexts" {
  interface SurveyAnswers {
    medicareConsent?: MedicareConsentOption;
  }
}

const MedicareConsentQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { partnerInfo, setSelectedProgram } = useAppContext();
  const [checkedItems, setCheckedItems] = useState<string[]>(
    surveyAnswers[QUESTION_ID] ? [surveyAnswers[QUESTION_ID]] : [],
  );
  const { t } = useTranslation();

  const options: (QuestionOption & { id: MedicareConsentOption })[] = [
    { id: "agree", text: t("medicareConsent.options.agree") },
    { id: "disagree", text: t("medicareConsent.options.disagree") },
  ];

  const toggleCheckedState = (
    optionId: string,
    currentCheckedState: boolean,
  ) => {
    // For this question we want the checkboxes to behave more like radio
    // buttons where only one option is allowed to be selected.
    const newCheckedItems = currentCheckedState ? [] : [optionId];

    setCheckedItems(newCheckedItems);
  };

  const submitCheckboxQuestion = () => {
    if (checkedItems.length !== 1) {
      return;
    }

    const medicareConsent = checkedItems[0] as MedicareConsentOption;

    const updatedProgram = medicareConsent === "disagree" ? null : "MED";

    setSelectedProgram(updatedProgram);
    onClickNext(medicareConsent, { updatedProgram });
  };

  return (
    <BaseQuestion
      onClickBack={onClickBack}
      questionDescription={t("medicareConsent.questionDescription", {
        partnerName: getDisplayName(partnerInfo),
      })}
      questionId={QUESTION_ID}
      questionText={t("medicareConsent.questionText")}
    >
      <Text size="md">
        <Trans i18nKey="medicareConsent.disclaimer">
          PATIENT RESTRICTION ON MEDICARE CLAIMS SUBMISSION
          <br />
          <br />
          “I have been informed that the healthcare services being made
          available to me through this program will not be covered by Medicare.
          I further understand that, given my status as a Medicare beneficiary,
          any provider that provides covered services to me, such as the
          services included in this program, is required to submit my claim to
          Medicare unless I, exercising my own free will, restrict such provider
          from doing so. As such, by signing this attestation, I hereby restrict
          any provider that provides me healthcare services in this program from
          sharing any claims information, including any protected health
          information (as defined in the Health Insurance Portability and
          Accountability Act), with Medicare.”
        </Trans>
      </Text>
      <CheckboxOptions
        checkedItems={checkedItems}
        options={options}
        submitCheckboxQuestion={submitCheckboxQuestion}
        toggleCheckedState={toggleCheckedState}
      />
      <Button
        colorScheme="primary"
        disabled={checkedItems.length === 0}
        onClick={() => submitCheckboxQuestion()}
        size="xl"
      >
        {t("medicareConsent.buttonText")}
      </Button>
    </BaseQuestion>
  );
};

export const Q_MEDICARE_CONSENT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ surveyAnswers: { enrolledInMedicare } }) =>
    enrolledInMedicare === "yes" || enrolledInMedicare === "notSure",
  component: MedicareConsentQuestion,
};
