import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Spacing, Stack } from "@noom/wax-component-library";

import {
  CreateAccountForm,
  CreateAccountFormValues,
  defaultInitialValues as defaultCreateAccountFormValues,
} from "@/components/forms";
import {
  defaultEligibilityCheckFormValues,
  EligibilityCheckForm,
  EligibilityCheckFormValues,
} from "@/components/forms/EligibilityCheckForm";
import { NoomEmailAddressLink } from "@/components/noom-branding";
import {
  CREATE_ACCOUNT_QUESTION_ID,
  ELIGIBILITY_CHECK_QUESTION_ID,
} from "@/components/survey/questionTypes/dpp-triage";
import { DOB_FORMAT, ErrorState } from "@/constants";
import {
  CAREFIRST_PARTNER_ID,
  EXTERNAL_COACH_PARTNER_ID,
  NOOM_TEST_PARTNER_ID,
  PENDULUM_PARTNER_ID,
} from "@/constants/Partners";
import { useAppContext } from "@/contexts";
import {
  EligibilityResult,
  useAnswerSurveyQuestion,
  useEligibility,
  useGoto,
  useQueryParams,
  useTrackEvent,
} from "@/hooks";
import { EligibilityReason, getDisplayName } from "@/models";
import { withPiiQueryParams } from "@/utils/pixels/urlParamsPii";

import { BaseSideForm } from "./BaseSideForm";

// TriageLandingForm acts as a standalone question (outside of a survey)
declare module "@/contexts" {
  interface SurveyAnswers {
    createAccount?: CreateAccountFormValues;
    eligibilityCheck?: EligibilityCheckFormValues;
  }
}

export const TriageLandingForm: React.FC = () => {
  const { answerSurveyQuestion } = useAnswerSurveyQuestion();
  const {
    eligibilityInfo,
    enrollmentInfo: { customSupportEmail, upfrontEligibilityEnforcement } = {},
    partnerInfo,
    surveyAnswers,
  } = useAppContext();
  const { email, firstName } = eligibilityInfo || {};
  const createAccountSurveyAnswer: CreateAccountFormValues = surveyAnswers[
    CREATE_ACCOUNT_QUESTION_ID
  ] as CreateAccountFormValues;
  const [createAccountFormInitialValues] = useState<CreateAccountFormValues>(
    createAccountSurveyAnswer && Object.keys(createAccountSurveyAnswer).length
      ? createAccountSurveyAnswer
      : {
          ...defaultCreateAccountFormValues,
          email: (upfrontEligibilityEnforcement === "EMAIL" && email) || "",
        },
  );
  const eligibilityCheckSurveyAnswer = surveyAnswers[
    ELIGIBILITY_CHECK_QUESTION_ID
  ] as EligibilityCheckFormValues;
  const [
    eligibilityCheckFormInitialValues,
    setEligibilityCheckFormInitialValues,
  ] = useState<EligibilityCheckFormValues>(
    eligibilityCheckSurveyAnswer || defaultEligibilityCheckFormValues,
  );

  const { checkEligibility, redirectToParticipantPartner } = useEligibility();
  const goto = useGoto();
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const [localEligibilityResult, setLocalEligibilityResult] = useState<
    EligibilityResult | undefined
  >();
  const [
    submittedEligibilityCheckFormValues,
    setSubmittedEligibilityCheckFormValues,
  ] = useState<EligibilityCheckFormValues | undefined>();
  const { trackAnonEvent, trackAnonPageViewed } = useTrackEvent();
  const { t } = useTranslation();

  const partnerId = partnerInfo?.id;
  const { eligibilityId, sharedEligibility } = queryParams;

  const getEligibilityResult = async (values: EligibilityCheckFormValues) => {
    if (!partnerId) {
      return;
    }

    const eligibilityResult = await checkEligibility(
      {
        dateOfBirth: values?.dob
          ? format(values.dob as Date, DOB_FORMAT)
          : undefined,
        firstName: values.firstName,
        lastName: values.lastName,
        participantId: values?.employeeId,
        policyId: values?.policyId,
      },
      partnerId,
      queryParams.leadSource,
    );

    setLocalEligibilityResult(eligibilityResult);
  };

  const onSubmitEligibilityCheckForm = async (
    values: EligibilityCheckFormValues,
  ) => {
    if (!localEligibilityResult) {
      return;
    }

    const {
      isEligible,
      isMedEligible,
      eligibilityReason,
      participantEligibilityId,
      participantPartnerId,
      upid,
    } = localEligibilityResult;

    await answerSurveyQuestion(ELIGIBILITY_CHECK_QUESTION_ID, {
      ...values,
      isMedEligible,
    });

    if (!isEligible) {
      if (eligibilityReason === EligibilityReason.PRIOR_TO_EFFECTIVE_DATE) {
        goto.error(ErrorState.PRIOR_TO_EFFECTIVE_DATE);
      } else if (eligibilityReason !== EligibilityReason.EXISTING_ACCOUNT) {
        goto.error(ErrorState.FAILED_ELIGIBILITY_CHECK);
      }
      return;
    }

    if (partnerId !== participantPartnerId) {
      redirectToParticipantPartner(
        participantEligibilityId,
        participantPartnerId,
        upid,
      );
    } else {
      navigate({
        pathname: "/enrollment",
        search,
      });
    }
  };

  const handleCreateAccountFormSubmit = async (
    values: CreateAccountFormValues,
  ) => {
    // save responses for both eligibility check and create account questions.
    await answerSurveyQuestion(CREATE_ACCOUNT_QUESTION_ID, values, {
      [ELIGIBILITY_CHECK_QUESTION_ID]: eligibilityInfo,
    });

    navigate({
      pathname: "/enrollment",
      search,
    });
  };

  useEffect(() => {
    const queryParamsWithPii = withPiiQueryParams(queryParams);
    trackAnonEvent("b2bEnrollmentAnonTriageFlowStarted", {
      leadSource:
        eligibilityId && sharedEligibility !== "true"
          ? "sso"
          : queryParamsWithPii.leadSource,
      utmSource: queryParamsWithPii.utm_source,
      utmMedium: queryParamsWithPii.utm_medium,
      utmCampaign: queryParamsWithPii.utm_campaign,
      utmTerm: queryParamsWithPii.utm_term,
      utmContent: queryParamsWithPii.utm_content,
    });
    trackAnonPageViewed(
      eligibilityId
        ? CREATE_ACCOUNT_QUESTION_ID
        : ELIGIBILITY_CHECK_QUESTION_ID,
    );
  }, []);

  // Call getEligibilityResult after the form values are set in local state
  useEffect(() => {
    if (!submittedEligibilityCheckFormValues) {
      return;
    }
    getEligibilityResult(submittedEligibilityCheckFormValues);
  }, [submittedEligibilityCheckFormValues]);

  // Only call onSubmitEligibilityCheckForm after eligibilityInfo has been
  // evaluated and updated in app state through the checkEligibility call.
  useEffect(() => {
    if (
      !(
        eligibilityInfo &&
        localEligibilityResult &&
        submittedEligibilityCheckFormValues
      )
    ) {
      return;
    }
    onSubmitEligibilityCheckForm(submittedEligibilityCheckFormValues);
  }, [
    eligibilityInfo,
    localEligibilityResult,
    submittedEligibilityCheckFormValues,
  ]);

  const partnerName = getDisplayName(partnerInfo);

  const hideNoomCostMention = partnerInfo?.configs?.HIDE_COST || false;

  let headerText = "Welcome to Noom.";
  const benefitText =
    "Noom is a " +
    (!hideNoomCostMention ? "free " : "") +
    `benefit through ${partnerName}.`;
  let helperText =
    benefitText +
    " Please enter your details below to confirm your eligibility.";

  if (eligibilityId) {
    headerText = `Welcome, ${firstName}! Noom is free for you through ${partnerName}.`;
    helperText =
      "Create your account below to get started. Remember these credentials - you'll use them to log into our app.";
  } else if (partnerId === CAREFIRST_PARTNER_ID) {
    headerText = `Noom is free for eligible ${partnerName} members.`;
    helperText =
      "Members must be age 18+ and have coverage via an employer health plan. Enter your details below to confirm your eligibility.";
  } else if (
    partnerId === PENDULUM_PARTNER_ID ||
    partnerId === NOOM_TEST_PARTNER_ID
  ) {
    helperText =
      "Let's finish up setting up your Noom subscription purchased through Pendulum.";
  } else if (partnerId === EXTERNAL_COACH_PARTNER_ID) {
    helperText =
      "Let's finish setting up your complimentary Noom subscription from your Noom Mindset Coach program.";
  } else if (upfrontEligibilityEnforcement === "NONE") {
    helperText = benefitText + " Enter your details below to get started.";
  }

  return (
    <BaseSideForm headerText={headerText} helperText={helperText}>
      <Stack spacing={Spacing[8]}>
        {!eligibilityId ? (
          <EligibilityCheckForm
            pageId="eligibilityCheck"
            onSubmit={(values) =>
              setSubmittedEligibilityCheckFormValues({ ...values })
            }
            initialValues={eligibilityCheckFormInitialValues}
            setInitialValues={setEligibilityCheckFormInitialValues}
          />
        ) : (
          <CreateAccountForm
            initialValues={createAccountFormInitialValues}
            onSubmit={handleCreateAccountFormSubmit}
          />
        )}
        <footer>
          <Trans
            i18nKey="support.messages.haveQuestions"
            values={{
              email: customSupportEmail || t("support.contact.email"),
            }}
          >
            Have a question? Drop us a line at
            {NoomEmailAddressLink}
          </Trans>
        </footer>
      </Stack>
    </BaseSideForm>
  );
};
