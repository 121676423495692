import React, { useEffect, useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { B2BEnrollmentAnonRetriageResultPresentedProto } from "@noom/noom-contracts/noom_contracts/events/web/b2b/enrollment_anon";
import { B2BEnrollmentEnumsProto_Program } from "@noom/noom-contracts/noom_contracts/events/web/b2b/shared";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { useAppContext } from "@/contexts";
import { useTrackEvent } from "@/hooks";

import { BaseSuccess } from "../core/BaseSuccess";

const QUESTION_ID = "retriageResult";

enum ResultValue {
  WEIGHT = "weight",
  CURRENT_PROGRAM = "currentProgram",
  DPP = "dpp",
}

function getNoomEventProgram(
  v: ResultValue,
): B2BEnrollmentEnumsProto_Program | undefined {
  switch (v) {
    case ResultValue.WEIGHT:
      return "WEIGHT";
    case ResultValue.DPP:
      return "DPP";
    default:
      return undefined;
  }
}

const RetriageResult: React.FC<QuestionComponentProps> = ({
  onClickNext,
  surveyAnswers: { dppEligible },
}) => {
  const { retriageEligibility, surveyAnswers } = useAppContext();
  const { trackAnonEvent } = useTrackEvent();
  const { t } = useTranslation();

  const { firstName } = surveyAnswers?.retriageEligibilityCheck || {
    firstName: "Noomer",
  };
  const result: ResultValue = useMemo(
    () => (dppEligible ? ResultValue.DPP : ResultValue.CURRENT_PROGRAM),
    [dppEligible],
  );

  useEffect(() => {
    const params: B2BEnrollmentAnonRetriageResultPresentedProto = {
      currentProgram:
        retriageEligibility?.currentProgram || "PROGRAM_UNSPECIFIED",
    };
    const eligibleProgram = getNoomEventProgram(result);
    if (eligibleProgram) {
      params.eligibleProgram = eligibleProgram;
    }
    trackAnonEvent("b2bEnrollmentAnonRetriageResultPresented", params);
  }, [result]);

  return (
    <BaseSuccess
      onClickNext={() => onClickNext(null)}
      questionId={QUESTION_ID}
      headingText={t(
        `retriageResult.${result}.heading`,
        `We've got just the program for you, ${firstName}.`,
      )}
      bodyContent={t(
        `retriageResult.${result}.body`,
        "Based on your answers, we're confident that your current Noom program is the best match for you. Let's keep Noomin'!",
      )}
      ctaText={
        result === ResultValue.CURRENT_PROGRAM && !isMobile
          ? ""
          : t(`retriageResult.${result}.cta`, "Next")
      }
    />
  );
};

export const Q_RETRIAGE_RESULT: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => true,
  component: RetriageResult,
};
