/**
 * Top level component
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { LoaderView, ThemeProvider } from "@noom/wax-component-library";

import { useTrackEvent } from "@/hooks";
import { bootstrapConsent } from "@/utils/consent";
import { initializeGlobalVendorScripts } from "@/utils/vendors";
import { DeeplinkView } from "@/views/DeeplinkView";
import { DevView } from "@/views/DevView";
import { DoNotShareView } from "@/views/DoNotShareView";
import { DppTriageView } from "@/views/DppTriageView";
import { NotFoundView } from "@/views/NotFoundView";
import { RecurringOutcomesView } from "@/views/RecurringOutcomesView";
import { RetriageView } from "@/views/RetriageView";
import { TransitionView } from "@/views/TransitionView";
import { ErrorView } from "@/views/error";
import { ReskinView } from "@/views/reskin";
import { SuccessView } from "@/views/reskin/success/SuccessView";
import { VirginPulseRedirect } from "@/views/reskin/virgin-pulse/VirginPulseRedirect";

import { init as init18n } from "./i18n";
import { reskinTheme } from "./themes/reskin/theme";

init18n();

const AppInitializer = ({ children }: { children: React.ReactNode }) => {
  const { trackIdentifiedEvent } = useTrackEvent();

  useEffect(() => {
    bootstrapConsent(trackIdentifiedEvent).then(() => {
      // Need to wait for consent to be reset as prior consent may not match new consent
      initializeGlobalVendorScripts();
    });
  }, []);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const App: React.FC = () => (
  <ThemeProvider resetCSS theme={reskinTheme}>
    <BrowserRouter>
      <AppInitializer>
        <Suspense fallback={<LoaderView />}>
          <Routes>
            <Route path="/deeplink" element={<DeeplinkView />} />
            <Route path="/do-not-share" element={<DoNotShareView />} />
            <Route
              path="/employer/firestone"
              element={
                <Navigate replace to="/employer/holcimbuildingenvelope" />
              }
            />
            {/* The /enroll route was used in a previous version of the app
                See: https://noom.slack.com/archives/C039LQPS6KZ/p1673385869755929 */}
            <Route path="/enroll" element={<Navigate replace to="/" />} />
            <Route path="/error" element={<ErrorView />} />
            <Route path="/success" element={<SuccessView />} />
            <Route path="/virgin-pulse" element={<VirginPulseRedirect />} />
            <Route path="/enrollment/*" element={<DppTriageView />} />
            <Route
              path="/recurring-wellness-survey/*"
              element={<RecurringOutcomesView />}
            />
            <Route path="/transition/*" element={<TransitionView />} />
            <Route
              path="/employer/:employerSlug/rt/*"
              element={<RetriageView />}
            />
            <Route path="/employer/:employerSlug" element={<ReskinView />} />
            <Route path="/_dev/:questionSetName/*" element={<DevView />} />
            <Route path="/" element={<ReskinView />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        </Suspense>
      </AppInitializer>
    </BrowserRouter>
  </ThemeProvider>
);

export default App;
