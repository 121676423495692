import { CountryRegionData } from "react-country-region-selector";

// Format of CountryRegionData:
// [["Long Country Name","ShortName","Region1~Region1Short|Region2~Region2Short|..."]]
export const getRegionAbbreviation = (
  countryCode: string,
  regionLong: string,
) =>
  CountryRegionData.find((country) => country[1] === countryCode)?.[2]
    .split("|")
    .find((regionData) => regionData.includes(regionLong))
    ?.split("~")[1];
