import GoogleAdwordsHandlers from "./adwords";
import FacebookHandlers from "./facebook";
import HealthlineHandlers from "./healthline";
import HorizonHandlers from "./horizon";

export function initializePublishers() {
  FacebookHandlers();
  GoogleAdwordsHandlers();
  HealthlineHandlers();
  HorizonHandlers();
}
