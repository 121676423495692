import { getIn, useFormikContext } from "formik";
import i18next from "i18next";
import React from "react";
import { CountryRegionData } from "react-country-region-selector";
import * as Yup from "yup";

import { Input, Select, Spacing, Stack } from "@noom/wax-component-library";

import { defaultFieldStyles } from "@/app/themes/reskin/defaultFieldStyles";
import { DEFAULT_ADDRESS } from "@/constants";
import { Address } from "@/models";

type AddressFieldName = keyof Address;
type Props = {
  countryCode: string;
  countrySelectDisabled?: boolean;
  formName?: string;
};

// Maps the order in which address fields should be displayed
const addressFieldPositionCountryMap: Record<string, AddressFieldName[]> = {
  US: ["address1", "address2", "city", "region", "zipcode"],
  CA: ["address1", "address2", "city", "region", "zipcode"],
  AU: ["address1", "address2", "zipcode", "city", "region"],
  GB: ["address1", "address2", "city", "zipcode"],
  default: ["address1", "address2", "city", "region", "zipcode"],
};

const STREET_MAX_LENGTH = 120;
const CITY_REGION_MAX_LENGTH = 100;
const ZIPCODE_MAX_LENGTH = 20;

const getTranslationForCountryCode = (
  formName: string,
  localTranslationKey: string,
  countryCode: string,
): string =>
  i18next.t([
    `form.${formName}.${localTranslationKey}.${countryCode}`,
    `form.${formName}.${localTranslationKey}.default`,
  ]);

const shouldValidateFieldForCountry = (
  field: AddressFieldName,
  countryCode: string,
) =>
  addressFieldPositionCountryMap[countryCode]?.includes(field) ||
  (countryCode && addressFieldPositionCountryMap[countryCode] === undefined);

export const buildAddressSchema = (formName: string) => {
  // returns an error message with the form field's label interpolated into the message
  const tError = (
    errorKey: "required" | "maxLength",
    fieldName: string,
    countryCode: string,
    props?: object,
  ): string => {
    const label = getTranslationForCountryCode(
      formName,
      `${fieldName}.label`,
      countryCode,
    );
    return i18next.t(`form.errors.${errorKey}`, { ...props, label });
  };

  return Yup.object({
    country: Yup.string().required(() => tError("required", "country", "")),
    region: Yup.string()
      .when("country", (country: string, schema) =>
        schema.max(
          CITY_REGION_MAX_LENGTH,
          tError("maxLength", "region", country, {
            maxLength: CITY_REGION_MAX_LENGTH,
          }),
        ),
      )
      .when("country", (country: string, schema) =>
        shouldValidateFieldForCountry("region", country)
          ? schema.required(tError("required", "region", country))
          : schema,
      ),
    city: Yup.string()
      .when("country", (country: string, schema) =>
        schema.max(
          CITY_REGION_MAX_LENGTH,
          tError("maxLength", "city", country, {
            maxLength: CITY_REGION_MAX_LENGTH,
          }),
        ),
      )
      .when("country", (country: string, schema) =>
        shouldValidateFieldForCountry("city", country)
          ? schema.required(tError("required", "city", country))
          : schema,
      ),
    address1: Yup.string()
      .when("country", (country: string, schema) =>
        schema.max(
          STREET_MAX_LENGTH,
          tError("maxLength", "address1", country, {
            maxLength: STREET_MAX_LENGTH,
          }),
        ),
      )
      .when("country", (country: string, schema) =>
        shouldValidateFieldForCountry("address1", country)
          ? schema.required(tError("required", "address1", country))
          : schema,
      ),
    address2: Yup.string().when("country", (country: string, schema) =>
      schema.max(
        STREET_MAX_LENGTH,
        tError("maxLength", "address2", country, {
          maxLength: STREET_MAX_LENGTH,
        }),
      ),
    ),
    zipcode: Yup.string()
      .when("country", (country: string, schema) =>
        schema.max(
          ZIPCODE_MAX_LENGTH,
          tError("maxLength", "zipcode", country, {
            maxLength: ZIPCODE_MAX_LENGTH,
          }),
        ),
      )
      .when("country", (country: string, schema) =>
        shouldValidateFieldForCountry("zipcode", country)
          ? schema.required(tError("required", "zipcode", country))
          : schema,
      ),
  });
};

export const AddressSchema = buildAddressSchema("address");

// Format of CountryRegionData:
// [["Long Country Name","ShortName","Region1~Region1Short|Region2~Region2Short"]]
// This creates an array of tuples where each tuple is [Region, RegionShort]
const getRegionDataForCountry = (countryCode: string): string[][] =>
  CountryRegionData.find((country) => country[1] === countryCode)?.[2]
    .split("|")
    .map((region) => region.split("~")) || [];

export const AddressForm: React.FC<Props> = ({
  countryCode,
  countrySelectDisabled = false,
  formName = "address",
}) => {
  const {
    errors,
    getFieldProps,
    handleChange,
    setFieldError,
    setFieldValue,
    validateField,
  } = useFormikContext();

  const handleInputChange = async (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
    fieldName: string,
  ) => {
    // This is a hacky method to get each field to validate on change
    // individually.
    await handleChange(e);
    validateField(fieldName);
  };

  const formPath = (field: AddressFieldName) => `${formName}.${field}`;
  const getError = (field: AddressFieldName) => getIn(errors, formPath(field));

  const addressFieldComponentMap: Record<AddressFieldName, JSX.Element> = {
    country: (
      <Select
        {...getFieldProps(formPath("country"))}
        disabled={countrySelectDisabled}
        error={getError("country")}
        label={getTranslationForCountryCode(
          formName,
          "country.label",
          countryCode,
        )}
        maxWidth={defaultFieldStyles.maxWidth}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          // Since which address fields are displayed is a function of the country,
          // we'll reset the address form values to ensure only the latest inputs are recorded.
          setFieldValue(formName, DEFAULT_ADDRESS);
          setFieldError(formPath("address1"), "");
          setFieldError(formPath("city"), "");
          setFieldError(formPath("region"), "");
          setFieldError(formPath("zipcode"), "");

          setFieldValue(formPath("country"), e.target.value);
          validateField(formPath("country"));
        }}
        placeholder={getTranslationForCountryCode(
          formName,
          "country.placeholder",
          countryCode,
        )}
      >
        {CountryRegionData.map((country) => (
          <option key={country[1]} value={country[1]}>
            {country[0]}
          </option>
        ))}
      </Select>
    ),
    region: (
      <Select
        {...getFieldProps(formPath("region"))}
        error={getError("region")}
        label={getTranslationForCountryCode(
          formName,
          "region.label",
          countryCode,
        )}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          handleInputChange(e, formPath("region"))
        }
        maxWidth={defaultFieldStyles.maxWidth}
        placeholder={getTranslationForCountryCode(
          formName,
          "region.placeholder",
          countryCode,
        )}
      >
        {getRegionDataForCountry(countryCode).map(([label, value]) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    ),
    city: (
      <Input
        {...getFieldProps(formPath("city"))}
        error={getError("city")}
        label={getTranslationForCountryCode(
          formName,
          "city.label",
          countryCode,
        )}
        onChange={(e) => handleInputChange(e, formPath("city"))}
        placeholder={getTranslationForCountryCode(
          formName,
          "city.placeholder",
          countryCode,
        )}
      />
    ),
    address1: (
      <Input
        {...getFieldProps(formPath("address1"))}
        error={getError("address1")}
        helper={
          formName === "address"
            ? "We need your address to comply with tax regulations."
            : undefined
        }
        label={getTranslationForCountryCode(
          formName,
          "address1.label",
          countryCode,
        )}
        onChange={(e) => handleInputChange(e, formPath("address1"))}
        placeholder={getTranslationForCountryCode(
          formName,
          "address1.placeholder",
          countryCode,
        )}
      />
    ),
    address2: (
      <Input
        {...getFieldProps(formPath("address2"))}
        error={getError("address2")}
        label={getTranslationForCountryCode(
          formName,
          "address2.label",
          countryCode,
        )}
        onChange={(e) => handleInputChange(e, formPath("address2"))}
        placeholder={getTranslationForCountryCode(
          formName,
          "address2.placeholder",
          countryCode,
        )}
      />
    ),
    zipcode: (
      <Input
        {...getFieldProps(formPath("zipcode"))}
        error={getError("zipcode")}
        label={getTranslationForCountryCode(
          formName,
          "zipcode.label",
          countryCode,
        )}
        onChange={(e) => handleInputChange(e, formPath("zipcode"))}
        placeholder={getTranslationForCountryCode(
          formName,
          "zipcode.placeholder",
          countryCode,
        )}
      />
    ),
  };

  const addressFieldConfig =
    addressFieldPositionCountryMap[countryCode] ||
    addressFieldPositionCountryMap.default;

  return (
    <Stack spacing={Spacing[8]}>
      {addressFieldComponentMap.country}
      {addressFieldConfig.map((field) => (
        <React.Fragment key={field}>
          {addressFieldComponentMap[field]}
        </React.Fragment>
      ))}
    </Stack>
  );
};
