import { AxiosError } from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import { Api } from "@noom/noomscape";

import {
  ErrorCode,
  ErrorState,
  ProgramOffering,
  ResultType,
} from "@/constants";
import { useAppContext } from "@/contexts";
import { useQueryParams } from "@/hooks/useQueryParams";
import { captureException } from "@/utils/sentry";

import { useEnrollMed } from "./useEnrollMed";
import { useTrackEvent } from "./useTrackEvent";

export const useTransitionB2CAccount = () => {
  const { setErrorState, userData } = useAppContext();
  const { enrollMed } = useEnrollMed();
  const { upid } = useQueryParams();
  const navigate = useNavigate();
  const { linkB2BIdentity, trackAnonEvent, trackIdentifiedEvent } =
    useTrackEvent();

  const transitionB2CAccount = async (
    selectedProgram: ProgramOffering | null,
  ): Promise<string | undefined> => {
    if (!userData || !upid) {
      setErrorState(ErrorState.DEFAULT);
      navigate("/error");
      return undefined;
    }

    try {
      const { accessCode } = await Api.call(
        "account.migrateToB2B",
        Api.api.account.migrateToB2B,
        {
          upid,
          userData,
        },
      );

      if (selectedProgram === "MED") {
        await enrollMed(accessCode, userData);
      }

      // Set cookie expiration to 1 hour
      Cookies.set("lastEnrolledUpid", upid, { expires: 1 / 24 });

      linkB2BIdentity(userData.email, upid, accessCode);

      trackIdentifiedEvent("b2bEnrollmentIdentifiedSignupSubmitted", {
        b2bProgram: selectedProgram || "PROGRAM_UNSPECIFIED",
        b2bSignupResult: ResultType.SUCCESS,
        b2cTransitionedAccount: true,
        freeAccountExists: false,
        upid,
      });

      trackAnonEvent("b2bEnrollmentAnonSignupSubmitted", {
        b2bProgram: selectedProgram || "PROGRAM_UNSPECIFIED",
        b2bSignupResult: ResultType.SUCCESS,
        b2cTransitionedAccount: true,
        freeAccountExists: false,
      });
      return accessCode;
    } catch (e: unknown) {
      const errorMessage = (e as AxiosError<WebApiErrorResponse>)?.response
        ?.data?.errorCode;
      const isIneligible = errorMessage === "B2B_INELIGIBLE";
      const migrationRequestCreated =
        errorMessage === "B2B_MIGRATION_REQUEST_CREATED";

      const medEnrollmentFailed =
        (e as Error)?.message === ErrorCode.MED_ENROLLMENT_FAILED;

      if (isIneligible) {
        throw new Error("B2B_INELIGIBLE");
      }

      const resultType = ResultType.ERROR;
      if (migrationRequestCreated || medEnrollmentFailed) {
        setErrorState(ErrorState.SUPPORT_REQUEST_CREATED);
      } else {
        setErrorState(ErrorState.DEFAULT);
      }

      trackIdentifiedEvent("b2bEnrollmentIdentifiedSignupSubmitted", {
        b2bProgram: selectedProgram || "PROGRAM_UNSPECIFIED",
        b2bSignupResult: resultType,
        b2cTransitionedAccount: true,
        freeAccountExists: false,
        upid,
      });

      trackAnonEvent("b2bEnrollmentAnonSignupSubmitted", {
        b2bProgram: selectedProgram || "PROGRAM_UNSPECIFIED",
        b2bSignupResult: resultType,
        b2cTransitionedAccount: true,
        freeAccountExists: false,
      });

      captureException(e);
      navigate("/error");
      return undefined;
    }
  };

  return {
    transitionB2CAccount,
  };
};
