import React, { Fragment, useEffect, useState } from "react";

import { Box, CompassColor, Image } from "@noom/wax-component-library";

const BAR_ZINDEX = 50;
const ICON_ZINDEX = 100;

const ICON_SIZE_HALF = 5;
const ICON_SIZE_FULL = 10;

export type SurveyProgressBarProps = {
  currentProgress: number;
  currentSectionIndex: number;
  lastProgress: number;
  percentages: number[];
};

/**
 * Calculates the elements needed to render the progress bar with the correct
 * bars, icons, and styles for all pieces of the complete bar.
 *
 * @param percentages The list of percentages that the bar should be divided
 * into (e.g. [25, 50, 25] means the bar will be divided into three parts with
 * the inner bar being twice as long as the end bars).
 * @param currentSectionIndex The index of the current section, used to
 * determine the coloring and icons for the rest of the sections.
 * @param progress The progress within the current section (e.g. 35 is 35%
 * within the current section).
 * @returns an array of elements used to render the progress bar data
 */
const getSectionBars = (
  percentages: number[],
  currentSectionIndex: number,
  progress: number,
  shouldAnimate: boolean,
) => {
  const bars = [];
  let sectionPercentageRunningSum = 0;

  for (let i = 0; i < percentages.length; i += 1) {
    const percentage = percentages[i];
    const isCompletedSection = i < currentSectionIndex;
    const isCurrentSection = i === currentSectionIndex;

    const isFirstSection = i === 0;
    const isLastSection = i === percentages.length - 1;
    const isEndSection = isFirstSection || isLastSection;

    let barColor: CompassColor = CompassColor.offWhite;
    if (isCompletedSection) {
      barColor = CompassColor.lagoon;
    } else if (isCurrentSection) {
      barColor = CompassColor.stream;
    }

    // The bar should extend beyond the icon in order to contain the icon. The
    // distance is half the width of an icon if it is an end bar, otherwise the
    // full width of an icon.
    const barWidth = `calc(${percentage}% + ${
      isEndSection ? ICON_SIZE_HALF : ICON_SIZE_FULL
    }px)`;

    // The bar should overlap with the icon so it is moved back halfway the
    // size of the icon if the bar is not the first one.
    const barLeftOffset = `calc(${sectionPercentageRunningSum}% - ${
      isFirstSection ? 0 : ICON_SIZE_HALF
    }px)`;

    sectionPercentageRunningSum += percentage;

    // The icon should fit completely inside the bar if it is the last one,
    // otherwise it should be halfway between bars
    const iconLeftOffset = `calc(${sectionPercentageRunningSum}% - ${
      isLastSection ? ICON_SIZE_FULL : ICON_SIZE_HALF
    }px)`;

    bars.push(
      <Fragment key={i}>
        <Box
          height="100%"
          left={barLeftOffset}
          position="absolute"
          width={barWidth}
        >
          <Box
            backgroundColor={barColor}
            borderRadius="5px"
            height="100%"
            position="absolute"
            transition={shouldAnimate ? "width 500ms ease 0s" : ""}
            width={isCurrentSection ? `${progress}%` : "100%"}
            // We subtract one for subsequent bars so that future bars are rendered
            // below previous bars.
            zIndex={BAR_ZINDEX - i}
          />
        </Box>
        {percentages.length > 1 && (
          <Image
            alt=""
            height="100%"
            left={iconLeftOffset}
            position="absolute"
            src={
              isCompletedSection
                ? "/assets/img/checkmark.svg"
                : "/assets/img/diamond.svg"
            }
            zIndex={ICON_ZINDEX}
          />
        )}
      </Fragment>,
    );
  }

  return bars;
};

export const SurveyProgressBar: React.FC<SurveyProgressBarProps> = ({
  currentProgress,
  currentSectionIndex,
  lastProgress,
  percentages,
}) => {
  const [progress, setProgress] = useState(currentProgress || lastProgress);
  const [sectionIndex, setSectionIndex] = useState(currentSectionIndex);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  useEffect(() => {
    // This allows us to animate the progress difference when the props change
    if (progress !== currentProgress) {
      setTimeout(() => setProgress(currentProgress), 10);
    }
  }, [currentProgress, progress]);

  useEffect(() => {
    // This prevents a bug where switching to the first question in a new
    // section will cause the bar to seem like it's going backwards
    setShouldAnimate(sectionIndex === currentSectionIndex);
    setSectionIndex(currentSectionIndex);
  }, [currentSectionIndex]);

  return (
    <Box
      background={CompassColor.offWhite}
      borderRadius="5px"
      display="flex"
      height={`${ICON_SIZE_FULL}px`}
      margin="0 auto var(--spacing-m)"
      maxWidth="400px"
      position="relative"
      width="100%"
    >
      {getSectionBars(
        percentages,
        currentSectionIndex,
        progress,
        shouldAnimate,
      )}
    </Box>
  );
};
