/**
 * Endpoint for getting an upid for a given Virgin pulse authorization code
 *
 * Copyright (C) 2021D Noom, Inc.
 * @author nikola
 */

import axios from "axios";

import { Api } from "@noom/noomscape";

export type VirginPulseGenerateUpidResponseError = {
  email: string;
};

Api.register(
  "upid.getVirginPulse",
  async ({
    authorizationCode,
    redirectUri,
  }: {
    authorizationCode: string;
    redirectUri: string;
  }) => {
    const response = await axios({
      url: "/virginpulse/v1/users:generateUpid",
      urlWithPathParams: "/virginpulse/v1/users:generateUpid",
      baseURL: window.ENV.SERVER_URL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        authorizationCode,
        redirectUri,
      },
    });

    return response.data as string;
  },
);
