import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "@/contexts";

export type SurveyProgressBarState = {
  currentProgress: number;
  currentQuestionId: string;
  lastProgress: number;
  currentSectionDescription: string;
  currentSectionIndex: number;
  percentages: number[];
};

export const useSurveyProgressBar = (): SurveyProgressBarState => {
  const { questionSet, surveyProgressBarState, setSurveyProgressBarState } =
    useAppContext();
  const { pathname } = useLocation();

  const { currentProgress, currentQuestionId, currentSectionIndex } =
    surveyProgressBarState;
  const questionId = pathname.split("/").pop() || "";

  useEffect(() => {
    if (!questionSet.length) {
      return;
    }

    if (questionId === currentQuestionId) {
      return;
    }

    const newSectionIndex = questionSet.findIndex((section) =>
      section.questions.find((definition) => definition.id === questionId),
    );
    const { description, questions } = questionSet[newSectionIndex];

    const questionIndex = questions.findIndex(
      (definition) => definition.id === questionId,
    );

    const newProgress = (questionIndex / questions.length) * 100;

    setSurveyProgressBarState({
      currentProgress: newProgress,
      currentQuestionId: questionId,
      currentSectionDescription: description,
      currentSectionIndex: newSectionIndex,
      lastProgress:
        newSectionIndex !== currentSectionIndex ? 0 : currentProgress,
      percentages: questionSet.flatMap((section) => section.percentage),
    });
  }, [
    questionId,
    questionSet,
    currentProgress,
    currentQuestionId,
    currentSectionIndex,
    setSurveyProgressBarState,
  ]);

  return surveyProgressBarState;
};
