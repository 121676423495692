import { AxiosError } from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Api } from "@noom/noomscape";

import { VirginPulseGenerateUpidResponseError } from "@/api/upid/getVirginPulse";
import { ErrorState } from "@/constants";
import { VIRGIN_PULSE_PATH } from "@/constants/VirginPulse";
import { useAppContext } from "@/contexts";
import { captureException } from "@/utils/sentry";

const defaultState = "vp-7Nf7uAwTcKrs9WFRk5ZD";

function getFormattedVirginPulseUrl(
  redirectUri: string,
  state: string = defaultState,
): string {
  const url =
    window.ENV.VIRGIN_PULSE_URL +
    VIRGIN_PULSE_PATH.replace(
      ":redirectURL",
      encodeURIComponent(redirectUri),
    ).replace(":state", encodeURIComponent(state));

  return url;
}

export const VirginPulseRedirect: React.FC = () => {
  const { setErrorState, setMaskedEmail } = useAppContext();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const code = queryParams.get("code");
  const state = queryParams.get("state");
  const redirectUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

  const handleAuthorizationCodeValidation = async (
    authorizationCode: string,
    redirectUri: string,
  ) => {
    try {
      const { upid } = await Api.call(
        "upid.getVirginPulse",
        Api.api.upid.getVirginPulse,
        {
          authorizationCode,
          redirectUri,
        },
      );

      if (!upid) {
        throw new Error("Missing UPID");
      }

      const qs = new URLSearchParams({ upid });
      navigate({
        pathname: "/",
        search: qs.toString(),
      });
    } catch (e: unknown) {
      const userAlreadyAssigned = (e as AxiosError)?.response?.status === 409;

      if (userAlreadyAssigned) {
        const maskedEmail =
          (e as AxiosError<VirginPulseGenerateUpidResponseError>)?.response
            ?.data?.email || "";
        setMaskedEmail(maskedEmail);
        setErrorState(ErrorState.EXISTING_VIRGIN_PULSE_USER);
      } else {
        captureException(e);
        setErrorState(ErrorState.DEFAULT);
      }

      navigate("/error");
    }
  };

  useEffect(() => {
    if (!code) {
      window.location.replace(getFormattedVirginPulseUrl(redirectUri));
    } else {
      handleAuthorizationCodeValidation(code, redirectUri);
    }
  }, [code, state]);

  return <></>;
};
