import React from "react";

import {
  Box,
  CompassColor,
  Heading,
  Image,
  Spacing,
  Stack,
  Text,
} from "@noom/wax-component-library";

export type ItemDefinition = {
  text: string;
  image: string;
  alt?: string;
};

type ComponentProps = {
  items: Array<ItemDefinition>;
  headingText?: string;
};

export const ImageBulletListCard: React.FC<ComponentProps> = ({
  items,
  headingText,
}) => (
  <Box
    backgroundColor={CompassColor.offWhite}
    border={`1px solid ${CompassColor.sand01}`}
    borderRadius="8px"
    padding="16px 12px"
  >
    <Stack spacing={Spacing[8]}>
      {headingText && (
        <Heading fontSize="20px" fontWeight={500} lineHeight="26px">
          {headingText}
        </Heading>
      )}
      {items.map(({ image, text, alt }) => (
        <Box display="flex" alignItems="flex-start" key={image} gap="12px">
          <Image alt={alt} src={image} />
          <Text>{text}</Text>
        </Box>
      ))}
    </Stack>
  </Box>
);
