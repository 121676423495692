import React from "react";
import { Trans } from "react-i18next";

import {
  Box,
  Button,
  CompassColor,
  Image,
  Text,
  useMediaQuery,
} from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { BaseInterstitial } from "@/components/survey/questionTypes/core";
import { PregnantSelection } from "@/constants";

const QUESTION_ID = "nursing";

const Nursing: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
}) => {
  const [isBrowser] = useMediaQuery("(min-width: 1160px)");

  return (
    <BaseInterstitial
      backgroundColor={CompassColor.sky}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      questionId={QUESTION_ID}
      width={isBrowser ? "600px" : undefined}
    >
      <Image
        height={{ base: "48px", lg: "64px" }}
        margin="var(--spacing-l) 0px var(--spacing-s) 0px"
        src="/assets/img/diamond-tarocco.svg"
      />
      <Text>
        <Trans i18nKey="survey.questions.nursing.message">
          Thanks for sharing!
          <strong>
            When you're nursing, you may have different weight loss needs, so
            we'll adjust your plan.
          </strong>
          <br />
          <br />
          Once you feel ready to begin your weight loss journey post partum, we
          recommend discussing any specific adjustments with a medical
          professional to safely lose weight with Noom. Noom will allow you to
          manually customize your weight loss pace to match your needs both
          during and after nursing.
        </Trans>
      </Text>
      <Box display="flex" justifyContent="center" marginTop="var(--spacing-xl)">
        <Button
          autoFocus
          colorScheme="primary"
          onClick={() => onClickNext(null)}
          size="xl"
          _focus={{
            boxShadow: "none",
          }}
        >
          Next
        </Button>
      </Box>
    </BaseInterstitial>
  );
};

export const Q_NURSING: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({ selectedProgram, surveyAnswers: { pregnant } }) =>
    selectedProgram !== "MOOD" && pregnant === PregnantSelection.NO_BUT_NURSING,
  component: Nursing,
};
