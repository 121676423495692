import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Api } from "@noom/noomscape";

import { ErrorState } from "@/constants";
import { useAppContext } from "@/contexts";
import {
  EligibilityInfo,
  EligibilityReason,
  EligibilityResponse,
  EligibilityVerificationProperties,
  Partner,
} from "@/models";
import { parseEligibilityFileDate } from "@/utils/eligibility";
import { captureException } from "@/utils/sentry";

import { useGoto } from "./useGoto";
import { QueryParamsWithoutPii, useQueryParams } from "./useQueryParams";
import { useTrackEvent } from "./useTrackEvent";

export type EligibilityResult = {
  eligibilityInfo: EligibilityInfo;
  eligibilityReason: EligibilityReason;
  isEligible: boolean;
  isMedEligible: boolean;
  participantEligibilityId: string | undefined;
  participantPartnerId: number | undefined;
  upid: string | undefined;
};

export const useEligibility = () => {
  const { setEligibilityInfo } = useAppContext();
  const goto = useGoto();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const [hasEligibilityError, setHasEligibilityError] =
    useState<boolean>(false);
  const { trackAnonEvent, trackIdentifiedEvent } = useTrackEvent();

  const redirectToParticipantPartner = async (
    eligibilityId: string | undefined,
    participantPartnerId: number | undefined,
    upid: string | undefined,
  ) => {
    if (!(!!eligibilityId && !!participantPartnerId && !!upid)) {
      captureException(
        Error(
          "Attempted to redirect for shared eligibility partner(s) but required redirect params are missing",
        ),
      );
      navigate("/error");
      return;
    }
    const partner: Partner = await Api.call(
      "partner.getPartnerByUpid",
      Api.api.partner.getPartnerByUpid,
      { upid },
    );

    const newParams: QueryParamsWithoutPii = {
      ...queryParams,
      eligibilityId,
      sharedEligibility: "true",
      upid,
    };
    const qs = new URLSearchParams(
      Object.entries(newParams).filter(([, v]) => v !== undefined),
    );

    window.location.href = `${window.location.origin}/employer/${partner.slug}?${qs.toString()}`;
  };

  const checkEligibility = async (
    eligibilityVerificationProperties: EligibilityVerificationProperties,
    partnerId: number,
    leadSource?: string,
  ): Promise<EligibilityResult> => {
    // Reset the eligibility error
    setHasEligibilityError(false);
    const {
      dateOfBirth,
      email,
      effectiveDate: effectiveDateString,
      eligible: isEligible,
      eligibilityId: participantEligibilityId,
      eligibilityReason,
      extras,
      firstName,
      lastName,
      medEligible: isMedEligible,
      partnerId: participantPartnerId,
      participantId: employeeId,
      upid,
    }: EligibilityResponse = await Api.call(
      "partner.checkEligibility",
      Api.api.partner.checkEligibility,
      {
        eligibilityVerificationProperties,
        leadSource,
        partnerId,
      },
    );

    const eligibilityInfo = {
      dob: parseEligibilityFileDate(dateOfBirth),
      effectiveDate: parseEligibilityFileDate(effectiveDateString),
      email,
      employeeId,
      isMedEligible,
      firstName,
      lastName,
      ...extras,
    };
    setEligibilityInfo({ ...eligibilityInfo });

    const eventParams = {
      eligibleForEnrollment: isEligible,
      eligibilityStatus: eligibilityReason,
    };

    trackAnonEvent("b2bEnrollmentAnonEligibilityChecked", eventParams);
    trackIdentifiedEvent(
      "b2bEnrollmentIdentifiedEligibilityChecked",
      eventParams,
    );

    if (isEligible === false) {
      if (eligibilityReason === EligibilityReason.EXISTING_ACCOUNT) {
        goto.error(ErrorState.ELIGIBILITY_CHECK_EXISTING_ACCOUNT);
      } else if (
        eligibilityReason === EligibilityReason.PRIOR_TO_EFFECTIVE_DATE
      ) {
        goto.error(ErrorState.PRIOR_TO_EFFECTIVE_DATE);
      } else {
        setHasEligibilityError(true);
      }
    }

    return {
      eligibilityInfo,
      eligibilityReason,
      isEligible,
      isMedEligible,
      participantEligibilityId,
      participantPartnerId,
      upid,
    };
  };

  const fetchEligibilityInfo = async (
    eligibilityId: string,
    partnerId: number,
  ) =>
    Api.call("partner.checkEligibility", Api.api.partner.checkEligibility, {
      eligibilityVerificationProperties: { eligibilityId },
      partnerId,
    });

  return {
    checkEligibility,
    fetchEligibilityInfo,
    hasEligibilityError,
    redirectToParticipantPartner,
    setHasEligibilityError,
  };
};
