import React, { useEffect, useState } from "react";

import { Box, Image, useMediaQuery } from "@noom/wax-component-library";

// The confetti gifs are 500x500 (desktop) and 300x300 (mobile), but they both
// have whitespace at the top and bottom each equal to 24% of the height. The
// whitespace therefore takes up 48% of the height, leaving 52% as the actual
// height we're looking for (52% * 500 = 260, 52% * 300 = 156)
const CONFETTI_HEIGHT_DESKTOP = "260px";
const CONFETTI_HEIGHT_MOBILE = "156px";
const CONFETTI_WIDTH_DESKTOP = "500px";
const CONFETTI_WIDTH_MOBILE = "300px";

export const ConfettiBox = () => {
  const [isBrowser] = useMediaQuery("(min-width: 1160px)");
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsAnimationComplete(true), 3000);
  }, []);

  return (
    <Box
      alignItems="center"
      display="flex"
      height={{
        base: CONFETTI_HEIGHT_MOBILE,
        lg: CONFETTI_HEIGHT_DESKTOP,
      }}
      justifyContent="center"
      width={{
        base: CONFETTI_WIDTH_MOBILE,
        lg: CONFETTI_WIDTH_DESKTOP,
      }}
    >
      <Image
        alt=""
        // The padding on each side creates a 144x144 checkmark based
        // on the confetti container dimensions
        // [mobile] 156x300 - 144x144 = 12x156 space left / 2 = 6x78
        // [desktop] 260x500 - 144x144 = 116x356 space left / 2 = 58x178
        padding={{
          base: "6px 78px",
          lg: "58px 178px",
        }}
        src="/assets/img/small-checkmark.svg"
        width="100%"
      />
      {!isAnimationComplete && (
        <Image
          alt=""
          height={{
            base: CONFETTI_HEIGHT_MOBILE,
            lg: CONFETTI_HEIGHT_DESKTOP,
          }}
          // This allows the confetti to be stacked on top of the checkmark
          marginLeft={{
            base: `-${CONFETTI_WIDTH_MOBILE}`,
            lg: `-${CONFETTI_WIDTH_DESKTOP}`,
          }}
          objectFit="cover"
          src={
            isBrowser
              ? "/assets/img/confetti-desktop.gif"
              : "/assets/img/confetti-mobile.gif"
          }
          width={{
            base: CONFETTI_WIDTH_MOBILE,
            lg: CONFETTI_WIDTH_DESKTOP,
          }}
        />
      )}
    </Box>
  );
};
