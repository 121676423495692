import axios from "axios";

import { Api } from "@noom/noomscape";

export type UpdateLastTriageTimestampRequest = {
  accessCode: string;
  newLastTriageTimestamp: string;
};

Api.register(
  "dpp.updateLastTriageTimestamp",
  (requestBody: UpdateLastTriageTimestampRequest) =>
    axios({
      baseURL: window.ENV.SERVER_URL,
      data: requestBody,
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      url: "/dpp/v1/updateLastTriageTimestamp",
      urlWithPathParams: "/dpp/v1/updateLastTriageTimestamp",
    }),
);
