import React, { useState } from "react";

import { Box, CompassColor, Image } from "@noom/wax-component-library";

import { ASSET_URL } from "@/constants";
import { DPP_PARTNERS } from "@/constants/Partners";
import { useAppContext } from "@/contexts";
import { getDisplayName } from "@/models";
import { getAutologinData } from "@/utils/cookies";

export const NoomHeader: React.FC<{ forceHeader?: boolean }> = ({
  forceHeader,
}) => {
  const { partnerInfo } = useAppContext();
  const [employerLogoError, setEmployerLogoError] = useState(false);

  const autologinData = getAutologinData();

  // Hide header if B2B WEA is loaded through a webview in the Noom app because the Noom app already loads its own header
  if (!forceHeader && autologinData) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <Box
      display="flex"
      gap="var(--spacing-s)"
      padding={{
        base: "var(--spacing-l) var(--spacing-m)",
        lg: "var(--spacing-l) var(--spacing-xl)",
      }}
    >
      <Image alt="Noom" height="16px" src={`/assets/img/noom-logo-black.svg`} />
      {partnerInfo?.slug && !employerLogoError && (
        <>
          <Box backgroundColor={CompassColor.black} width="2px" />
          <Image
            alt={getDisplayName(partnerInfo)}
            height="16px"
            onError={() => setEmployerLogoError(true)}
            src={
              partnerInfo.isDppPartner || DPP_PARTNERS.includes(partnerInfo.id)
                ? `${ASSET_URL}/img/${partnerInfo?.slug}/logo-alt.png`
                : `${ASSET_URL}/img/${partnerInfo?.slug}/logo.png`
            }
          />
        </>
      )}
    </Box>
  );
};
