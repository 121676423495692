import React from "react";

import { Curriculum } from "@/constants";
import { partnerOffersPrograms } from "@/utils/programs";

import {
  QuestionComponentProps,
  QuestionDefinition,
  QuestionOption,
} from "../../Question";
import { MultipleChoiceQuestion } from "../core";

const QUESTION_ID = "healthCareProfessional";

declare module "@/contexts" {
  interface SurveyAnswers {
    healthCareProfessional?: string;
  }
}

const options: QuestionOption[] = [
  { id: "doctor", text: "Yes - a doctor or doctor's office" },
  { id: "pharmacist", text: "Yes - a pharmacist" },
  {
    id: "anotherProfessional",
    text: "Yes - another health care professional",
  },
  { id: "no", text: "No" },
];

const HealthCareProfessionalQuestion: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => (
  <MultipleChoiceQuestion
    onClickBack={onClickBack}
    onClickNext={onClickNext}
    options={options}
    questionId={QUESTION_ID}
    questionText="Did a health care professional ask you to join this program?"
    selectedItem={surveyAnswers[QUESTION_ID] || ""}
  />
);

export const Q_HEALTH_CARE_PROFESSIONAL: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    enrollmentInfo,
    partnerInfo,
    selectedProgram,
    surveyAnswers: { dppEligible },
  }) =>
    partnerOffersPrograms(["DPP"], enrollmentInfo, partnerInfo) &&
    selectedProgram !== "MED" &&
    selectedProgram !== "MOOD" &&
    dppEligible === true,
  component: HealthCareProfessionalQuestion,
};

export const Q_RETRIAGE_HEALTH_CARE_PROFESSIONAL: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: ({
    retriageEligibility: { currentProgram, triageCurriculums = [] } = {},
    surveyAnswers: { dppEligible },
  }) =>
    currentProgram === "WEIGHT" &&
    triageCurriculums.includes(Curriculum.DPP) &&
    dppEligible === true,
  component: HealthCareProfessionalQuestion,
};
