import React from "react";

import { Heading, Spacing, Stack, Text } from "@noom/wax-component-library";

type BaseSideFormProps = {
  children?: React.ReactNode;
  headerText?: string;
  helperText: string;
};

export const BaseSideForm: React.FC<BaseSideFormProps> = ({
  children,
  headerText = "Welcome to Noom.",
  helperText,
}) => (
  <Stack spacing={Spacing[8]} maxWidth="343px">
    <Stack spacing={Spacing[2]}>
      <Heading
        fontSize={{ base: "24px", lg: "28px" }}
        fontWeight={500}
        lineHeight={{ base: "32px", lg: "36px" }}
      >
        {headerText}
      </Heading>
      <Text size="lg">{helperText}</Text>
    </Stack>
    {children}
  </Stack>
);
