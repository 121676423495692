import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { B2BEnrollmentEnumsProto_AccountType } from "@noom/noom-contracts/noom_contracts/events/web/b2b/shared";

import { EnrollmentSuccessType } from "@/constants";
import { useAppContext } from "@/contexts";
import { useTrackEvent } from "@/hooks";

import { DownloadView } from "./DownloadView";
import { ProgressView } from "./ProgressView";

export const SuccessView: React.FC = () => {
  const { b2bIdentifiedUserId, selectedProgram } = useAppContext();
  const { search } = useLocation();
  const [isDoneShowingProgress, setIsDoneShowingProgress] = useState(false);
  const { trackIdentifiedEvent } = useTrackEvent();

  const type =
    (new URLSearchParams(search).get("type") as EnrollmentSuccessType) ||
    EnrollmentSuccessType.CREATED;

  useEffect(() => {
    const accountTypeMap: Record<
      EnrollmentSuccessType,
      B2BEnrollmentEnumsProto_AccountType
    > = {
      [EnrollmentSuccessType.CREATED]: "CREATED",
      [EnrollmentSuccessType.TRANSITIONED]: "TRANSITIONED",
      [EnrollmentSuccessType.UPGRADED]: "UPGRADED",
    };

    trackIdentifiedEvent("b2bEnrollmentIdentifiedSuccessPageViewed", {
      accountType: accountTypeMap[type],
    });
  }, [type]);

  useEffect(() => {
    if (selectedProgram === "MED" && isDoneShowingProgress) {
      window.location.href = `${window.ENV.BUYFLOW_CLIENT_URL}/telehealth?userId=${b2bIdentifiedUserId}&isB2B=true#/steadyPatientIntake`;
    }
  }, [isDoneShowingProgress]);

  return isDoneShowingProgress && selectedProgram !== "MED" ? (
    <DownloadView enrollmentSuccessType={type} />
  ) : (
    <ProgressView
      enrollmentSuccessType={type}
      selectedProgram={selectedProgram}
      setIsDoneShowingProgress={setIsDoneShowingProgress}
    />
  );
};
