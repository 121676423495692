import React, { ReactNode } from "react";

export const NoomVirtualAssistantLink: React.FC<{ children: ReactNode }> = ({
  children,
}) => (
  <a
    href="https://www.noom.com/support/?ft-action=1"
    target="_blank"
    rel="noreferrer"
  >
    {children}
  </a>
);
