import React, { useEffect } from "react";
import { isBrowser } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { Image } from "@noom/wax-component-library";

import { ContinueToDownloadButton, FullscreenMessage } from "@/components";
import { useTrackEvent } from "@/hooks";

export const PostEnrollmentRefreshView: React.FC = () => {
  const { trackIdentifiedPageViewed } = useTrackEvent();
  const { t } = useTranslation();

  useEffect(() => {
    trackIdentifiedPageViewed("postEnrollmentRefresh");
  }, []);

  return (
    <FullscreenMessage
      button={isBrowser ? undefined : <ContinueToDownloadButton />}
      heading={t("success.postEnrollmentRefresh.heading")}
      message={t("success.postEnrollmentRefresh.message", {
        context: isBrowser ? "desktop" : "mobile",
      })}
      visual={<Image alt="" margin="auto" src="/assets/img/thumbs-up.svg" />}
    />
  );
};
