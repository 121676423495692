import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

import { CompassColor, Image } from "@noom/wax-component-library";

import { useTrackEvent } from "@/hooks";

import { FadeIn, FadeInAndOut } from "./animation/fade";

const open = keyframes`
  from {
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    opacity: 0;
  }

  to {
    height: 300vh;
    width: 300vh;
    top: calc(50% - 150vh);
    left: calc(50% - 150vh);
    opacity: 1;
  }
  `;

const ExpandingCircleCover = styled.div`
  position: fixed;
  background-color: ${CompassColor.offWhite};
  border-radius: 50%;
  opacity: 0;

  animation: ${open};
  animation-duration: 2s;
  animation-delay: 3.5s;
  animation-fill-mode: forwards;
`;

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: ${CompassColor.blueberry};

  h1 {
    font-family: "Brown LL", sans-serif;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.03em;
    color: ${CompassColor.white};
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 24px;
  }
`;

const slideUpSmall = keyframes`
  from {
    opacity: 0;
    transform: translate(0, 20px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
  `;

const Logo = styled.img`
  opacity: 0;
  animation-name: ${slideUpSmall};
  animation-duration: 0.5s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  height: 36px;
`;

const Slide = styled.div<{ bgColor?: string }>`
  position: fixed;
  height: 100vh;
  padding-top: 30vh;
  background-color: ${(props) => props.bgColor || CompassColor.offWhite};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SlideText = styled.div`
  font-family: "Untitled Serif", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: ${CompassColor.black};
  font-size: 24px;
  text-align: center;
  margin: 24px;
`;

type Slide = {
  content: React.ReactElement;
  canProceed?: () => boolean;
};

const WelcomeToNoomAnimation: React.FC<{
  animationDone: () => void;
  isEligible?: boolean;
}> = ({ animationDone, isEligible }) => {
  const { trackAnonPageViewed, trackIdentifiedPageViewed } = useTrackEvent();

  useEffect(() => {
    trackAnonPageViewed("ssoEligibilityVerification");
    trackIdentifiedPageViewed("ssoEligibilityVerification");
  }, []);

  const [currentSlideIdx, setCurrentSlideIdx] = useState<number>(0);
  const slides: Slide[] = [
    {
      content: (
        <Slide bgColor={CompassColor.blueberry}>
          <FadeIn duration={0.5} delay={1}>
            <h1>WELCOME TO</h1>
          </FadeIn>
          <Logo src="/assets/img/noomLogo.svg" alt="Noom" />
          <ExpandingCircleCover
            onAnimationEnd={() => {
              setCurrentSlideIdx(currentSlideIdx + 1);
            }}
          />
        </Slide>
      ),
    },
    {
      content: (
        <Slide>
          <FadeInAndOut
            delay={0}
            duration={2}
            onAnimationEnd={() => {
              if (!isEligible) {
                animationDone();
              }
              setCurrentSlideIdx(currentSlideIdx + 1);
            }}
          >
            <Image
              alt="checkmark"
              margin="0 auto"
              src="/assets/img/greenCheckmarkInCircle.svg"
              visibility="hidden"
            />
            <SlideText>Verifying eligibility information...</SlideText>
          </FadeInAndOut>
        </Slide>
      ),
    },
    {
      content: (
        <Slide>
          <FadeInAndOut
            delay={2}
            onAnimationEnd={() => {
              animationDone();
            }}
          >
            <Image
              alt="checkmark"
              margin="0 auto"
              src="/assets/img/greenCheckmarkInCircle.svg"
            />
            <SlideText>Eligibility verified</SlideText>
          </FadeInAndOut>
        </Slide>
      ),
    },
  ];

  return <Container>{slides[currentSlideIdx].content}</Container>;
};

export default WelcomeToNoomAnimation;
