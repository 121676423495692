import React, { useEffect } from "react";
import { isBrowser } from "react-device-detect";

import { Button, Spacing, Stack } from "@noom/wax-component-library";

import {
  QuestionComponentProps,
  QuestionDefinition,
} from "@/components/survey/Question";
import { HealthGoal, ProgramOffering } from "@/constants";
import { B2C_PARTNERS } from "@/constants/Partners";
import { useAppContext } from "@/contexts";
import { partnerOffersPrograms } from "@/utils/programs";

import {
  BaseQuestion,
  ImageBulletListCard,
  ItemDefinition as BulletItemDefinition,
} from "../core";

const QUESTION_ID = "programOverview";

declare module "@/contexts" {
  interface SurveyAnswers {
    programOverview?: string;
  }
}

type ProgramOverviewConfig = {
  buttonText: string;
  program: ProgramOffering | null;
  questionText: string;
  listItems: BulletItemDefinition[];
};

const getLosingWeightText = (isB2C: boolean) =>
  isB2C
    ? "Access to the industry-leading weight loss program"
    : "Free access to the industry-leading weight loss program";

const getProgramMap: (
  isB2C: boolean,
) => Record<HealthGoal, ProgramOverviewConfig> = (isB2C) => ({
  [HealthGoal.REDUCING_STRESS_ANXIOUS_FEELINGS]: {
    buttonText: "Join Program",
    program: "MOOD",
    questionText: "Great! Let's get you set up with the Noom Mood program.",
    listItems: [
      {
        text: "Understand the behaviors and emotions that drive your mood",
        image: "/assets/img/illustration-lightbulb.svg",
      },
      {
        text: "Train your brain to better deal with stress and anxious feelings",
        image: "/assets/img/illustration-brain.svg",
      },
    ],
  },
  [HealthGoal.LOSING_WEIGHT]: {
    buttonText: "Let's go",
    program: null,
    questionText:
      "Great! Let's get you set up with a personalized weight program.",
    listItems: [
      {
        text: getLosingWeightText(isB2C),
        image: "/assets/img/illustration-weight.svg",
      },
      {
        text: "Lose the weight for good through behavior change",
        image: "/assets/img/illustration-food-logging.svg",
      },
    ],
  },
});

const ProgramOverview: React.FC<QuestionComponentProps> = ({
  onClickBack,
  onClickNext,
  surveyAnswers,
}) => {
  const { enrollmentInfo, partnerInfo, setSelectedProgram, setSurveyAnswers } =
    useAppContext();
  const { healthGoal } = surveyAnswers;

  const isB2C = B2C_PARTNERS.includes(partnerInfo?.id as number);
  const PROGRAM_MAP = getProgramMap(isB2C);

  let assignedHealthGoal = healthGoal || HealthGoal.LOSING_WEIGHT;

  if (
    !healthGoal &&
    partnerOffersPrograms(["MOOD"], enrollmentInfo, partnerInfo)
  ) {
    assignedHealthGoal = HealthGoal.REDUCING_STRESS_ANXIOUS_FEELINGS;
  }

  const { buttonText, program, questionText, listItems } =
    PROGRAM_MAP[assignedHealthGoal];

  useEffect(() => {
    setSelectedProgram(program);
  }, [program]);

  return (
    <BaseQuestion
      onClickBack={() => {
        // Don't persist the selection if navigating before this question
        setSurveyAnswers({ ...surveyAnswers, [QUESTION_ID]: undefined });
        onClickBack();
      }}
      questionId={QUESTION_ID}
      questionText={questionText}
    >
      <ImageBulletListCard
        items={listItems}
        headingText="Here's what to look forward to:"
      />
      <Stack spacing={isBrowser ? Spacing[8] : Spacing[4]}>
        <Button
          autoFocus
          colorScheme="primary"
          onClick={() => {
            onClickNext(assignedHealthGoal);
          }}
          size="xl"
          type="submit"
          _focus={{
            boxShadow: "none",
          }}
        >
          {buttonText}
        </Button>
      </Stack>
    </BaseQuestion>
  );
};

export const Q_PROGRAM_OVERVIEW: QuestionDefinition = {
  id: QUESTION_ID,
  shouldShowQuestion: () => true,
  component: ProgramOverview,
};
