import {
  paramNamesWithPii,
  QueryParamsWithoutPii,
  QueryParamsWithPii,
} from "@/hooks/useQueryParams";

export function updateURLParams(newParams: Record<string, string | number>) {
  const url = new URL(window.location.href);

  Object.entries(newParams).forEach(([key, value]) => {
    if (value == null) {
      url.searchParams.delete(key);
    } else {
      url.searchParams.set(key, `${value}`);
    }
  });

  window.history.replaceState(window.history.state, "", url.toString());
}

const URL_PARAMS_PII_KEY = "urlpSession";

type QueryParametersState = Record<string, string | number | boolean | null>;

let urlParamsPiiSessionStore: QueryParametersState;
try {
  urlParamsPiiSessionStore = JSON.parse(
    sessionStorage.getItem(URL_PARAMS_PII_KEY) || "{}",
  );
} catch {
  urlParamsPiiSessionStore = {};
}

export function getUrlParamsPii(): QueryParametersState {
  return urlParamsPiiSessionStore;
}

function setUrlParamsPii(params: QueryParametersState) {
  urlParamsPiiSessionStore = params;
  sessionStorage.setItem(
    URL_PARAMS_PII_KEY,
    JSON.stringify(urlParamsPiiSessionStore),
  );
}

// Determines which params need to be hidden and which can remain.
export function categorizeParams(params: URLSearchParams) {
  const paramObj = Object.fromEntries(params.entries());
  const hiddenParams: Record<string, string> = {};
  paramNamesWithPii.forEach((deny) => {
    if (paramObj[deny]) {
      hiddenParams[deny] = paramObj[deny];
      delete paramObj[deny];
    }
  });
  return {
    params: paramObj,
    hidden: hiddenParams,
  };
}

// Modifies the current URL to hide params. Should be called before any third-party code (pixels)
// is loaded.
export function init() {
  const urlParams = new URLSearchParams(window.location.search);
  const prevHiddenParams = getUrlParamsPii();
  const { hidden } = categorizeParams(urlParams);
  // Keys set to null are removed from the URL
  updateURLParams(
    Object.keys(hidden).reduce(
      (ret, key) => ({
        ...ret,
        [key]: null,
      }),
      {},
    ),
  );
  setUrlParamsPii({ ...prevHiddenParams, ...hidden });
}

// Modifies the given params by adding the hidden params to it.
export function appendPiiParams(params: URLSearchParams) {
  const piiParams = getUrlParamsPii();
  const newParams = new URLSearchParams(params);
  Object.keys(piiParams).forEach((key) => {
    if (piiParams[key]) {
      newParams.set(key, `${piiParams[key]}`);
    }
  });
  return newParams;
}

// As appendPiiParams, but with stronger typing on inputs and outputs and without pass-through of arbitrary parameters.
export function withPiiQueryParams(
  params: QueryParamsWithoutPii,
): QueryParamsWithPii {
  const piiParams = getUrlParamsPii();
  return {
    ...params,
    name: piiParams.name?.toString(),
    email: piiParams.email?.toString(),
    ref_email: piiParams.ref_email?.toString(),
    utm_term: piiParams.utm_term?.toString(),
    utm_content: piiParams.utm_content?.toString(),
  };
}
