import React from "react";

import { Button, Link } from "@noom/wax-component-library";

import { buildDeeplink } from "@/constants";

type ContinueToDownloadButtonProps = {
  email?: string;
};

export const ContinueToDownloadButton: React.FC<
  ContinueToDownloadButtonProps
> = ({ email }) => (
  <Link href={buildDeeplink(email)} isExternal>
    <Button as="a" colorScheme="primary" size="xl">
      Continue to Download
    </Button>
  </Link>
);
